import { useEffect } from "react";

/** @param {Function} func
 */
function useUnmount(func) {
  //eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => func, []);
}

export default useUnmount;
