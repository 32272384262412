import React from "react";
import * as PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { Box, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import LockIcon from "../../../SharedComponents/LockIcon.jsx";
import { useBaseData } from "../../../Providers/Data/BaseDataProvider.jsx";

const useStyles = makeStyles({
  btn: {
    width: "95%",
    margin: "0 2.5%",
    opacity: ({ active, tinyMode }) => (tinyMode ? (active ? 1 : 0.4) : 1),
    cursor: ({ active, tinyMode }) => (tinyMode ? (active ? "default" : "pointer") : "pointer"),
    "& .MuiButton-label": {
      height: "100%",
    },
    "&:hover": {
      backgroundColor: ({ active, tinyMode }) => (tinyMode ? (active ? "transparent" : "") : ""),
    },
    textTransform: "none",
  },
  levelName: {
    //whiteSpace: "nowrap",
    width: "100%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    margin: "5px 0 0",
    display: "-webkit-box",
    "-webkit-line-clamp": ({ lineClamp }) => lineClamp,
    "-webkit-box-orient": "vertical",
  },
  levelLogo: {
    display: "block",
    maxWidth: "90%",
    width: "auto",
    height: 65,
    objectFit: "contain",
  },
  lockIcon: {
    top: 5,
    right: 5,
  },
});

/**
 * @param {Level} level
 * @param {number} itemHeight
 * @param {Boolean} disabled
 * @param {Function} onClick
 * @param {Boolean} active
 * @param {Boolean} tinyMode
 * @returns {null|*}
 * @constructor
 */
const SmallLevelItem = ({ level, lineClamp, disabled, onClick, active, tinyMode }) => {
  const classes = useStyles({ lineClamp, active, tinyMode });
  const { userInfo } = useBaseData();

  if (!level) {
    return null;
  }

  return (
    <Button
      className={classes.btn}
      onClick={tinyMode && active ? undefined : onClick}
      disabled={disabled}
      disableRipple={tinyMode && active}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-start"
        height="100%"
        width="100%"
      >
        <img className={classes.levelLogo} src={level.imageUrl || level.image?.url} alt="level logo" />
        <Typography className={classes.levelName} variant="body2">
          {level.name}
        </Typography>
        {(!level.open || !(userInfo.isActive || userInfo.isDemoMode)) && <LockIcon className={classes.lockIcon} />}
      </Box>
    </Button>
  );
};

SmallLevelItem.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  lineClamp: PropTypes.number,
  level: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  tinyMode: PropTypes.bool,
};

SmallLevelItem.defaultProps = {
  active: false,
  tinyMode: false,
  lineClamp: 4,
};

export default SmallLevelItem;
