import React from "react";
import { Typography, withStyles } from "@material-ui/core";

const PrimaryDarkTypography = withStyles((theme) => ({
  colorPrimary: {
    color: theme.palette.primary.dark,
  },
}))((props) => <Typography {...props} color="primary" />);

export default PrimaryDarkTypography;
