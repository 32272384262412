import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import ToggleButton from "../../../../../SharedComponents/ToggleButton.jsx";

/** @param {String} url */
const LearnAudioPlayer = ({ url }) => {
  const [isLowSpeed, setLowSpeed] = useState(false);
  const audioRef = useRef(/** @type {HTMLAudioElement} */ null);

  /** */
  const toggleHandler = () => {
    setLowSpeed(!isLowSpeed);
    audioRef.current.playbackRate = isLowSpeed ? 1 : 0.5;
  };

  return (
    <>
      {/*todo: wrap into box*/}
      <audio ref={audioRef} style={{ margin: "0 10px 0 20px" }} controls src={url} controlsList="nodownload" />
      <ToggleButton size="small" value={isLowSpeed} onChange={toggleHandler}>
        x0.5
      </ToggleButton>
    </>
  );
};

LearnAudioPlayer.propTypes = {
  url: PropTypes.string.isRequired,
};

export default LearnAudioPlayer;
