import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { useTranslate } from "react-polyglot";

const useStyles = makeStyles(() => ({
  commentToAnswer: {
    whiteSpace: "pre-wrap",
    marginBottom: "15px",
  },
}));

/**
 * @param {[String]} comments
 */
const WrongAnswerAlertComment = ({ comments }) => {
  const classes = useStyles();
  const t = useTranslate();
  return (
    <>
      <Box width="100%" fontSize="1rem" fontWeight="500" lineHeight="1.6" textAlign="left">
        <Typography variant="h6">{t("Exercises.commentToAnswer")}</Typography>
      </Box>
      {comments.map((text) => (
        <Box key={text} marginTop="15px">
          <Typography className={classes.commentToAnswer} variant="body1">
            {text}
          </Typography>
          <Divider variant="middle" />
        </Box>
      ))}
    </>
  );
};

WrongAnswerAlertComment.propTypes = {
  comments: PropTypes.array.isRequired,
};

export default WrongAnswerAlertComment;
