import { useParams } from "react-router-dom";
import { IS_COMMON_APP } from "../constants.js";

/**
 * @returns {{companyName: ?string, domain: ?string}}
 */
function useCompanyPublicId() {
  const { companyName } = useParams();
  const domain = window.location.host;

  if (!IS_COMMON_APP) {
    return {
      companyName: null,
      domain,
    };
  } else {
    return {
      companyName,
      domain: null,
    };
  }
}

export default useCompanyPublicId;
