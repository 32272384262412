import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core";
import icHand from "../../../../../assets/hand2.svg";
import { delay } from "../../../../../Utils/misc.js";

const SPEED_FACTOR = 3000;

/**
 * @param {Number[]} sourceNumbers
 * @param {DOMRect} imgRect
 * @returns {{x: Number, y: Number}}
 */
function convertCoordinates(sourceNumbers, imgRect) {
  return { x: sourceNumbers[0] * imgRect.width - 23, y: sourceNumbers[1] * imgRect.height - 2 };
}

/**
 * @param {Number[]} point1
 * @param {Number[]} point2
 * @returns {Number}
 */
function distance(point1, point2) {
  return Math.sqrt(Math.pow(point2[0] - point1[0], 2) + Math.pow(point2[1] - point1[1], 2));
}

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    marginTop: 20,
  },
  imageQuestion: {
    maxWidth: 400,
    borderRadius: 5,
  },
  hand: {
    position: "absolute",
    width: 40,
    transitionProperty: "top, left",
    transitionTimingFunction: "linear",
    animationDuration: "1s",
    animationFillMode: "forwards",
    filter: "drop-shadow( 3px 3px 2px rgba(0, 0, 0, .25))",
  },
}));

/**
 * @param {RemoteMedia} image
 * @param {ExerciseType6Answer} data
 */
const LetterImageQuestion = ({ image, data }) => {
  const classes = useStyles();
  const [handPosition, setHandPosition] = useState({ x: 0, y: 0 });
  const [currentSpeed, setCurrentSpeed] = useState(0);
  const [handAnimationName, setHandAnimationName] = useState("");
  const [handOpacity, setHandOpacity] = useState();
  /** @type {React.MutableRefObject<HTMLImageElement>} */
  const letterImgRef = useRef();

  //init animations
  useEffect(() => {
    setHandAnimationName("");
    setCurrentSpeed(0);
    setHandOpacity(0);
    letterImgRef.current.onload = () => {
      setHandOpacity(undefined);
      initHand();
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  /** */
  const initHand = () => {
    if (!letterImgRef.current) {
      return;
    }
    const startPosition = data.lines[0][0];
    const imgRect = letterImgRef.current.getBoundingClientRect();
    const coordinates = convertCoordinates(startPosition, imgRect);
    setCurrentSpeed(0);
    setHandPosition(coordinates);
    setHandAnimationName("animation-hand-appear");
    setTimeout(() => {
      playAnimation().then();
    }, 1000);
  };

  /**
   */
  const playAnimation = async () => {
    if (!letterImgRef.current) {
      return;
    }
    const imgRect = letterImgRef.current.getBoundingClientRect();
    const length = data.lines.length;
    for (let i = 0; i < length; i++) {
      await playOneLine(data.lines[i]);
      if (i !== length - 1) {
        await jumpToPoint(data.lines[i + 1][0], imgRect);
      }
    }
    if (data.points && data.points.length > 0) {
      const points = data.points;
      for (let i = 0; i < points.length; i++) {
        await jumpToPoint(points[i], imgRect);
      }
    }
    setHandAnimationName("animation-hand-disappear");
    await delay(1000);
    initHand();
  };

  /**
   * @param {Array<Array<Number>>} line
   */
  const playOneLine = async (line) => {
    for (let i = 1; i < line.length; i++) {
      if (!letterImgRef.current) {
        return;
      }
      const lineElement = line[i];
      const imgRect = letterImgRef.current.getBoundingClientRect();
      const dist = distance(line[i - 1], lineElement);
      const speed = dist * SPEED_FACTOR;
      setCurrentSpeed(speed);
      setHandPosition(convertCoordinates(lineElement, imgRect));
      await delay(speed);
    }
  };

  /**
   * @param {Number[]} point
   * @param {DOMRect} imgRect
   */
  const jumpToPoint = async (point, imgRect) => {
    setHandAnimationName("animation-hand-jump");
    setCurrentSpeed(750);
    setHandPosition(convertCoordinates(point, imgRect));
    await delay(1000);
    setHandAnimationName("");
  };

  return (
    <div className={classes.root}>
      <img ref={letterImgRef} className={classes.imageQuestion} src={image.url} alt={image.name} />
      <img
        className={classes.hand}
        src={icHand}
        style={{
          top: handPosition.y,
          left: handPosition.x,
          transitionDuration: `${currentSpeed}ms`,
          animationName: handAnimationName,
          opacity: handOpacity,
        }}
        alt=""
      />
    </div>
  );
};

LetterImageQuestion.propTypes = {
  data: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
};

export default LetterImageQuestion;
