import React, { createContext, useContext, useEffect, useState } from "react";
import _ from "lodash";
import { IS_APP_STUDYLESS } from "../../constants.js";
import { useHttp } from "../Common/HttpProvider.jsx";
import { loadObjectFromStorage, saveObjectToStorage } from "../../Utils/StorageUtils.js";
import { useBaseData } from "./BaseDataProvider.jsx";

export const WATCHED_EVENTS = "watched events";

/**
 * @typedef {Object} SortedEvents
 * @property {String} month
 * @property {SkillzrunEvent[]} events
 */

/**
 * @typedef {Object} InterestedPatchResponse
 * @property {String} message
 */

/**
 * @callback FnInterestedPatch
 * @param {(Number|String)} string
 * @returns {InterestedPatchResponse}
 */

/**
 * @callback FnMarkVisitPatch
 * @param {(Number|String)} string
 * @returns {({})}
 */

/**
 * @typedef {Object} EventsProviderContext
 * @property {Object} events
 * @property {SortedEvents[]} sortedEvents
 * @property {Number} unwatchedCount
 * @property {FnInterestedPatch} markInterested
 * @property {FnNumberVoid} markAsWatched
 * @property {EventsFilters} filters
 * @property {FnObjectVoid} setFilters
 * @property {Array} leadersList
 * @property {Array} tagsList
 */

/**
 * @typedef {Object} EventsFilters
 * @property {String} leader
 * @property {String} tag
 * @property {Boolean} joined
 */

/**
 * @type {React.Context<EventsProviderContext>}
 */
const eventsContext = createContext({
  sortedEvents: [],
  unwatchedCount: 0,
  /** @type {FnInterestedPatch} */ markInterested: () => {},
  /** @type {FnMarkVisitPatch} */ markVisit: () => {},
  /** @type {FnNumberVoid} */ markAsWatched: () => {},
  leadersList: [],
  tagsList: [],
  /** @type {EventsFilters} */
  filters: {},
  /** @type {FnObjectVoid} */
  setFilters: () => {},
});

/**
 * @returns {EventsProviderContext}
 */
export const useEvents = () => useContext(eventsContext);

/**
 * @param {React.ReactNode} children
 * @returns {React.ReactNode}
 * @constructor
 */
const EventsProvider = ({ children }) => {
  const { get, patch } = useHttp();
  const { events, setEvents } = useBaseData();
  const [sortedEvents, setSortedEvents] = useState(/** @type {SortedEvents[]} */ []);
  const [leadersList, setLeadersList] = useState(/** @type {string[]} */ []);
  const [tagsList, setTagsList] = useState(/** @type {string[]} */ []);
  const [unwatchedCount, setUnwatchedCount] = useState(0);
  const [watchedEventIds, setWatchedEventIds] = useState(
    /** @type {Number[]} */ loadObjectFromStorage(WATCHED_EVENTS) || [],
  );
  const [filters, setFilters] = useState(
    /** @type {EventsFilters} */ {
      leader: "",
      tag: "",
      joined: false,
    },
  );

  /** */
  const requestData = async () => {
    const resp = await get("events");
    if (resp) {
      setEvents(resp);
    }
  };

  // set leaders list and tags list
  useEffect(() => {
    const leadersSet = new Set();
    let tagsSet = new Set();
    events.forEach((event) => {
      if (event.leader && event.leader.name) {
        leadersSet.add(event.leader.name);
      }

      if (event.tags.length > 0) {
        event.tags.forEach((tag) => tagsSet.add(tag.name));
      }
    });

    setLeadersList(Array.from(leadersSet));
    setTagsList(Array.from(tagsSet));
  }, [events]);

  // sort and filter events
  useEffect(() => {
    let data = filterEvents(events);
    if (IS_APP_STUDYLESS) {
      setSortedEvents(data);
    } else {
      // filter and sort be month events
      /** @type {Object.<String, SortedEvents>} */
      const sortedData = {};

      data.forEach((event) => {
        const date = new Date(event.date);
        const year = date.getFullYear();
        const month = date.getMonth();
        const stringDate = `${year}/${month}`;
        if (!sortedData[stringDate]) {
          sortedData[stringDate] = { month: month.toString(), events: [event] };
        } else {
          sortedData[stringDate].events.push(event);
        }
      });
      setSortedEvents(Object.values(sortedData));
    }
  }, [events, filters]);

  // calculate unwatched count
  useEffect(() => {
    if (events.length > 0) {
      const diff = events.length - watchedEventIds.length;
      setUnwatchedCount(diff < 0 ? 0 : diff);
    } else {
      setUnwatchedCount(0);
    }
  }, [events, watchedEventIds]);

  /** @param {SkillzrunEvent[]} data */
  const filterEvents = (data) => {
    //filter
    if (filters.leader || filters.tag || filters.joined) {
      return data
        .filter((item) => (filters.leader ? item.leader?.name === filters.leader : true))
        .filter((item) => (filters.tag ? item.tags?.some((el) => el.name === filters.tag) : true))
        .filter((item) => (filters.joined ? item.interested === true : true));
    }

    return data;
  };

  /** @param {Number} eventId */
  const markInterested = async (eventId) => {
    const resp = await patch(`events/${eventId}/interested`, {});
    if (resp) {
      requestData().then();
    }
    return resp;
  };

  /** @param {Number} eventId */
  const markVisit = async (eventId) => {
    const resp = await patch(`events-interests/${eventId}/mark-visit`, {});
    return resp;
  };

  /** @param {Number} index */
  const markAsWatched = (index) => {
    const ids = sortedEvents[index].events.map((event) => event.id);
    const union = _.union(watchedEventIds, ids);
    saveObjectToStorage(WATCHED_EVENTS, union);
    setWatchedEventIds(union);
  };

  const value = {
    events,
    sortedEvents,
    unwatchedCount,
    markInterested,
    markAsWatched,
    filters,
    setFilters,
    leadersList,
    tagsList,
    markVisit,
  };

  return <eventsContext.Provider value={value}>{children}</eventsContext.Provider>;
};

export default EventsProvider;
