import { useEffect, useRef } from "react";

/**
 * @param {Function} effect
 * @param {Array} deps
 */
function useEffectWithPreviousValues(effect, deps) {
  const _prevValues = useRef([]);
  useEffect(() => {
    effect(_prevValues.current);
    _prevValues.current = deps;
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export default useEffectWithPreviousValues;
