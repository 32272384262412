import React, { useEffect, useState } from "react";
import { useTranslate } from "react-polyglot";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import useEffectWithPreviousValues from "../../../../hooks/useEffectWithPreviousValues.js";
import FlexboxVertical from "../../../../SharedComponents/FlexboxVertical.jsx";
import ExamProgressTypography from "../../../../SharedComponents/ExamProgressTypography.jsx";
import TimerChip from "../../../../SharedComponents/TimerChip.jsx";
import { formatTime } from "../../../../Utils/misc.js";
import ExamResultPopup from "./SharedComponents/ExamResultPopup.jsx";
import { useLearnProcess } from "../../../../Providers/Data/LearnProcessProvider.jsx";
import ExamStatusText from "../../../../SharedComponents/ExamStatusText.jsx";
import icCheck from "../../../../assets/ic_check_green.svg";
import icPlus from "../../../../assets/ic_plus_pink.svg";

const useStyles = makeStyles(
  () => ({
    learnedStatusIcon: {
      width: 20,
      height: 20,
      marginBottom: -3,
      marginLeft: 8,
    },
    wrongAnswerIcon: {
      transform: "rotate(45deg)",
    },
  }),
  {
    name: "ExamInfoBlock",
  },
);

/**
 * @param {ExerciseResponse} exercise
 */
const ExamInfoBlock = ({ exercise }) => {
  const classes = useStyles();
  const t = useTranslate();
  const { requestOtherExercise } = useLearnProcess();
  const history = useHistory();
  const [examResultOpen, setExamResultOpen] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const exam = exercise.deck.exam;

  // Start exam's timer
  useEffect(() => {
    if (!exam.startDate) {
      return;
    }
    const end = exam.startDate + exam.timeLimit * 60 * 1000;
    let now = Date.now();
    if (end - now <= 0 || (exam.status !== "in_progress" && exam.status !== "none")) {
      setElapsedTime(0);
      return;
    }
    setElapsedTime(end - now);
    const timerHandle = setInterval(() => {
      now = Date.now();
      if (end - now <= 0) {
        clearInterval(timerHandle);
        timeIsOut();
        return;
      }
      setElapsedTime(end - now);
    }, 1000);

    return () => {
      clearInterval(timerHandle);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exercise]);

  // observe exam status
  useEffectWithPreviousValues(
    /** @param {ExerciseResponse} prevExercise */
    ([prevExercise]) => {
      if (prevExercise && prevExercise.deck.id === exercise.deck.id && prevExercise.deck.exam.status !== exam.status) {
        setExamResultOpen(true);
      }
    },
    [exercise],
  );

  /** */
  const timeIsOut = () => {
    setTimeout(() => {
      requestOtherExercise(0).then();
    }, 1000);
  };

  /** */
  const examResultPopupPositiveButtonHandler = () => {
    if (exam.status !== "none" && exam.status !== "in_progress") {
      history.push("/");
    }
  };

  return (
    <>
      <FlexboxVertical alignItems="center">
        <ExamProgressTypography color="primary">
          {t("exam.progress", { current: exercise.exercise.position + 1, total: exercise.deck.count })}
          {exam.endDate !== null && exercise.exercise.learned && (
            <img className={classes.learnedStatusIcon} src={icCheck} alt="" />
          )}
          {exam.endDate !== null && !exercise.exercise.learned && (
            <img className={clsx(classes.learnedStatusIcon, classes.wrongAnswerIcon)} src={icPlus} alt="" />
          )}
        </ExamProgressTypography>
        {exam.endDate === null && <TimerChip label={formatTime(elapsedTime / 1000)} />}
        {exam.endDate !== null && <ExamStatusText exam={exam} />}
      </FlexboxVertical>
      <ExamResultPopup
        exam={exam}
        open={examResultOpen}
        onClose={(positive) => {
          setExamResultOpen(false);
          if (positive) {
            examResultPopupPositiveButtonHandler();
          }
        }}
      />
    </>
  );
};

ExamInfoBlock.propTypes = {
  exercise: PropTypes.object.isRequired,
};

export default ExamInfoBlock;
