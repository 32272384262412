import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

/**
 * @returns {Boolean}
 */
export default function () {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up("lg"));
}
