import React from "react";
import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";

export default withStyles(() => ({
  root: {
    borderRadius: 50,
  },
  //eslint-disable-next-line react/display-name
}))(React.forwardRef((props, ref) => <Button {...props} ref={ref} color="primary" size="small" variant="contained" />));
