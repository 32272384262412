import PropTypes from "prop-types";
import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  circle: {
    width: ({ size }) => size,
    height: ({ size }) => size,
    backgroundColor: ({ color }) => color,
    borderRadius: "50%",
    display: ({ inline }) => (inline ? "inline-block" : "block"),
  },
}));

/**
 * @param {String} color
 * @param {Number} size
 * @param {Boolean} inline
 */
const ColoredCircle = ({ color, size, inline }) => {
  const classes = useStyles({ color, size, inline });

  return <div className={classes.circle} />;
};

ColoredCircle.propTypes = {
  color: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  size: PropTypes.number.isRequired,
};

export default ColoredCircle;
