import React from "react";
import { useTranslate } from "react-polyglot";
import { Card, CardContent, makeStyles, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import grey from "@material-ui/core/colors/grey";
import YellowButton from "./YellowButton.jsx";
import { useAuth } from "../Providers/Auth/AuthProvider.jsx";
import ic_connect from "../assets/ic_connect.svg";
import useMediaQueryUp from "../hooks/useMediaQueryUp.js";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: grey[50],
    fontSize: 12,
    marginBottom: 20,
    width: "49%",
    borderRadius: 10,
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  cardContent: {
    display: "flex",
    alignItems: "flex-start",
  },
  communicationText: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    alignItems: "flex-start",
    justifyContent: "center",
    marginLeft: 20,
  },
  imgBox: {
    position: "relative",
  },
  cardImg: {
    width: 100,
  },
  btnMargin: {
    margin: "8px 0",
  },
  smallCard: {
    display: "flex",
    alignItems: "center",
    backgroundColor: grey[50],
    borderRadius: 10,
    marginLeft: "16px",
  },
  smallCardContent: {
    padding: "10px",
    display: "flex",
    alignItems: "center",
  },
  smallCardImg: {
    width: 60,
  },
}));

/** */
const ContactUs = () => {
  const t = useTranslate();
  const classes = useStyles();
  const { company2 } = useAuth();
  const history = useHistory();
  const location = history.location.pathname;
  const isLargeScreen = useMediaQueryUp("lg");

  if (!company2.communicationLink) return null;

  if (location !== "/" || isLargeScreen)
    return (
      <Card className={classes.card} elevation={4}>
        <CardContent className={classes.cardContent}>
          <div className={classes.imgBox}>
            <img className={classes.cardImg} src={ic_connect} alt="communication link icon" />
          </div>
          <div className={classes.communicationText}>
            <Typography gutterBottom>{company2.name}</Typography>
            <YellowButton href={company2.communicationLink} target="_blank" className={classes.btnMargin}>
              {t("communication")}
            </YellowButton>
          </div>
        </CardContent>
      </Card>
    );

  return (
    <Card className={classes.smallCard} elevation={4}>
      <CardContent className={classes.smallCardContent}>
        <a href={company2.communicationLink}>
          <div className={classes.imgBox}>
            <img className={classes.smallCardImg} src={ic_connect} alt="communication link icon" />
          </div>
        </a>
      </CardContent>
    </Card>
  );
};

export default ContactUs;
