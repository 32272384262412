import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey.js";
import clsx from "clsx";
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp";
import { useCourseProvider } from "../../../Providers/Data/CourseProvider.jsx";
import SmallDeckList from "./SmallDeckList.jsx";
import SmallBranchCard from "./SmallBranchCard.jsx";
import Carousel from "../../../SharedComponents/Carousel.jsx";
import useMediaQueryUp from "../../../hooks/useMediaQueryUp.js";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    width: 415,
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    margin: 0,
    padding: "15px 0",
    borderRadius: 10,
    backgroundColor: grey[50],
    [theme.breakpoints.up("lg")]: {
      margin: "17px 0 0 0",
      padding: "0",
    },
    transition: "width 1s",
  },
  smallSidebar: {
    [theme.breakpoints.up("lg")]: {
      width: 150,
    },
  },
  branchListRoot: {
    width: "100%",
    height: "164px",
    display: "none",
    opacity: 1,
    transition: "opacity 2s ease-in, height 1s",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },

    "& .slick-slide > div": {
      display: "flex",
      flexDirection: "column",
    },
  },
  hideLevels: {
    transition: "opacity .5s, height 1s",
    opacity: 0,
    height: 0,
  },
  carouselArrows: {
    margin: "10px 0 6px",
    borderRadius: 10,
  },
  cardContentRoot: {
    paddingLeft: 0,
    paddingRight: 0,
    "&:last-child": {
      paddingBottom: 0,
      paddingTop: 0,
    },
    [theme.breakpoints.up("lg")]: {
      "&:last-child": {
        paddingBottom: 16,
        paddingTop: 16,
      },
    },
  },
  infoCardLabel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    height: 90,
    width: 31,
    right: 0,
    top: "50%",
    transform: "translate(100%, -50%)",
    backgroundColor: theme.palette.primary.light,
    color: "white",
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
    cursor: "pointer",

    "& .MuiSvgIcon-root": {
      fontSize: "16px",
    },
  },
}));

/**
 * @returns {null|*}
 * @constructor
 */
const LevelsAndDecks = () => {
  const classes = useStyles();
  const { currentLevel, branchesForLevel, currentBranch, saveCurrentBranch } = useCourseProvider();
  const isLargeScreen = useMediaQueryUp("lg");
  const [open, setOpen] = useState(false);

  /**
   * @param {Branch} branch
   * @returns {Function}
   */
  const branchClickHandler = (branch) => () => {
    saveCurrentBranch(branch);
  };

  if (!branchesForLevel || !currentLevel || !currentBranch) {
    return null;
  }

  const initialSlideIndex = branchesForLevel.findIndex((element) => element.percent < 100);
  const slidesToShow = branchesForLevel.length > 3 ? 3 : branchesForLevel.length;

  return (
    <>
      {isLargeScreen && (
        <div className={classes.infoCardLabel} onClick={() => setOpen(!open)}>
          {open ? <ArrowBackIosSharpIcon /> : <ArrowForwardIosSharpIcon />}
        </div>
      )}
      <Card className={clsx(classes.cardRoot, { [classes.smallSidebar]: !open })} elevation={3}>
        <CardContent className={classes.cardContentRoot}>
          {isLargeScreen && (
            <Carousel
              className={clsx(classes.branchListRoot, { [classes.hideLevels]: !open })}
              arrowButtonsClassName={classes.carouselArrows}
              sliderSettings={{
                slidesToShow,
                initialSlide: initialSlideIndex,
                slidesToScroll: 3,
              }}
            >
              {branchesForLevel.map((branch) => (
                <SmallBranchCard
                  key={branch.id}
                  branch={branch}
                  disabled={branch.id !== currentBranch.id}
                  onClick={branchClickHandler(branch)}
                />
              ))}
            </Carousel>
          )}

          <SmallDeckList isOpen={open} />
        </CardContent>
      </Card>
    </>
  );
};

export default LevelsAndDecks;
