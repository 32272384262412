import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslate } from "react-polyglot";
import ExerciseTitle from "./SharedComponents/ExerciseTitle.jsx";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    margin: "0 0 30px",
  },
  iFrame: {
    width: "100%",
    border: "none",
    flexGrow: 1,
    minHeight: 622,
    marginTop: 30,
  },
}));

/**
 * @param {ExerciseType12} exercise
 * @param {string} exerciseTitle
 */
const Exercise12 = ({ exercise, exerciseTitle }) => {
  const classes = useStyles();
  const t = useTranslate();

  return (
    <Box className={classes.root}>
      <ExerciseTitle>{exerciseTitle || t("Exercises.type12.title")}</ExerciseTitle>
      <iframe className={classes.iFrame} src={exercise.question.text.base64Src()} />
    </Box>
  );
};

Exercise12.propTypes = {
  exercise: PropTypes.object.isRequired,
  exerciseTitle: PropTypes.string,
};

export default Exercise12;
