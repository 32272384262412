import React, { useState } from "react";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import Card from "@material-ui/core/Card";
import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useBaseData } from "../../../../Providers/Data/BaseDataProvider.jsx";
import useEffectWithPreviousValues from "../../../../hooks/useEffectWithPreviousValues.js";
import { delay } from "../../../../Utils/misc.js";

const StyledTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
  },
}))(Tooltip);

const StyledCard = withStyles((theme) => ({
  root: {
    minWidth: 56,
    minHeight: 56,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))(Card);

const StyledTypography = withStyles(() => ({
  root: {
    textAlign: "center",
    fontSize: 24,
  },
}))(Typography);

/** */
const AddPointsAnimation = ({ children }) => {
  const { counts } = useBaseData();
  const [open, setOpen] = useState(false);
  const [pointsCount, setPointsCount] = useState("");

  useEffectWithPreviousValues(
    /** @param {Counts} prevCounts */
    async ([prevCounts]) => {
      if (counts && prevCounts) {
        const diff = counts.points - prevCounts.points;
        if (diff > 0) {
          setPointsCount(`+${diff}`);
          setOpen(true);
          await delay(4000);
          setOpen(false);
          await delay(600);
          setPointsCount("");
        }
      }
    },
    [counts],
  );

  return (
    <StyledTooltip
      open={open && pointsCount !== ""}
      TransitionComponent={Zoom}
      TransitionProps={{ timeout: 600 }}
      placement="top-end"
      title={
        <StyledCard>
          <StyledTypography variant="h6">{pointsCount}</StyledTypography>
        </StyledCard>
      }
    >
      {children}
    </StyledTooltip>
  );
};

export default AddPointsAnimation;
