import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

/**
 * @param {Object} rootProps
 * @param {Object} progressProps
 * @param {Object} labelProps
 * @return {*}
 * @constructor
 */
function SimpleCircularProgressWithLabel({ rootProps, progressProps, labelProps }) {
  return (
    <Box position="relative" display="inline-flex" {...rootProps}>
      <CircularProgress variant="determinate" {...progressProps} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary" {...labelProps}>
          {`${Math.round(progressProps.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

SimpleCircularProgressWithLabel.propTypes = {
  rootProps: PropTypes.object,
  progressProps: PropTypes.object,
  labelProps: PropTypes.object,
};

export default SimpleCircularProgressWithLabel;
