import React, { forwardRef } from "react";
import { withStyles } from "@material-ui/core";
import GreyButton from "./GreyButton.jsx";

const CustomButton = forwardRef((props, ref) => <GreyButton ref={ref} {...props} disableElevation />);

CustomButton.displayName = "FlatGreyButton";

export default withStyles({
  root: {
    borderRadius: 0,
  },
})(CustomButton);
