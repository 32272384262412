import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles, withStyles } from "@material-ui/core";
import { useTranslate } from "react-polyglot";
import lodash from "lodash";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import clsx from "clsx";
import ToggleButton from "../../../../SharedComponents/ToggleButton.jsx";
import FlexboxVertical from "../../../../SharedComponents/FlexboxVertical.jsx";
import FlexboxHorizontal from "../../../../SharedComponents/FlexboxHorizontal.jsx";
import { DEFAULT_LEARNED_PATCH } from "../../../../Providers/Data/LearnProcessProvider.jsx";
import Question from "./SharedComponents/Question.jsx";
import Appendix from "./SharedComponents/Appendix.jsx";

const useStyles = makeStyles((theme) => ({
  sourceData: {
    marginTop: 20,
    padding: 20,
    justifyContent: "space-between",
  },
  validPairs: {
    marginTop: 20,
    padding: 20,
    backgroundColor: "white",
    flexGrow: 1,
    borderBottom: "2px solid #fafafa",
    width: "100%",
    justifyContent: "flex-start",
  },
  validPair: {
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  validItem: {
    flexBasis: "48%",
  },
  sideBox: {
    display: "flex",
    flexDirection: "column",
    flexBasis: "48%",
    "&>*": {
      marginBottom: theme.spacing(1),
    },
  },
  leftBox: {
    borderRight: `1px solid ${theme.palette.grey[300]}`,
  },
}));

const StyledChip = withStyles((theme) => ({
  root: {
    color: "white",
    fontSize: theme.typography.button.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    minHeight: 36,
    height: "unset",
    borderRadius: 18,
  },
  label: {
    whiteSpace: "normal",
    textAlign: "center",
    lineHeight: theme.typography.button.lineHeight,
    padding: 5,
    paddingLeft: 15,
    paddingRight: 15,
  },
}))(Chip);

/**
 * @param {ExerciseType8Legacy|ExerciseType8} exercise
 * @param {Function} setCheckButtonDisabled
 * @param {String} exerciseTitle
 * @param {MutableRefObject<Function>} checkAnswerRef
 * @param {Object} rest
 * @returns {*}
 * @constructor
 */
const Exercise8 = ({ exercise, setCheckButtonDisabled, exerciseTitle, checkAnswerRef, ...rest }) => {
  const classes = useStyles();
  const t = useTranslate();
  const [leftRandomValues, setLeftRandomValues] = useState([]);
  const [rightRandomValues, setRightRandomValues] = useState([]);
  const [currentPair, setCurrentPair] = useState([null, null]);
  const [validPairs, setValidPairs] = useState(/** @type {Array<String[]>} */ []);
  const checkFunctionHandleRef = useRef(0);
  const resetErrorFunctionHandleRef = useRef(0);
  const [error, setError] = useState(false);

  /** @type {ExerciseType8Legacy|ExerciseType8Answer} */
  const answer = exercise.answer || exercise;
  const pairs = answer.pairs;

  //reset state
  useEffect(() => {
    let leftSet = pairs.map((pair) => pair[0]);
    leftSet = lodash.shuffle(leftSet);
    let rightSet = pairs.map((pair) => pair[1]);
    rightSet = lodash.shuffle(rightSet);
    setLeftRandomValues(leftSet);
    setRightRandomValues(rightSet);
    setCurrentPair([null, null]);
    setValidPairs([]);
    setError(false);
  }, [pairs]);

  // check current selected pair
  useEffect(() => {
    clearTimeout(checkFunctionHandleRef.current);
    if (!currentPair[0] || !currentPair[1]) {
      return;
    }
    checkFunctionHandleRef.current = setTimeout(() => {
      const searchResult = pairs.find((pair) => pair[0] === currentPair[0].v && pair[1] === currentPair[1].v);
      if (!searchResult) {
        setError(true);
        clearTimeout(resetErrorFunctionHandleRef.current);
        resetErrorFunctionHandleRef.current = setTimeout(() => {
          setError(false);
          setCurrentPair([null, null]);
        }, 1500);
      } else {
        leftRandomValues.splice(currentPair[0].i, 1);
        rightRandomValues.splice(currentPair[1].i, 1);
        setLeftRandomValues([...leftRandomValues]);
        setRightRandomValues([...rightRandomValues]);
        setValidPairs([...validPairs, searchResult]);
        setCurrentPair([null, null]);
      }
    }, 700);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPair, exercise]);

  // update check button state
  useEffect(() => {
    if (!pairs) {
      return;
    }
    setCheckButtonDisabled(validPairs.length !== pairs.length);
  }, [setCheckButtonDisabled, pairs, validPairs]);

  checkAnswerRef.current = () => {
    return DEFAULT_LEARNED_PATCH;
  };

  /** @param {Array} pair */
  const toggleButtonHandler = (pair) => () => {
    if (error) {
      return;
    }
    setCurrentPair(pair);
  };

  return (
    <Box {...rest} data-component="Exercise8" display="flex" flexDirection="column" alignItems="center" height="100%">
      <Question
        exerciseQuestion={exercise.question}
        exerciseTitle={exerciseTitle}
        defaultTitle={t("Exercises.type8.title")}
      />
      {exercise.appendix && exercise.appendix.pdf && <Appendix pdfUrl={exercise.appendix.pdf.url} />}
      <Grid container className={classes.sourceData} direction="row">
        <Grid item data-tag="left side" className={classes.sideBox}>
          {leftRandomValues.map((v, i) => (
            <ToggleButton
              key={v + i}
              value={lodash.get(currentPair[0], "v") === v}
              onChange={toggleButtonHandler([{ v, i }, currentPair[1]])}
              color="primary"
              error={error && lodash.get(currentPair[0], "v") === v}
            >
              {v}
            </ToggleButton>
          ))}
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item data-tag="right side" className={classes.sideBox}>
          {rightRandomValues.map((v, i) => (
            <ToggleButton
              key={v + i}
              value={lodash.get(currentPair[1], "v") === v}
              onChange={toggleButtonHandler([currentPair[0], { v, i }])}
              color="primary"
              error={error && lodash.get(currentPair[1], "v") === v}
            >
              {v}
            </ToggleButton>
          ))}
        </Grid>
      </Grid>
      <FlexboxVertical className={clsx(classes.sourceData, classes.validPairs)}>
        {validPairs.map((pair) => (
          <FlexboxHorizontal key={pair[0] + pair[1]} className={classes.validPair}>
            <StyledChip className={classes.validItem} label={pair[0]} color="primary" />
            <StyledChip className={classes.validItem} label={pair[1]} color="primary" />
          </FlexboxHorizontal>
        ))}
      </FlexboxVertical>
    </Box>
  );
};

Exercise8.propTypes = {
  checkAnswerRef: PropTypes.object.isRequired,
  exercise: PropTypes.object.isRequired,
  exerciseTitle: PropTypes.string,
  setCheckButtonDisabled: PropTypes.func.isRequired,
};

export default Exercise8;
