import { withStyles } from "@material-ui/core";
import CustomFab from "./CustomFab.jsx";

const RedFab = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.error.dark,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.error.main,
    },
  },
}))(CustomFab);

export default RedFab;
