import React, { forwardRef, useRef } from "react";
import * as PropTypes from "prop-types";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import clsx from "clsx";

const useStyles = makeStyles({
  buttons: {
    minWidth: 40,
    maxWidth: 40,
  },
  slider: {
    width: "calc(100% - 80px)",
  },
});

const Carousel = forwardRef(
  /**
   * @param {React.ReactNode} children
   * @param {Object} sliderSettings
   * @param {String} arrowButtonsClassName
   * @param {String} buttonsVariant
   * @param {Function} beforeChange
   * @param {Object} rest
   * @param {(MutableRefObject<React.ReactNode>|Function)} ref
   * @returns {*}
   */
  ({ children, sliderSettings, arrowButtonsClassName, buttonsVariant, beforeChange, ...rest }, ref) => {
    const classes = useStyles();
    /** @type {MutableRefObject<React.ReactNode>} */
    const sliderRef = useRef();

    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      swipeToSlide: true,
      arrows: false,
      ...sliderSettings,
    };

    const forwardClickHandler = () => {
      if (sliderRef.current) {
        sliderRef.current.slickNext();
      }
    };

    const backClickHandler = () => {
      if (sliderRef.current) {
        sliderRef.current.slickPrev();
      }
    };

    /** @param {React.ReactNode} node */
    const onRefChange = (node) => {
      sliderRef.current = node;
      if (ref) {
        if (typeof ref === "function") {
          ref(node);
        } else {
          ref.current = node;
        }
      }
    };

    return (
      <Box {...rest} display="flex">
        <Button
          className={clsx(classes.buttons, arrowButtonsClassName)}
          color="primary"
          variant={buttonsVariant}
          onClick={backClickHandler}
        >
          <ArrowBackIosRoundedIcon />
        </Button>
        <Slider ref={onRefChange} {...settings} className={classes.slider} beforeChange={beforeChange}>
          {children}
        </Slider>
        <Button
          className={clsx(classes.buttons, arrowButtonsClassName)}
          color="primary"
          variant={buttonsVariant}
          onClick={forwardClickHandler}
        >
          <ArrowForwardIosRoundedIcon />
        </Button>
      </Box>
    );
  },
);

Carousel.displayName = "Carousel";

Carousel.propTypes = {
  sliderSettings: PropTypes.object,
  /** @type Validator<String> */
  arrowButtonsClassName: PropTypes.string,
  /** @type Validator<String> */
  buttonsVariant: PropTypes.string,
  beforeChange: PropTypes.func,
};

Carousel.defaultProps = {
  arrowButtonsClassName: "",
};

export default Carousel;
