import FormControl from "@material-ui/core/FormControl";
import clsx from "clsx";
import Select from "@material-ui/core/Select";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import React from "react";
import useSelectInputStyles from "./UseSelectInputStyles.jsx";

/**
 * @param {String[]} values
 * @param {String} value
 * @param {Function} onChange
 * @param {String} label
 * @param {String} className
 * @param {Object} rest
 * @return {React.ReactNode}
 */
const StringSelectInput = ({ values, value, onChange, label, className, ...rest }) => {
  const classes = useSelectInputStyles();

  return (
    <FormControl {...rest} className={clsx(className, classes.root)} size="small">
      <Select
        value={value}
        onChange={onChange}
        displayEmpty
        IconComponent={ExpandLessIcon}
        renderValue={(val) => (val !== "" ? `${label}: ${val}` : label)}
      >
        <MenuItem value="">{label}</MenuItem>
        {values.map((v, i) => (
          <MenuItem key={v + i} value={v}>
            {v}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

StringSelectInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.string),
};

export default StringSelectInput;
