import { initializeApp } from "firebase/app";
import { getToken, getMessaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCUVHHNhlvA-QtJ-fYMQTBca0hO3Y6WDtw",
  authDomain: "weeteam-skillzrun.firebaseapp.com",
  databaseURL: "https://weeteam-skillzrun.firebaseio.com",
  projectId: "weeteam-skillzrun",
  storageBucket: "weeteam-skillzrun.appspot.com",
  messagingSenderId: "43386971392",
  appId: "1:43386971392:web:9eded0879a95d43b07bfe5",
  measurementId: "G-ZN2PRM2L1G",
};

/** */
export const isNotificationsSupported = () =>
  "Notification" in window && "serviceWorker" in navigator && "PushManager" in window;

let messaging;
if (isNotificationsSupported()) {
  try {
    const firebaseApp = initializeApp(firebaseConfig);
    messaging = getMessaging(firebaseApp);
  } catch (e) {
    console.log(e.message);
  }
} else {
  console.log("This browser doesn't support the APIs required to use Firebase SDK.");
}

/**
 * @param {function} setFirebaseToken
 */
export const fetchToken = (setFirebaseToken) => {
  return getToken(messaging, {
    vapidKey: "BFy6FKeSjauWOtn9MPP3YQTTCVxsCOT9Z89ULbESHFMtK7rZKdTSnrdzB6ckPWcFtv9ecLwyI4g0cJfmKBhnmU0",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        setFirebaseToken(currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log("No registration token available. Request permission to generate one.");
        setFirebaseToken("");
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

/** */
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });
