import PropTypes from "prop-types";
import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

const useStyles = makeStyles(() => ({
  imageQuestion: {
    maxWidth: 400,
    marginTop: 20,
    cursor: "pointer",
    borderRadius: 5,
  },
  bigImage: {
    maxHeight: "90vh",
    marginBottom: -5,
    borderRadius: 3,
  },
}));

const StyledDialogContent = withStyles(() => ({
  root: {
    padding: 1,
    "&:first-child": {
      paddingTop: 1,
    },
  },
}))(DialogContent);

/**
 * @param {RemoteMedia} image
 */
const ImageQuestion = ({ image }) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);

  /** */
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <img className={classes.imageQuestion} src={image.url} alt={image.name} onClick={() => setDialogOpen(true)} />
      <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="xl">
        <StyledDialogContent>
          <img className={classes.bigImage} src={image.url} alt={image.name} />
        </StyledDialogContent>
      </Dialog>
    </>
  );
};

ImageQuestion.propTypes = {
  image: PropTypes.object.isRequired,
};

export default ImageQuestion;
