import React from "react";
import { Redirect, Route } from "react-router-dom";
import useCompanyPublicId from "../../hooks/useCompanyPublicId.js";
import { useBaseData } from "../Data/BaseDataProvider.jsx";

/**
 * @param {React.ReactNode|HTMLElement} children
 * @param {Object} rest
 */
const AuthRequestDataRoute = ({ children, ...rest }) => {
  const { domain } = useCompanyPublicId();
  const { preselectedSubject } = useBaseData();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!domain || (domain && preselectedSubject)) {
          return children;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/subjects",
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  );
};

export default AuthRequestDataRoute;
