import React, { createContext, useContext, useEffect, useState } from "react";
import { useHttp } from "../Common/HttpProvider.jsx";
import useEffectAsync from "../../hooks/useEffectAsync.js";
import "./../../Utils/extensions.js";
import { useBaseData } from "./BaseDataProvider.jsx";

/** @typedef {Object} DictionaryProviderContext
 * @property {Object} allWords
 * @property {FnObjectVoid} setFilters
 * @property {SimpleIdNameObject[]} branches
 * @property {SimpleIdNameObject[]} decks
 * @property {SimpleIdNameObject[]} allDecks
 * @property {DictionaryFilters} filters
 */

/**
 * @typedef {Object} DictionaryFilters
 * @property {String} text
 * @property {String} letter
 * @property {Number} branch
 * @property {Number} deck
 */

/**
 * @type {React.Context<DictionaryProviderContext>}
 */
const dictionaryContext = createContext({
  allWords: {},
  branches: [],
  decks: [],
  allDecks: [],
  /** @type {DictionaryFilters} */
  filters: {},
  /** @type {FnObjectVoid} */
  setFilters: () => {},
});

/**
 * @returns {DictionaryProviderContext}
 */
export const useDictionary = () => useContext(dictionaryContext);

/**
 * @param {React.ReactNode} children
 * @returns {React.ReactNode}
 * @constructor
 */
const DictionaryProvider = ({ children }) => {
  const { get } = useHttp();
  const { refreshUserInfo, subject } = useBaseData();
  const [wordList, setWordList] = useState(/** @type {WordListItem[]} */ []);
  const [allWords, setAllWords] = useState({});
  const [branches, setBranches] = useState([]);
  const [decks, setDecks] = useState([]);
  const [allDecks, setAllDecks] = useState([]);
  const [filters, setFilters] = useState({
    text: "",
    letter: "",
    branch: -1,
    deck: -1,
  });

  const subjectId = subject?.subjectId;

  // download data
  useEffectAsync(async () => {
    const resp = await get("wordList");
    if (resp) {
      setWordList(resp);
    }
    refreshUserInfo();
  }, [subjectId]);

  // convert and filter input data
  useEffect(() => {
    const wordsCollection = {};
    const decksCollection = [];
    const allDecksCollection = [];
    const branchesCollection = [];
    wordList.forEach((branchData) => {
      branchesCollection.push({ id: branchData.id, name: branchData.name });
      branchData.decks.forEach((deckInWordList) => {
        allDecksCollection.push({ id: deckInWordList.id, name: deckInWordList.name });
        if (filters.branch === branchData.id) {
          decksCollection.push({ id: deckInWordList.id, name: deckInWordList.name });
        }
        deckInWordList.words.forEach((wordInWordList) => {
          let totalCondition;
          const textFilterCondition =
            filters.text === ""
              ? true
              : wordInWordList.source.toUpperCase().contains(filters.text.toUpperCase()) ||
                wordInWordList.translation.toUpperCase().contains(filters.text.toUpperCase());
          const letterFilterCondition =
            filters.letter === ""
              ? true
              : wordInWordList.source[0].toUpperCase() === filters.letter.toUpperCase() ||
                (wordInWordList.translation !== "" &&
                  wordInWordList.translation[0].toUpperCase() === filters.letter.toUpperCase());
          const branchFilterCondition = filters.branch === -1 ? true : wordInWordList.branchId === filters.branch;
          const deckFilterCondition = filters.deck === -1 ? true : wordInWordList.deckId === filters.deck;
          totalCondition = textFilterCondition && letterFilterCondition && branchFilterCondition && deckFilterCondition;
          if (!totalCondition) {
            return;
          }
          if (!wordsCollection[wordInWordList.id]) {
            wordsCollection[wordInWordList.id] = [wordInWordList];
          } else {
            wordsCollection[wordInWordList.id].push(wordInWordList);
          }
        });
      });
    });
    setBranches(branchesCollection);
    setDecks(decksCollection);
    setAllDecks(allDecksCollection);
    setAllWords(wordsCollection);
  }, [wordList, filters]);

  /** @param {Object} _filters */
  const setFiltersPublic = (_filters) => {
    setFilters({ ...filters, ..._filters });
  };

  const value = {
    allWords,
    branches,
    decks,
    allDecks,
    filters,
    setFilters: setFiltersPublic,
  };

  return <dictionaryContext.Provider value={value}>{children}</dictionaryContext.Provider>;
};

export default DictionaryProvider;
