import React, { forwardRef } from "react";
import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const CustomButton = forwardRef((props, ref) => (
  <Button ref={ref} {...props} color="primary" variant="contained" disableElevation />
));

CustomButton.displayName = "FlatPrimaryButton";

export default withStyles({
  containedPrimary: {
    "& .MuiButton-label": {
      fontWeight: 300,
      fontSize: 24,
    },
  },
})(CustomButton);
