import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslate } from "react-polyglot";
import clsx from "clsx";
import { Box, makeStyles, TextField, Typography, Divider, Button, Checkbox, Chip } from "@material-ui/core";
import ToggleButton from "../../../../SharedComponents/ToggleButton.jsx";
import LearnAudioPlayer from "./SharedComponents/LearnAudioPlayer.jsx";
import FlexboxVertical from "../../../../SharedComponents/FlexboxVertical.jsx";
import FlexboxHorizontal from "../../../../SharedComponents/FlexboxHorizontal.jsx";
import { DEFAULT_LEARNED_PATCH } from "../../../../Providers/Data/LearnProcessProvider.jsx";
import Question from "./SharedComponents/Question.jsx";
import Appendix from "./SharedComponents/Appendix.jsx";
import WrongAnswerAlertComment from "./SharedComponents/WrongAnswerAlertComment.jsx";

const useStyles = makeStyles((theme) => ({
  answersGroup: {
    margin: "20px 0",
  },
  exerciseTitle: {
    fontSize: 24,
    fontWeight: 500,
    textAlign: "center",
    marginTop: 20,
  },
  textAnswer: {
    flex: "0 0 40%",
    margin: "0 5% 20px",
  },
  imageAnswerButton: {
    flex: "0 0 20%",
    maxWidth: 184,
    minWidth: 184,
    maxHeight: 184,
    padding: 7,
    borderRadius: 7,
    margin: "0 1.7% 20px",
    "&.selected": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  imageAnswer: {
    maxWidth: 170,
    height: 170,
    objectFit: "contain",
    cursor: "pointer",
  },
  keyboardAnswer: {
    margin: "60px 0",
    width: "80%",
  },
  rightAnswerChip: {
    fontSize: "0.875rem",
    fontWeight: 500,
    margin: 4,
  },
}));

/**
 * @param {Object} classes
 * @param {ExerciseType11} exercise
 * @param {?boolean[]} answerValue
 * @param {String} keybAnswerValue
 * @param {Function} answerHandler
 * @param {FnStringVoid} keyboardAnswerHandler
 * @returns {*}
 * @constructor
 */
const Answers = ({ classes, exercise, answerValue, keybAnswerValue, answerHandler, keyboardAnswerHandler }) => {
  const t = useTranslate();
  const answer = exercise.answer;

  /**
   * @param {Boolean} isRight
   * @param {Number} index
   */
  const composeAnswerHandler = (isRight, index) => {
    return () => {
      answerHandler({ target: { value: { isRight, index } } });
    };
  };

  return (
    <>
      {answer.type === "select_audio" && (
        <FlexboxVertical className={classes.answersGroup}>
          {answer.selectAudio.map(
            /** @param {SingleMiniAnswer<RemoteMedia>} item
             * @param {Number} index */
            (item, index) => (
              <FlexboxHorizontal
                data-tag="answer item"
                key={item.answer.path}
                justifyContent="center"
                alignItems="center"
                marginTop="20px"
              >
                <Checkbox
                  checked={answerValue[index] !== null}
                  color="primary"
                  onChange={composeAnswerHandler(item.right, index)}
                />
                <LearnAudioPlayer url={item.answer.url} />
              </FlexboxHorizontal>
            ),
          )}
        </FlexboxVertical>
      )}
      {answer.type === "select_text" && (
        <Box
          data-tag="text answers"
          width="100%"
          display="flex"
          margin="30px 0 0"
          flexWrap="wrap"
          justifyContent="flex-start"
        >
          {answer.selectText.map(
            /** @param {SingleMiniAnswer<String>} item
             * @param {Number} index */
            (item, index) => (
              <ToggleButton
                className={classes.textAnswer}
                key={uuidv4()}
                onChange={composeAnswerHandler(item.right, index)}
                value={answerValue[index] !== null}
              >
                {item.answer}
              </ToggleButton>
            ),
          )}
        </Box>
      )}
      {answer.type === "select_image" && (
        <Box
          data-tag="image answers"
          width="100%"
          display="flex"
          margin="30px 0 0"
          flexWrap="wrap"
          justifyContent="flex-start"
        >
          {answer.selectImage.map((item, index) => (
            <Button
              key={item.answer.path}
              className={clsx(classes.imageAnswerButton, { selected: answerValue[index] !== null })}
              onClick={composeAnswerHandler(item.right, index)}
            >
              <img className={classes.imageAnswer} src={item.answer.url} alt={item.answer.name} />
            </Button>
          ))}
        </Box>
      )}
      {answer.type === "keyboard" && (
        <TextField
          className={classes.keyboardAnswer}
          placeholder={t("Exercises.typeAnswer")}
          variant="outlined"
          value={keybAnswerValue}
          onChange={keyboardAnswerHandler}
        />
      )}
    </>
  );
};

Answers.propTypes = {
  answerHandler: PropTypes.func.isRequired,
  answerValue: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired,
  exercise: PropTypes.object.isRequired,
  keybAnswerValue: PropTypes.string,
  keyboardAnswerHandler: PropTypes.func,
};

/**
 * @param {Object} answer
 * @param {Array} answerValue
 * @param {string} keybAnswerValue
 * @returns {string}
 */
function composeAnswerValueForApi(answer, answerValue, keybAnswerValue) {
  let answerValueForApi;
  if (answer.type !== "keyboard") {
    const selectedOptions = [];
    switch (answer.type) {
      case "select_text":
        answer.selectText.forEach((it, i) => {
          if (answerValue[i] !== null) selectedOptions.push(it.answer);
        });
        break;
      case "select_image":
        answer.selectImage.forEach((it, i) => {
          if (answerValue[i] !== null) selectedOptions.push(it.answer.url);
        });
        break;
      case "select_audio":
        answer.selectAudio.forEach((it, i) => {
          if (answerValue[i] !== null) selectedOptions.push(it.answer.url);
        });
        break;
    }
    answerValueForApi = selectedOptions.join(", ");
  } else {
    answerValueForApi = keybAnswerValue;
  }
  return answerValueForApi;
}

//
//
//
//
//
//
//
//
//
//

/**
 * @param {ExerciseType11} exercise
 * @param {Function} setCheckButtonDisabled
 * @param {String} exerciseTitle
 * @param {MutableRefObject<Function>} checkAnswerRef
 * @param {FnComponentStringVoid} showRightAnswer
 * @param {FnComponentArrayStringVoid} showRightAnswerWithComments
 * @param {Object} rest
 * @returns {*}
 * @constructor
 */
const Exercise11 = ({
  exercise,
  setCheckButtonDisabled,
  exerciseTitle,
  checkAnswerRef,
  showRightAnswer,
  showRightAnswerWithComments,
  ...rest
}) => {
  const classes = useStyles();
  const t = useTranslate();
  const [answerValue, setAnswerValue] = useState(/** @type {?boolean[]} */ []);
  const [keybAnswerValue, setKeybAnswerValue] = useState("");
  const [resetAnswerTrigger, setResetAnswerTrigger] = useState(false);

  const answer = exercise.answer;

  //reset state
  useEffect(() => {
    setAnswerValue(
      (answer.selectText && answer.selectText.length > 0 && answer.selectText.map(() => null)) ||
        (answer.selectImage && answer.selectImage.length > 0 && answer.selectImage.map(() => null)) ||
        (answer.selectAudio && answer.selectAudio.length > 0 && answer.selectAudio.map(() => null)) ||
        [],
    );
    setKeybAnswerValue("");
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exercise, resetAnswerTrigger]);

  checkAnswerRef.current = () => {
    let learned = true;
    let comments = [];
    /** @param {SingleMiniAnswer} Answer
     * @param {Number} index */
    const answerChecker = (Answer, index) => {
      const answerValue_ = answerValue[index];
      if (answerValue_ !== null && Answer.comment) {
        comments.push(Answer.comment);
      }
      if ((Answer.right && answerValue_ === null) || (!Answer.right && answerValue_ !== null)) {
        learned = false;
      }
    };
    switch (answer.type) {
      case "select_image":
        answer.selectImage.forEach(answerChecker);
        break;
      case "select_text":
        answer.selectText.forEach(answerChecker);
        break;
      case "select_audio":
        answer.selectAudio.forEach(answerChecker);
        break;
      default:
        learned = answer.keyboard.trim().toLowerCase() === keybAnswerValue.trim().toLowerCase();
        break;
    }

    const answerValueForApi = composeAnswerValueForApi(answer, answerValue, keybAnswerValue);

    if (!answer.requireAllAnswers && answerValue.find((item) => item === true)) {
      learned = true;
    }
    if (!learned) {
      let correctAnswerView = null;
      switch (answer.type) {
        case "select_image":
          correctAnswerView = exercise.answer.selectImage
            .filter((image) => image.right)
            .map((image, index) => (
              <img
                key={image.answer.path}
                style={{ width: "80%", marginTop: index > 0 ? 10 : undefined }}
                src={image.answer.url}
                alt=""
              />
            ));
          break;
        case "select_audio":
          correctAnswerView = exercise.answer.selectAudio
            .filter((audio) => audio.right)
            .map((audio) => (
              <FlexboxHorizontal
                data-tag="answer item"
                key={audio.answer.path}
                justifyContent="center"
                alignItems="center"
                marginTop="20px"
              >
                <LearnAudioPlayer url={audio.answer.url} />
              </FlexboxHorizontal>
            ));
          break;
        case "select_text":
          correctAnswerView = (
            <>
              {exercise.answer.selectText
                .filter((text) => text.right)
                .map((text, index) => (
                  <Chip
                    key={text.answer + index}
                    className={classes.rightAnswerChip}
                    variant="outlined"
                    label={text.answer}
                  />
                ))}
              {comments.length > 0 && (
                <>
                  <Box width="100%" padding="15px 0">
                    <Divider variant="fullWidth" />
                  </Box>
                  <WrongAnswerAlertComment comments={comments} />
                </>
              )}
            </>
          );
          break;
        case "keyboard":
          correctAnswerView = <Typography>{exercise.answer.keyboard}</Typography>;
          break;
      }
      if (answer.type === "select_text") {
        showRightAnswerWithComments(correctAnswerView, comments, answerValueForApi);
      } else {
        showRightAnswer(correctAnswerView, answerValueForApi);
      }
      setResetAnswerTrigger(!resetAnswerTrigger);
      return null;
    } else {
      showRightAnswerWithComments(null, comments, answerValueForApi);
      return { ...DEFAULT_LEARNED_PATCH, payload: { answerValue: answerValueForApi } };
    }
  };

  /** @param {IReactEventTarget<{isRight: Boolean, index: Number}>} target */
  const answerHandler = ({ target }) => {
    const { isRight, index } = target.value;
    const newArray = [...answerValue];
    if (newArray[index] === null) {
      newArray[index] = isRight;
    } else {
      newArray[index] = null;
    }
    setAnswerValue(newArray);
    setCheckButtonDisabled(newArray.findIndex((element) => element !== null) === -1);
  };

  /** @param {String} value */
  const keyboardAnswerHandler = ({ target: { value } }) => {
    setKeybAnswerValue(value);
    setCheckButtonDisabled(!value);
  };

  return (
    <Box {...rest} data-component="Exercise11" display="flex" flexDirection="column" alignItems="center">
      <Question
        exerciseQuestion={exercise.question}
        exerciseTitle={exerciseTitle}
        defaultTitle={
          exercise.answer.type === "keyboard"
            ? t("Exercises.type11.title.keyboard")
            : t("Exercises.type11.title.select")
        }
      />
      {exercise.appendix && exercise.appendix.pdf && <Appendix pdfUrl={exercise.appendix.pdf.url} />}
      <Answers
        classes={classes}
        exercise={exercise}
        answerValue={answerValue}
        keybAnswerValue={keybAnswerValue}
        answerHandler={answerHandler}
        keyboardAnswerHandler={keyboardAnswerHandler}
      />
    </Box>
  );
};

Exercise11.propTypes = {
  checkAnswerRef: PropTypes.object.isRequired,
  exercise: PropTypes.object.isRequired,
  exerciseTitle: PropTypes.string,
  setCheckButtonDisabled: PropTypes.func.isRequired,
  showRightAnswer: PropTypes.func.isRequired,
  showRightAnswerWithComments: PropTypes.func.isRequired,
};

export default Exercise11;
