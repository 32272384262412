import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core";
import DeckCard from "./DeckCard.jsx";
import { useCourseProvider } from "../../../Providers/Data/CourseProvider.jsx";
import DeckDataProvider from "../../../Providers/Data/DeckDataProvider.jsx";

const useStyles = makeStyles((theme) => ({
  deckListRoot: {
    display: "flex",
    flexWrap: "wrap",
    margin: "12px 0 30px",
    width: "calc(100% + 18px)",
    transform: "translateX(-9px)",
    [theme.breakpoints.up("lg")]: {
      width: "unset",
      transform: "none",
    },
  },
}));

/** @returns {null|*}
 * @constructor
 */
const DeckList = () => {
  const classes = useStyles();
  const { currentBranch } = useCourseProvider();

  if (!currentBranch) {
    return null;
  }

  return (
    <Box className={classes.deckListRoot}>
      {currentBranch.decks.map((deck) => (
        <DeckDataProvider key={deck.id} deck={deck} branch={currentBranch}>
          <DeckCard />
        </DeckDataProvider>
      ))}
    </Box>
  );
};

export default DeckList;
