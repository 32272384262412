import React, { useEffect, useState } from "react";
import { Box, makeStyles, Typography, Badge } from "@material-ui/core";
import grey from "@material-ui/core/colors/grey.js";
import { useTranslate } from "react-polyglot";
import gold from "../../assets/ic_reward.svg";
import silver from "../../assets/ic_reward-silver.svg";
import bronze from "../../assets/ic_reward-bronze.svg";
import target from "../../assets/ic_target.svg";
import targetCompleted from "../../assets/ic_target_completed.svg";
import { useMissions } from "../../Providers/Data/MissionsProvider.jsx";
import BaseAccordion from "../../SharedComponents/BaseAccordion.jsx";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: "auto",
    padding: "35px 20px",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 0,
      paddingRight: 0,
      maxWidth: 700,
      width: 700,
      alignSelf: "center",
    },
  },
  rewardWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 35,
  },
  title: {
    textTransform: "uppercase",
    fontSize: 24,
    fontWeight: 500,
    color: "#00213A",
  },
  rewardImg: {
    width: 40,
    height: 50,
  },
  rewardBadge: {
    margin: "0 15px",
    "& .MuiBadge-badge": {
      top: 15,
      right: 5,
      color: theme.palette.background.default,
    },
  },
  accordion: {
    marginBottom: 5,
  },
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    "& .MuiAccordionSummary-content": {
      margin: 0,
      alignItems: "center",
    },
    "& .MuiIconButton-root": {
      color: theme.palette.background.default,
    },
  },
  headIcon: {
    width: 50,
    height: 60,
    marginRight: 35,
  },
  headTitle: {
    fontSize: 12,
    fontWeight: 700,
    marginBottom: 5,
  },
  headText: {
    fontSize: 12,
  },
  accordionItem: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "15px 25px",
    borderBottom: "1px solid rgba(0, 0, 0, .1)",
    backgroundColor: grey[50],
  },
  accordionContent: {
    padding: 0,
    boxShadow: "none",
    flexDirection: "column",
  },
  accordionReward: {
    width: 35,
    height: 50,
  },
  contentTitle: {
    color: theme.palette.secondary.dark,
    fontSize: 12,
  },
  contentText: {
    color: "black",
    fontSize: 12,
  },
}));

/**
 * @returns {*}
 * @constructor
 */
const Missions = () => {
  const classes = useStyles();
  const t = useTranslate();
  const { missions } = useMissions();
  const [isFirstMissionExpanded, setFirstMissionExpanded] = useState(true);
  const [totalRewards, setTotalRewards] = useState({ br: 0, sl: 0, gl: 0 });

  // calc total count of rewards
  useEffect(() => {
    if (missions) {
      const total = { br: 0, sl: 0, gl: 0 };
      missions.forEach((mission) => {
        mission.tasks.forEach((task) => {
          if (task.learned) {
            switch (task.rank) {
              case 1:
                total.gl++;
                break;
              case 2:
                total.sl++;
                break;
              default:
                total.br++;
                break;
            }
          }
        });
      });
      setTotalRewards(total);
    }
  }, [missions]);

  /** */
  const firstAccordionExpandHandler = () => {
    setFirstMissionExpanded(!isFirstMissionExpanded);
  };

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.rewardWrapper}>
        <Typography className={classes.title}>missions</Typography>
        <Badge className={classes.rewardBadge} badgeContent={totalRewards.br} color="primary" invisible={false}>
          <img className={classes.rewardImg} src={bronze} alt="bronze" />
        </Badge>
        <Badge className={classes.rewardBadge} badgeContent={totalRewards.sl} color="primary" invisible={false}>
          <img className={classes.rewardImg} src={silver} alt="silver" />
        </Badge>
        <Badge className={classes.rewardBadge} badgeContent={totalRewards.gl} color="primary" invisible={false}>
          <img className={classes.rewardImg} src={gold} alt="gold" />
        </Badge>
      </Box>
      {missions.map((mission, i) => (
        <BaseAccordion
          key={mission.id}
          className={classes.accordion}
          expanded={i === 0 ? isFirstMissionExpanded : undefined}
          onChange={i === 0 ? firstAccordionExpandHandler : undefined}
          summaryClassName={classes.head}
          summary={
            <>
              <img className={classes.headIcon} src={mission.learned ? targetCompleted : target} alt="target" />
              <div>
                <Typography className={classes.headTitle}>{mission.name}</Typography>
                <Typography className={classes.headText}>{mission.description}</Typography>
              </div>
            </>
          }
          detailsClassName={classes.accordionContent}
        >
          {mission.tasks.map((task) => {
            let rankIcon = bronze;
            switch (task.rank) {
              case 1:
                rankIcon = gold;
                break;
              case 2:
                rankIcon = silver;
                break;
            }

            return (
              <div key={task.id} className={classes.accordionItem}>
                <div>
                  <Typography variant="caption" color={task.learned ? "primary" : "textSecondary"}>
                    {task.learned ? t("completed") : t("notCompleted")}
                  </Typography>
                  <Typography className={classes.contentTitle}>{task.name}</Typography>
                  <Typography className={classes.contentText}>{task.data.content}</Typography>
                </div>
                <img
                  className={classes.accordionReward}
                  src={rankIcon}
                  alt="rank"
                  style={{ opacity: task.learned ? 1 : 0.2 }}
                />
              </div>
            );
          })}
        </BaseAccordion>
      ))}
    </Box>
  );
};

export default Missions;
