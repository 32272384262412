import PropTypes from "prop-types";
import React from "react";
import { withStyles } from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: 0,
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    minHeight: 40,
    "&$expanded": {
      minHeight: 40,
    },
  },
  content: {
    margin: 0,
    "&$expanded": {
      margin: 0,
    },
  },
  expandIcon: {
    padding: "0 25px",
  },
  expanded: {},
})(MuiAccordionSummary);

/**
 * @param {React.ReactNode} children
 * @param {React.ReactNode} summary
 * @param {String} summaryClassName
 * @param {String} detailsClassName
 * @param {Object} rest
 */
const BaseAccordion = ({ children, summary, summaryClassName, detailsClassName, ...rest }) => {
  return (
    <Accordion square {...rest}>
      <AccordionSummary className={summaryClassName} expandIcon={<ExpandMoreIcon />}>
        {summary}
      </AccordionSummary>
      <AccordionDetails className={detailsClassName}>{children}</AccordionDetails>
    </Accordion>
  );
};

BaseAccordion.propTypes = {
  children: PropTypes.node,
  detailsClassName: PropTypes.string,
  summary: PropTypes.node,
  summaryClassName: PropTypes.string,
};

export default BaseAccordion;
