import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, withStyles } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import icSound from "../assets/ic_sound.png";

const progressCircleThickness = 2;
const componentSize = 70;

const StyledIconButton = withStyles({
  root: {
    width: componentSize,
    height: componentSize,
  },
})(IconButton);

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: componentSize,
    maxHeight: componentSize,
    minWidth: componentSize,
    minHeight: componentSize,
    position: "relative",
  },
  backCircle: {
    color: "rgba(0, 33, 58, 0.2)",
    position: "absolute",
  },
  mainCircle: {
    position: "absolute",
  },
  soundIconRoot: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  soundIcon: {
    objectFit: "contain",
    maxWidth: componentSize / 1.5,
  },
}));

/**
 * @param {String} className
 * @param {String} src
 */
function AudioPlayer({ className, src }) {
  const classes = useStyles();
  /** @type {React.MutableRefObject<HTMLMediaElement>} */
  const audioRef = useRef();
  const [playingProgress, setPlayingProgress] = useState(0);
  const [bufferProgress, setBufferProgress] = useState(0);

  /** */
  const buttonClickHandler = () => {
    if (audioRef.current) {
      const player = audioRef.current;
      if (player.paused) {
        player.play().then();
      } else {
        player.pause();
        player.currentTime = 0;
      }
    }
  };

  useEffect(() => {
    if (!audioRef.current) {
      return;
    }
    const player = audioRef.current;
    /** @returns {Number} */
    const getBufferProgress = () => {
      if (player.buffered.length > 0) {
        return (player.buffered.end(0) / player.duration) * 100;
      } else {
        return 0;
      }
    };
    setTimeout(() => {
      const bufferProgress1 = getBufferProgress();
      setBufferProgress(bufferProgress1);
    }, 1000);
    /** */
    const playingProgressHandler = () => {
      const length = player.duration;
      const currentTime = player.currentTime;
      setPlayingProgress((currentTime / length) * 100);
    };
    /** */
    const endPlayingHandler = () => {
      setTimeout(() => {
        setPlayingProgress(0);
      }, 500);
    };
    /** */
    const loadingHandler = () => {
      setBufferProgress(getBufferProgress());
    };
    player.addEventListener("progress", loadingHandler);
    player.addEventListener("timeupdate", playingProgressHandler);
    player.addEventListener("ended", endPlayingHandler);

    return () => {
      player.removeEventListener("progress", loadingHandler);
      player.removeEventListener("timeupdate", playingProgressHandler);
      player.removeEventListener("ended", endPlayingHandler);
    };
  }, [src]);

  return (
    <Box className={clsx(className, classes.root)}>
      <CircularProgress
        className={classes.backCircle}
        variant="determinate"
        size={componentSize}
        thickness={progressCircleThickness}
        value={bufferProgress}
      />
      <CircularProgress
        className={classes.mainCircle}
        variant="determinate"
        color="primary"
        size={componentSize}
        thickness={progressCircleThickness}
        value={playingProgress}
      />
      <Box className={classes.soundIconRoot}>
        <img className={classes.soundIcon} src={icSound} alt="" />
        <audio ref={audioRef} src={src} />
      </Box>
      <StyledIconButton onClick={buttonClickHandler} />
    </Box>
  );
}

AudioPlayer.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
};

export default AudioPlayer;
