import { useEffect, useState } from "react";

/**
 * @returns {{isFullscreen: boolean}}
 */
export function useFullscreenMode() {
  const [isFullscreen, setFullscreen] = useState(false);

  useEffect(() => {
    /** */
    const fullscreenEventHandler = () => {
      if (document.fullscreenElement) {
        setFullscreen(true);
      } else {
        setFullscreen(false);
      }
    };

    document.addEventListener("fullscreenchange", fullscreenEventHandler);

    return () => {
      document.removeEventListener("fullscreenchange", fullscreenEventHandler);
    };
  }, []);

  return { isFullscreen };
}
