import { Button, withStyles } from "@material-ui/core";

const MainStyleButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    textTransform: "none",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: "0.9",
    },
  },
}))(Button);

export default MainStyleButton;
