import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const ExamProgressTypography = withStyles((theme) => ({
  root: {
    fontWeight: 600,
    fontSize: 18,
  },
  colorPrimary: {
    color: theme.palette.primary.dark,
  },
}))(Typography);

export default ExamProgressTypography;
