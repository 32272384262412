import { format } from "date-fns";
import { ru, enUS, uk, fr } from "date-fns/locale";
import { useLocale } from "../Providers/i18n/I18nProvider.jsx";

const localeMap = {
  ru,
  en: enUS,
  uk,
  fr,
};

/**
 * @callback FnFormat
 * @param {Date} date
 * @param {String} pattern
 * @returns {String}
 */

/**
 * @returns {{format: FnFormat}}
 */
const useDateUtils = () => {
  const { locale } = useLocale();

  return {
    /** @type {FnFormat} */
    format: (date, pattern) => format(date, pattern, { locale: localeMap[locale] }),
  };
};

export default useDateUtils;
