import PropTypes from "prop-types";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { Button, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useTranslate } from "react-polyglot";
import { useHistory } from "react-router-dom";
import { fade } from "@material-ui/core/styles";
import FlexboxVertical from "../../../SharedComponents/FlexboxVertical.jsx";
import FlexboxHorizontal from "../../../SharedComponents/FlexboxHorizontal.jsx";
import { useHomeworks } from "../../../Providers/Data/HomeworksProvider.jsx";
import icLock from "../../../assets/ic_lock.png";
import bookIcon from "../../../assets/ic_bottom_tab_homework.svg";
import { stripHtmlTags } from "../../../Utils/misc";

const useStyles = makeStyles((theme) => ({
  itemRoot: {
    transitionProperty: "background",
    transitionDuration: theme.transitions.duration.standard,
    "$clickable&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  itemContent: {
    padding: `20px 0`,
  },
  leftBlock: {
    margin: "0 20px",
    minWidth: 100,
    alignItems: "center",
    justifyContent: "center",
  },
  rightBlock: {
    "&>*:not(:first-child)": {
      marginTop: 10,
    },
  },
  levelIcon: {
    width: 50,
    height: 50,
    objectFit: "contain",
  },
  hr: {
    width: "100%",
    borderStyle: "solid",
    color: theme.palette.grey[300],
    margin: 0,
  },
  dimming: {
    position: "absolute",
    backgroundColor: fade(theme.palette.primary.light, 0.3),
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  clickable: {
    cursor: "pointer",
  },
  lockIcon: {
    position: "absolute",
    width: 30,
    top: 14,
    left: 24,
  },
  date: {
    color: theme.palette.grey[600],
    fontWeight: 500,
    marginTop: 5,
  },
  bold: {
    fontWeight: 700,
  },
  onlyInAppMessage: {
    position: "absolute",
    right: 10,
    bottom: 10,
  },
  cutText: {
    wordBreak: "break-word",
    fontWeight: 700,
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
  },
}));

/**
 * @param {PendingHomework} pendingHomework
 */
const ListItem = ({ pendingHomework }) => {
  const classes = useStyles();
  const history = useHistory();
  const t = useTranslate();

  const data = pendingHomework.data;
  const isHomeworkAvailable =
    pendingHomework.type === "homework_todo" ||
    (data.deck && data.deck.open && data.branch && data.branch.open && data.level && data.level.open);

  const question = data.question;
  const partOfQuestion = question.text && question.text.split("///");

  /** */
  const exerciseHomeworkItemClickHandler = () => {
    history.push(`/learn/${data.deck.id}/exercise/${data.id}`);
  };

  /** */
  const toDoHomeworkItemClickHandler = () => {
    history.push(`/homeworks/todo/${pendingHomework.id}`);
  };

  /** */
  const onItemClick = () => {
    if (isHomeworkAvailable) {
      pendingHomework.type === "exercise" ? exerciseHomeworkItemClickHandler() : toDoHomeworkItemClickHandler();
    }
  };

  const levelIcon = data.level?.imageUrl || data.level?.image?.url || bookIcon;

  return (
    <FlexboxVertical
      data-component="ListItem"
      position="relative"
      className={clsx({ [classes.clickable]: isHomeworkAvailable }, classes.itemRoot)}
      onClick={onItemClick}
    >
      <FlexboxHorizontal className={classes.itemContent}>
        <FlexboxVertical className={classes.leftBlock}>
          <img className={classes.levelIcon} src={levelIcon} alt="" />
          <Typography className={classes.date}>{t(`homeworks.types.${pendingHomework.type}`)}</Typography>
          {!isHomeworkAvailable && <img src={icLock} className={classes.lockIcon} alt="" />}
        </FlexboxVertical>
        <FlexboxVertical className={classes.rightBlock}>
          <Typography className={classes.cutText}>{stripHtmlTags(partOfQuestion[0])}</Typography>
          {pendingHomework.type === "exercise" && (
            <>
              <Typography>
                <span className={classes.bold}>{t("homeworks.topic")}</span>&nbsp;&nbsp;
                <span>{data.branch ? data.branch.name : "-"}</span>
              </Typography>
              <Typography>
                <span className={classes.bold}>{t("homeworks.lesson")}</span>&nbsp;&nbsp;
                <span>{data.deck ? data.deck.name : "-"}</span>
              </Typography>
            </>
          )}
        </FlexboxVertical>
        {!isHomeworkAvailable && <div className={classes.dimming} />}
      </FlexboxHorizontal>
      <hr className={classes.hr} />
    </FlexboxVertical>
  );
};

ListItem.propTypes = {
  pendingHomework: PropTypes.object.isRequired,
};

/** */
const ToDoList = () => {
  const { pendingHomeworks, showMorePendingHW } = useHomeworks();
  const t = useTranslate();

  return (
    <FlexboxVertical data-component="ToDoList">
      {pendingHomeworks.map((homework) => (
        <ListItem
          key={"" + homework.id + homework.level?.id + homework.branch?.id + homework.deck?.id}
          pendingHomework={homework}
        />
      ))}
      {pendingHomeworks.length >= 10 && (
        <Button variant="contained" color="primary" size="small" fullWidth onClick={showMorePendingHW}>
          {t("showMore")}
        </Button>
      )}
    </FlexboxVertical>
  );
};

export default ToDoList;
