import PropTypes from "prop-types";
import React from "react";
import Typography from "@material-ui/core/Typography";
import "./../Utils/extensions.js";

//const useStyles = makeStyles(
//  () => ({
//    root: {
//      fontSize: ({ isArabic, arabicSize }) => {
//        if (isArabic === true) {
//          return arabicSize;
//        }
//      },
//      position: "relative",
//      "&::before": {
//        content: ({ isArabic, arabicSize }) => `'${isArabic} - ${arabicSize}'`,
//        color: "red",
//        position: "absolute",
//        top: -15,
//        fontSize: 10,
//        whiteSpace: "nowrap",
//      },
//    },
//  }),
//  {
//    name: "ArabicTypography",
//  },
//);

/** @param {Number} arabicSize
 * @param {Object} rest
 * @returns {*}
 * @constructor
 */
const TypographyArabic = ({ arabicSize, ...rest }) => {
  const isArabic = rest.children.isArabic();
  //const classes = useStyles({ isArabic, arabicSize });

  return (
    <Typography
      {...rest}
      //classes={{
      //  root: classes.root,
      //}}
      style={{ fontSize: isArabic ? arabicSize : undefined }}
    />
  );
};

TypographyArabic.propTypes = {
  arabicSize: PropTypes.number,
  children: PropTypes.string,
  ...Typography.propTypes,
};

TypographyArabic.defaultProps = {
  arabicSize: 26,
};

export default TypographyArabic;
