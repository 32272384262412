import React from "react";
import MyBackdrop from "./MyBackdrop.jsx";
import { useHttp } from "../Providers/Common/HttpProvider.jsx";

/** */
const LoadingBackdrop = () => {
  const { isLoading } = useHttp();

  return <MyBackdrop open={isLoading} />;
};

export default LoadingBackdrop;
