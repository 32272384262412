import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, makeStyles, Typography } from "@material-ui/core";
import grey from "@material-ui/core/colors/grey.js";
import { useTranslate } from "react-polyglot";
import { ReactComponent as Points } from "../assets/ic_points.svg";
import { ReactComponent as Phrases } from "../assets/ic_phrases.svg";
import { ReactComponent as Missions } from "../assets/ic_missions.svg";
import { ReactComponent as Words } from "../assets/ic_words-a.svg";
import { useBaseData } from "../Providers/Data/BaseDataProvider.jsx";
import { calculateEndDateOfAccess } from "../Utils/misc.js";
import { useUserPreferences } from "../Providers/Data/UserPreferencesProvider.jsx";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: grey[50],
    fontSize: 12,
    marginBottom: 20,
    width: "49%",
    borderRadius: 10,
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: 0,
    },
    "&:last-child": {
      width: "100%",
    },
  },
  statWrapper: {
    marginTop: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  statItem: {
    margin: "0 15px 0",
    textAlign: "center",
  },
  statIcon: {
    width: 40,
    height: 40,
    marginBottom: 7,
    "& path": {
      fill: theme.palette.primary.main,
    },
  },
  statProgress: {
    fontSize: 20,
    fontWeight: 900,
    color: theme.palette.grey[800],
    marginBottom: 5,
  },
  statName: {
    fontSize: 12,
    fontWeight: 300,
  },
}));

/** */
const StudentProgressStatistic = () => {
  const classes = useStyles();
  const t = useTranslate();
  const { lang } = useUserPreferences();
  const { availableSubjects, subject, counts, userInfo } = useBaseData();
  const [endLocalDate, setEndDate] = useState("");
  const [endLocalDemoDate, setDemoEndDate] = useState("");

  useEffect(() => {
    if (userInfo.isDemoMode) {
      const demoDays = 7;
      const endDate = userInfo.demoStartAt + demoDays * 24 * 60 * 60 * 1000;
      setDemoEndDate(new Date(endDate).toLocaleDateString(lang, { dateStyle: "medium" }));
    } else {
      calculateEndDateOfAccess(userInfo, setEndDate);
    }
  }, [userInfo, lang]);

  if (!availableSubjects || !counts) {
    return null;
  }

  return (
    <Card className={classes.card} elevation={4}>
      <CardContent>
        <Typography align="center">
          <b>{t("currentCourse")}: </b>
          {(availableSubjects.find((subj) => subj.id === subject.subjectId) || { name: "" }).name}
        </Typography>
        {(endLocalDemoDate || endLocalDate) && (
          <Typography align="center">
            {t("availableUntilDate", { endDate: endLocalDemoDate ? endLocalDemoDate : endLocalDate })}
          </Typography>
        )}
        <Typography align="center">
          <b>{t("statistic.title")}</b>
        </Typography>
        <Box className={classes.statWrapper}>
          <div className={classes.statItem}>
            <Points className={classes.statIcon} />
            <Typography className={classes.statProgress}>{counts.points}</Typography>
            <Typography className={classes.statName}>{t("statistic.points")}</Typography>
          </div>
          <div className={classes.statItem}>
            <Phrases className={classes.statIcon} />
            <Typography className={classes.statProgress}>{counts.phrases.learnedCount}</Typography>
            <Typography className={classes.statName}>{t("statistic.phrases")}</Typography>
          </div>
          <div className={classes.statItem}>
            <Missions className={classes.statIcon} />
            <Typography className={classes.statProgress}>{counts.missions.learnedCount}</Typography>
            <Typography className={classes.statName}>{t("statistic.missions")}</Typography>
          </div>
          <div className={classes.statItem}>
            <Words className={classes.statIcon} />
            <Typography className={classes.statProgress}>{counts.words.learnedCount}</Typography>
            <Typography className={classes.statName}>{t("statistic.words")}</Typography>
          </div>
        </Box>
      </CardContent>
    </Card>
  );
};

export default StudentProgressStatistic;
