import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(
  () => ({
    root: {
      "& .os-scrollbar .os-scrollbar-track .os-scrollbar-handle": {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
    },
  }),
  {
    name: "makeScrollbarsStyles",
  },
);

const MyScrollbars = forwardRef((props, ref) => {
  const classes = useStyles();

  return (
    <OverlayScrollbarsComponent
      ref={ref}
      {...props}
      className={clsx(classes.root, props.className)}
      options={{ scrollbars: { clickScrolling: true } }}
    />
  );
});

MyScrollbars.propTypes = {
  className: PropTypes.string,
};

MyScrollbars.displayName = "MyScrollbars";

export default MyScrollbars;
