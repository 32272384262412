import { Button, withStyles } from "@material-ui/core";

const TransparentButton = withStyles((theme) => ({
  root: {
    width: 65,
    height: 65,
    padding: 0,
    color: theme.palette.text.secondary,
  },
  label: {
    "& .MuiSvgIcon-root": {
      width: 40,
      height: 40,
    },
  },
}))(Button);

export default TransparentButton;
