import React from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

const progressCircleThickness = 5;
const valueColors = ["#D23200", "#DE781A", "#F3C100", "#1A854B"];

const useStyles = makeStyles((theme) => ({
  percentText: {
    fontSize: ({ componentSize }) => componentSize / 5,
    margin: "0 0 0 5px",
  },
  backCircle: {
    color: theme.palette.grey[200],
    position: "absolute",
  },
  frontCircle: {
    color: ({ progressColor }) => progressColor,
  },
  dot: {
    position: "absolute",
    backgroundColor: ({ progressColor }) => progressColor,
    width: ({ dotSize }) => dotSize,
    height: ({ dotSize }) => dotSize,
    borderRadius: ({ dotSize }) => dotSize / 2,
  },
}));

/** @param {Number} progress
 * @param {Number} componentSize
 * @param {Number} dotSize
 * @returns {{top: any, left: any}}
 */
function calcDotPosition(progress, componentSize, dotSize) {
  const circleRadius = componentSize / 2 - dotSize / 4;
  const angle = (progress * (Math.PI * 2)) / 100;
  const x = circleRadius * Math.sin(angle);
  const y = circleRadius * Math.cos(angle);
  return {
    left: componentSize / 2 + x - dotSize / 2,
    top: componentSize / 2 - y - dotSize / 2,
  };
}

/** @param {String} className
 * @param {Number} componentSize
 * @param {String} [textValue]
 * @param {Object} rest
 * @returns {*}
 * @constructor
 */
function CircularProgressWithLabel({ className, componentSize, textValue, ...rest }) {
  const { value } = rest;
  let progressColor;
  if (value < 30) {
    progressColor = valueColors[0];
  } else if (value < 70) {
    progressColor = valueColors[1];
  } else if (value < 100) {
    progressColor = valueColors[2];
  } else {
    progressColor = valueColors[3];
  }
  const dotSize = componentSize * 0.2267;
  const classes = useStyles({ progressColor, componentSize, dotSize });

  return (
    <Box className={className} position="relative" display="inline-flex">
      <CircularProgress
        className={classes.backCircle}
        variant="determinate"
        size={componentSize}
        thickness={progressCircleThickness}
        {...rest}
        value={100}
      />
      <CircularProgress
        className={classes.frontCircle}
        variant="determinate"
        size={componentSize}
        thickness={progressCircleThickness}
        {...rest}
      />
      <div className={classes.dot} style={calcDotPosition(value, componentSize, dotSize)} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography className={classes.percentText} variant="subtitle2" component="div">
          {textValue || `${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * @type Validator<Number>
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
  /** @type Validator<String> */
  className: PropTypes.string,
  /** @type Validator<Number> */
  componentSize: PropTypes.number,
  textValue: PropTypes.string,
};

CircularProgressWithLabel.defaultProps = {
  componentSize: 75,
};

export default CircularProgressWithLabel;
