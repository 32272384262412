import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-polyglot";
import { Link, Typography, Menu, MenuItem } from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import CreditCard from "@material-ui/icons/CreditCardOutlined";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../Providers/Auth/AuthProvider.jsx";
import { useBaseData } from "../../../Providers/Data/BaseDataProvider.jsx";
import FlexboxHorizontal from "../../../SharedComponents/FlexboxHorizontal.jsx";
import useCompanyPublicId from "../../../hooks/useCompanyPublicId.js";
import YellowButton from "../../../SharedComponents/YellowButton.jsx";
import MainStyleButton from "../../../SharedComponents/MainStyleButton.jsx";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "40px 0",
  },
  content: {
    "& h1": {
      fontWeight: 500,
      fontSize: 24,
      marginBottom: 24,
    },
  },
  logo: {
    width: 200,
    height: 200,
    borderRadius: 10,
    backgroundColor: "#ffffff",
    objectFit: "contain",
    marginRight: 40,
  },
  contacts: {
    marginBottom: 24,
    fontSize: 14,
    fontWeight: 500,

    "& svg": {
      color: theme.palette.primary.main,
      marginRight: 12,
      verticalAlign: "middle",
    },
  },
  description: {
    fontSize: 14,
    lineHeight: "normal",
  },
  hr: {
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    borderRadius: 2,
  },
  action: {
    flexShrink: 0,
    marginLeft: "auto",
  },
  userName: {
    fontWeight: 800,
    textTransform: "none",
    fontSize: 12,
    margin: 5,
  },
}));

/** */
const CompanyHeader = () => {
  const t = useTranslate();
  const history = useHistory();
  const { companyName } = useCompanyPublicId();
  const classes = useStyles();
  const { company2, user, signOut } = useAuth();
  const { company, userInfo } = useBaseData();

  const company_ = company || company2;

  const [anchorEl, setAnchorEl] = useState(null);

  /** */
  const enterButtonHandler = () => {
    history.push(`${companyName ? "/" + companyName : ""}/auth`);
  };

  /** */
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  /** */
  const logoutClickHandler = () => {
    history.push("/");
    signOut();
  };
  /** */
  const onProfile = () => {
    handleMenuClose();
    history.push("/profile");
  };
  /** */
  const onOrders = () => {
    handleMenuClose();
    history.push("/orders");
  };
  /** @param {Event} event */
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const menuId = "account-menu";
  const isMenuOpen = Boolean(anchorEl);

  if (!company_) {
    return null;
  }

  return (
    <>
      <div className={classes.root}>
        <FlexboxHorizontal>
          <Link component="button" onClick={() => history.push(`${companyName ? "/" + companyName : "/"}`)}>
            <div>
              <img className={classes.logo} src={company_.logo.url} alt="company logo" />
            </div>
          </Link>
          <div className={classes.content}>
            <Link component="button" onClick={() => history.push(`${companyName ? "/" + companyName : "/"}`)}>
              <Typography component="h1">{company_.name}</Typography>
            </Link>
            {company_.email && (
              <Typography variant="body1" color="textPrimary" component="p" className={classes.contacts}>
                <MailIcon /> {company_.email}
              </Typography>
            )}
            {company_.phone && (
              <Typography variant="body1" color="textPrimary" component="p" className={classes.contacts}>
                <PhoneIcon /> {company_.phone}
              </Typography>
            )}
            <Typography color="textPrimary" component="p" className={classes.description}>
              {company_.description ? company_.description : t("noDescription")}
            </Typography>
          </div>
          {!user && (
            <div className={classes.action}>
              <YellowButton onClick={enterButtonHandler}>
                <Typography>{t("studentEnter")}</Typography>
              </YellowButton>
            </div>
          )}
          {user && userInfo && (
            <>
              <div className={classes.action}>
                <MainStyleButton onClick={handleProfileMenuOpen}>
                  <Typography className={classes.userName}>{userInfo.name}</Typography>
                  <AccountCircleOutlinedIcon />
                </MainStyleButton>
              </div>
              <Menu
                anchorEl={anchorEl}
                id={menuId}
                keepMounted
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                getContentAnchorEl={null}
                open={isMenuOpen}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={onProfile}>
                  <AccountCircleOutlinedIcon />
                  <Typography className={classes.menuText}>{t("profile")}</Typography>
                </MenuItem>
                <MenuItem onClick={onOrders}>
                  <CreditCard />
                  <Typography className={classes.menuText}>{t("orders")}</Typography>
                </MenuItem>
                <MenuItem onClick={logoutClickHandler}>
                  <PowerSettingsNewIcon />
                  <Typography className={classes.menuText}>{t("logout")}</Typography>
                </MenuItem>
              </Menu>
            </>
          )}
        </FlexboxHorizontal>
      </div>
      <hr className={classes.hr} />
    </>
  );
};

export default CompanyHeader;
