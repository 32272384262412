import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useCourseProvider } from "../../../Providers/Data/CourseProvider.jsx";
import SmallDeckCard from "./SmallDeckCard.jsx";
import MyScrollbars from "../../../SharedComponents/MyScrollbars.jsx";
import DeckDataProvider from "../../../Providers/Data/DeckDataProvider.jsx";
import useMediaQueryUp from "../../../hooks/useMediaQueryUp.js";
import Carousel from "../../../SharedComponents/Carousel.jsx";

let deckHeight = 131; // 131 is default experimental value

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: 145,
    transition: "maxHeight 1s",
    padding: "0 16px",
    [theme.breakpoints.up("lg")]: {
      maxHeight: 410,
    },
  },
  smallSidebar: {
    [theme.breakpoints.up("lg")]: {
      maxHeight: 556,
    },
  },
}));

/** @returns {null|*}
 * @constructor
 */
const SmallDeckList = ({ isOpen }) => {
  let { deckId } = useParams();
  try {
    deckId = Number.parseInt(deckId);
    //eslint-disable-next-line no-empty
  } catch (e) {}
  const { currentBranch } = useCourseProvider();
  const osRef = useRef(/** @type {?OverlayScrollbarsComponent} */ null);
  const rootBoxRef = useRef(/** @type {?HTMLElement} */ null);
  const classes = useStyles();
  const isLargeScreen = useMediaQueryUp("lg");
  const sliderSettings = {
    slidesToShow: currentBranch.decks.length > 7 ? 7 : currentBranch.decks.length,
    initialSlide: 0,
    slidesToScroll: 3,
    infinite: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: currentBranch.decks.length > 5 ? 5 : currentBranch.decks.length,
          slidesToScroll: 3,
          infinite: true,
        },
      },
    ],
  };

  // autoscroll to current selected deck
  useEffect(() => {
    if (rootBoxRef.current) {
      const rootBoxHeight = rootBoxRef.current.getBoundingClientRect().height;
      const rowCount = Math.ceil(currentBranch.decks.length / 3);
      deckHeight = rootBoxHeight / rowCount;
    }
    const osInstance = osRef.current && osRef.current.osInstance();
    if (osInstance) {
      const deckIndex = currentBranch.decks.findIndex((deck) => deck.id === deckId);
      if (deckIndex > -1) {
        const rowIndex = Math.floor(deckIndex / 3);
        const scrollTo = rowIndex * deckHeight + 10;
        osInstance.scroll(scrollTo, 500);
      }
    }
  }, [currentBranch, deckId]);

  if (!currentBranch) {
    return null;
  }

  return (
    <>
      {isLargeScreen ? (
        <MyScrollbars ref={osRef} className={clsx(classes.root, { [classes.smallSidebar]: !isOpen })}>
          <Box ref={rootBoxRef} data-component="SmallDeckList" display="flex" justifyContent="center" margin="10px 0 0">
            <Box display="flex" flexWrap="wrap" maxWidth="339px" margin="0 4px 0 0">
              {currentBranch.decks.map((deck) => (
                <DeckDataProvider key={deck.id} deck={deck} branch={currentBranch}>
                  <SmallDeckCard disabled={deck.id !== Number.parseInt(deckId)} />
                </DeckDataProvider>
              ))}
            </Box>
          </Box>
        </MyScrollbars>
      ) : (
        <Carousel
          className={classes.branchListRoot}
          arrowButtonsClassName={classes.carouselArrows}
          sliderSettings={sliderSettings}
        >
          {currentBranch.decks.map((deck) => (
            <DeckDataProvider key={deck.id} deck={deck} branch={currentBranch}>
              <SmallDeckCard disabled={deck.id !== Number.parseInt(deckId)} />
            </DeckDataProvider>
          ))}
        </Carousel>
      )}
    </>
  );
};

SmallDeckList.propTypes = {
  isOpen: PropTypes.bool,
};

export default SmallDeckList;
