import { useRouteMatch } from "react-router-dom";
import lodash from "lodash";

/**
 * @param {String} routePattern
 * @param {String} paramName
 * @returns {?Number}
 */
const useExtractRouteParamInt = (routePattern, paramName) => {
  const routeMatch = useRouteMatch(routePattern);

  let paramValue = lodash.get(routeMatch, `params.${paramName}`);
  if (paramValue) {
    const number = Number.parseInt(paramValue);
    return isNaN(number) ? null : number;
  }
  return null;
};

export default useExtractRouteParamInt;
