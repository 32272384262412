import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useTranslate } from "react-polyglot";
import { useHistory } from "react-router-dom";
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";
import LevelsAndDecks from "../LeftSideComponents/LevelsAndDecks.jsx";
import DeckContent from "./DeckContent.jsx";
import LearnProcessProvider from "../../../Providers/Data/LearnProcessProvider.jsx";
import useCompanyTitle from "../../../hooks/useCompanyTitle.js";

const useStyles = makeStyles((theme) => ({
  backBtn: {
    marginTop: "16px",
    fontWeight: "bold",
  },
  learnRoot: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
  },
  infoCardsRight: {
    display: "flex",
    flexDirection: "column",
    margin: "32px 16px 0 16px",
    [theme.breakpoints.up("lg")]: {
      margin: "16px 40px 0 0",
    },
    position: "relative",
  },
  infoCardsLeft: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  trainingButton: {
    margin: "15px 0 0",
    [theme.breakpoints.up("lg")]: {
      margin: "15px 0 30px",
    },
  },
}));

/** */
const Learn = () => {
  const classes = useStyles();
  const history = useHistory();
  const t = useTranslate();

  useCompanyTitle("title.learn");

  return (
    <Box className={classes.learnRoot}>
      <Box>
        <Button className={classes.backBtn} variant="text" onClick={() => history.goBack()}>
          <ArrowBackIosSharpIcon color="primary" />
          {t("back")}
        </Button>
        {/*        <Box className={classes.infoCardsLeft}>
          <LevelsCard tinyMode={true} />
        </Box>*/}

        <Box className={classes.infoCardsRight}>
          <LevelsAndDecks />
          {/*<StartTrainingButton className={classes.trainingButton} />*/}
        </Box>
      </Box>
      <LearnProcessProvider>
        <DeckContent />
      </LearnProcessProvider>
    </Box>
  );
};

export default Learn;
