export default {
  title: {
    login: "%{school} - Вхід",
    registration: "%{school} - Реєстрація",
    subjects: "%{school} - Вибір предмету",
    subject: "%{school} - Предмет",
    offer: "%{school} - Пропозиція",
    home: "%{school} - Домашня сторінка",
    dictionary: "%{school} - Словник",
    events: "%{school} - Події",
    news: "%{school} - Новини",
    profile: "%{school} - Профіль",
    learn: "%{school} - Навчання",
    pay: "%{school} - Оплата предмету",
    checkInSystem: "Вкажіть ваш Email",
    setPassword: "Вкажіть пароль для входу.",
    createAccount: "Вкажіть/Перевірте дані вашого профілю в системі",
  },
  email: "Email",
  personalLink: "Особисте посилання",
  login: "Логін",
  name: "Ім'я",
  phone: "Телефон",
  aboutMe: "Про мене",
  registration: "Реєстрація",
  password: "Пароль",
  currentPassword: "Поточний пароль",
  newPassword: "Новий пароль",
  confirmPassword: "Повторіть пароль",
  confirmNewPassword: "Повторіть новий пароль",
  forgotPassword: "Забули пароль?",
  passwordsMatch: "Паролі не співпадають",
  resetPassword: "Пароль скинуто, перевірте свій Email.",
  resetConfirm: "Ви хочете скинути пароль?",
  enter: "Увійти",
  studentEnter: "Вхід для учнів",
  check: "Далі",
  emailIsNotRegistered: "Користувач з цією адресою не існує",
  logout: "Вийти",
  cancel: "Скасувати",
  yes: "Так",
  no: "Ні",
  course: "Курс",
  dictionary: "Словник",
  events: "Події",
  news: "Новини",
  words: "Слова",
  wordsAreHere: "Ці слова знаходяться тут:",
  allWords: "Всі слова",
  phrases: "Фрази",
  videos: "Відео",
  exercises: "Вправи",
  theory: "Теорія",
  theoryAlt: "Конспект",
  theoryAdditionalFile: "Завантажити додатковий файл (%{filename})",
  topic: "Тема",
  lesson: "Урок",
  profile: "Профіль",
  orders: "Покупки",
  payment: "Платежі",
  leaderboard: "Лідери",
  time: "Час",
  points: "Бали",
  courses: "Мої курси",
  currentLang: {
    en: "English",
    ru: "Русский",
    fr: "Français",
    uk: "Українська",
  },
  loading: "Завантаження...",
  noTheory: "До цього уроку немає теорії.",
  startTraining: "Почати тренування",
  missions: "Місіі",
  branches: "Розділи",
  easy: "Легко",
  expert: "Експерт",
  wordToLearn: "Вивчити пізніше",
  iKnowWord: "Я знаю це слово",
  videoVocabulary: "Відео лексика",
  passed: "пройдено",
  unsupportedExercise: "Цей тип вправ (%{type}) ще не підтримується",
  changeCourse: "Змінити курс",
  currentCourse: "Поточний курс",
  allSubjects: "Всі предмети",
  subject: "Предмет",
  group: "Група",
  start: "Розпочати",
  chooseSubject: "Виберіть предмет, який потрібно вивчити",
  hourValue: "%{value} г",
  completed: "Завершений",
  notCompleted: "Не завершений",
  paidBranch: "Платна тема. Для доступу потрібна оплата.",
  buyBranch: "Купити тему за %{price} %{currency}",
  paidSubject: "Платний предмет. Для доступу потрібна оплата.",
  buySubject2: "Купити предмет за %{price} %{currency}",
  decksItemNotOpen: "Щоб відкрити цей урок, вам потрібно виконати попередній урок",
  decksItemNotPurchasedBranch: "Тему цього уроку ще не куплено",
  decksItemNotOpenLevel: "Для відкриття цього уроку, необхідно пройти попередній рівень",
  decksItemNotOpenBranch: "Щоб відкрити цей урок, вам потрібно пройти попередню тему",
  deckAccessDenied: "Цей урок ще не доступний для вас",
  openOnDate: "відкриється %{date}", // todo
  levelAccessIsDenied: "Матеріали цього рівня не доступні для вас",
  youAreDeactivated: "Ваш акаунт було деактивовано, будь ласка, зв'яжіться з вашою школою",
  youAreDeactivatedShort: "Ваш акаунт деактивовано",
  youAreHaveNotPaidOffers: "У вас є неоплачені пропозиції",
  yourCertificates: "Ваші сертифікати",
  emptyDeck: "Урок порожній",
  nextDeck: "Наступний урок",
  next: "Далі",
  proceed: "Продовжити",
  skip: "Пропустити",
  more: "Більше",
  exit: "Вихід",
  details: "Детальніше",
  profileEdit: "Редагувати профіль",
  userInfo: "Інформація про користувача",
  of: "з",
  back: "Назад",
  levelPackExist: "У вас вже є цей набір рівнів",
  awaitingPayment: "Чекає на оплату",
  bought: "Куплений",
  boughtUntilDate: "Куплений до %{endDate}",
  availableUntilDate: "Доступний до %{endDate}",
  showMore: "показати більше",
  hide: "згорнути",
  communication: "Зв'язок зі школою",
  askQuestion: "Поставити питання",
  noDescription: "Опис відсутній",
  onePerMonth: "Раз на місяць",
  semiannually: "Раз на пів року",
  onceAYear: "Раз на рік",
  stopSubscribe: "Зупинити підписку",
  enable: "Увімкнути підписку",
  expired: "Вийшли строки",
  buySubject: "Купити предмет",
  buyOffer: "Купити пропозицію",
  subscribeOffer: "Підписатись",
  firstPayment: "Перший платіж",
  accessDaysWarning: "Залишився %{smart_count} день |||| Залишилось %{smart_count} днів",
  accessDemoDaysWarning: "Демо: Залишився %{smart_count} день |||| Демо: Залишилось %{smart_count} днів",
  closeButton: "Закрити",
  Exercises: {
    tabTitle: "Вправи",
    skip: "Пропустити",
    check: "Перевірити",
    typeAnswer: "Введіть відповідь",
    next: "Далі",
    rightAnswerWas: "Правильна відповідь була:",
    congratulation: "Поздоровляємо!",
    congratText: "Ви вивчили всі вправи у цьому уроці!",
    wrongAnswer: "Ваша відповідь помилкова",
    answerSaved: "Відповідь збережена",
    commentToAnswer: "Коментар до обранї відповіді:", // todo
    appendix: {
      pdf: {
        tooltipButton: "Додаткові матеріали",
      },
    },
    type12: {
      title: "Ознайомтесь з додатковою інформацією", // todo
    },
    type11: {
      title: {
        select: "Виберіть правильну відповідь",
        keyboard: "Напишіть правильну відповідь",
      },
    },
    type9: {
      title: {
        select: "Заповніть пропуски, вибравши потрібне слово",
        keyboard: "Заповніть пропуски",
      },
    },
    type8: {
      title: "Знайдіть пари",
    },
    type7: {
      title: {
        text: "Напишіть вашу відповідь",
        audio: "Запишіть аудіо відповідь ",
      },
      recordingIsNotSupported: "Запис звуку не підтримується вашим браузером.",
      audioRecordingError: "Помилка запису звуку: ",
      inputLabel: "Ваша відповідь",
      addPhoto: "Додати фото/відео (до 5 шт.)",
      cameraInitialization: "Ініціалізація ...",
      change: "Змінити",
      codeToApprove: "Пароль",
      homework: {
        teacherData: {
          audio: "Аудіо коментар вчителя",
          audioComment: "Голосовик коментар вчителя",
        },
      },
      addFiles: "Додати файли",
    },
    type6: {
      title: "Намалюйте елемент",
      checkButtonText: "Зрозуміло!",
    },
    type3: {
      title: "Розташуйте тут елементи у правильному порядку",
      hint: "Перетягніть сюди елементи, розташовані нижче",
    },
    type5: {
      title: "Виберіть елементи",
      hint: "Виберіть потрібні елементи у нижній частині екрана (щоб вибрати - просто натисніть на них)",
    },
    type2: {
      title: "Виберіть пропущене слово",
    },
  },
  Events: {
    headNotice: "Час події відображається відповідно до вашого часового поясу",
    title: "Події цього тижня",
    startTime: "Час початку",
    group: "Група",
    persons: "%{smart_count} учня |||| %{smart_count} учнів",
    leader: "Ведучий",
    tag: "Категорія",
    joinedCheckbox: "З реєстрацією",
    joined: "Обрано:",
    interested: "Реєстрація",
    enter: "Увійти",
    limitPopupInfo: "Ви досягли ліміту реєстрацій на події",
    eventHasStarted: "Подія вже почалася!",
    registrationNotice: "Ви успішно зареєструвалися! Кнопка входу буде активна у %{date_and_time}. Після цього часу ви не зможете скасувати реєстрацію на подію",
    registrationNoticeNoUrl: "Ви успішно зареєструвалися! Після %{date_and_time} ви не зможете скасувати реєстрацію на подію",
    cancelRegistration: "Ви дійсно хочете скасувати реєстрацію?",
    calendarLink: "Додати подію у Google календар",
  },
  statistic: {
    title: "Статистика",
    points: "Бали",
    phrases: "Фрази",
    missions: "Місіі",
    words: "Слова",
    time: "Час",
  },
  status: {
    wait_review: "Чекає перевірки",
    on_review: "В процесі",
    approved: "Прийнято",
    not_approved: "Відхилено",
    not_approved_allow_update: "Відхилено (дозволено виправити)",
  },
  homeworks: {
    tabName: "Завдання",
    todo: "До виконання",
    done: "Виконані",
    topic: "Тема",
    lesson: "Урок",
    onlyInMobileApp: "Доступно лише в мобільному додатку",
    todoHomework: "ЗАВДАННЯ НА ПЕРЕВІРЦІ",
    postToDoButton: "Відправити",
    types: {
      homework_todo: "ToDo",
      exercise: "Exercise",
    },
  },
  exam: {
    popupTitle: "Урок з іспитом",
    popupDescr1: "Це урок із іспитом. Спочатку вивчіть всі додаткові матеріали, і лише після цього переходьте до виконання вправ.",
    popupDescr2: `Як тільки ви перейдете у вкладку "Вправи", почнеться відлік часу.`,
    popupDescr3: 'Вкладки "Теорiя" та "Відео" НЕ запускають відлік часу іспиту.',
    cancelButton: "Наступного разу",
    positiveButton: "Зрозуміло",
    questions: "%{smart_count} питання |||| %{smart_count} питань",
    oneAttempt: "Одна спроба на відповідь",
    timeLimit: "Обмеження часу: %{limit} хвилин",
    noPause: "Іспит не може бути призупинено",
    progress: "Питання №%{current} з %{total}",
    closeButton: "Закрити",
    nextButton: "Продовжити",
    finishedPopupTitle: "Результати іспиту",
    examPassed: "Іспит пройдено",
    examNotPassed: "Іспит не пройдено",
    waitHomeworks: "%{smart_count} питання на перевірці |||| %{smart_count} питань на перевірці",
  },
  subjects: {
    unavailable: "На даний момент предмет недоступний",
  },
  orderStatus: {
    approved: "Успішно",
    canceled: "Скасовано",
    pending: "Очікує",
  },
  notificationsTypes: {
    event: "Подія",
    invoice: "invoice",
    news: "Новина",
    homework: "Завдання",
    homework_todo: "Завдання Todo",
    type: {
      exercise: "Завдання",
      homework_todo: "Завдання To do",
    },
  },
  notificationsAlert: {
    subscribe: "Увімкнути сповіщення",
    unsubscribe: "Вимкнути сповіщення",
    instructions: "Натисніть на замочок 🔒 зліва від адресного рядка і змініть дозвіл повідомлень, як показано на зображенні, або змініть загальні налаштування браузера.",
    permissionDefault: {
      title: "Підпишіться на сповіщення 🔔",
      body: "Сповіщення про зміну статусу ДЗ, початок події, новини, нове завдання до виконання. Ніякої реклами, тільки повідомлення про ваше навчання!",
    },
    permissionDenied: {
      title: "Зараз сповіщення вимкнені ❌",
      body: "Раніше ви відмовилися від повідомлень. Без них ви не можете вчасно дізнаватися про зміну статусу ДЗ, початок події. Щоб увімкнути сповіщення дотримуйтесь інструкцій нижче:",
    },
    permissionGranted: {
      title: "Зараз сповіщення увімкнено ✔",
      body: "Ви точно хочете відмовитися від сповіщень? Без них ви не можете вчасно дізнаватися про зміну статусу ДЗ, початок події. Щоб вимкнути сповіщення дотримуйтесь інструкцій нижче:",
    },
  },
  feedbackDialog: {
    sendFeedback: "Надіслати відгук", // todo
    placeholder: "Розкажіть про ваші враження", // todo
    defaultInstruction: "Використовуйте слайдер щоб оцінити", // todo
    eventInstruction: "Оцініть як пройшла подія", // todo
    submitError: "Виберіть будьласка оцінку", // todo
    submitSuccess: "Дякуємо! Ваш відгук успішно відправлено", // todo
    wasNotPresent: "Я не відвідував подію", // todo
    dateOfEvent: "Дата події:", // todo
  },
  congratulation: {
    mission: "Вітаємо, місію %{name} завершено!",
  },
};
