import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslate } from "react-polyglot";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { useFormState, useForm } from "react-final-form";
import { makeStyles, withStyles, Tooltip, Button } from "@material-ui/core";
import { useCourseProvider } from "../../Providers/Data/CourseProvider.jsx";
import PrimaryRoundedButton from "../PrimaryRoundedButton.jsx";

const NotPresentButton = withStyles(() => ({
  root: {
    borderBottom: "2px solid",
    alignSelf: "center",
    padding: 0,
    marginTop: 15,
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(Button);

const useDialogStyles = makeStyles({
  root: {
    flexDirection: "column",
    paddingBottom: 20,
    marginTop: 5,
    alignItems: "center",
    "& .MuiDialogActions-spacing > :not(:first-child)": {
      marginLeft: 0,
    },
    "& .MuiButton-root": {
      textTransform: "none",
    },
  },
});

/**
 * @param {Number} id
 * @param {String} resource
 * @param {Function} onClose
 */
const FeedbackDialogActions = ({ id, resource, onClose }) => {
  const dialogClasses = useDialogStyles();
  const { postUserReviews } = useCourseProvider();
  const t = useTranslate();
  const formState = useFormState();
  const form = useForm();
  const valueRating = _.get(formState.values, "rating");

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setDisabled(!formState.dirtyFields.rating && valueRating === 0);
  }, [formState.dirtyFields, valueRating]);

  /** */
  const submitBlank = async () => {
    const eventId = resource === "event" ? id : null;
    const deckId = resource === "deck" ? id : null;
    const text = _.get(formState.values, "text") || t("feedbackDialog.wasNotPresent");
    const postData = { eventId, deckId, reviewOf: resource, text, rating: 0 };

    await postUserReviews(postData);
    form.reset();
    onClose();
  };

  return (
    <MuiDialogActions classes={dialogClasses}>
      <Tooltip title={disabled ? t("feedbackDialog.submitError") : ""} placement="right-start">
        <span>
          <PrimaryRoundedButton type="submit" disabled={disabled}>
            {t("feedbackDialog.sendFeedback")}
          </PrimaryRoundedButton>
        </span>
      </Tooltip>
      <NotPresentButton type="button" size="large" variant="text" onClick={submitBlank}>
        {t("feedbackDialog.wasNotPresent")}
      </NotPresentButton>
    </MuiDialogActions>
  );
};
FeedbackDialogActions.propTypes = {
  id: PropTypes.number.isRequired,
  resource: PropTypes.oneOf(["event", "deck"]).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FeedbackDialogActions;
