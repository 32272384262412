import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslate } from "react-polyglot";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles, Typography } from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";
import LinkIcon from "@material-ui/icons/Link";
import PhoneIcon from "@material-ui/icons/Phone";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import FlexboxVertical from "../../SharedComponents/FlexboxVertical.jsx";
import { useBaseData } from "../../Providers/Data/BaseDataProvider.jsx";
import ic_empty_avatar from "../../assets/ic_empty_avatar.svg";
import useEffectAsync from "../../hooks/useEffectAsync.js";

const useStyles = makeStyles((theme) => ({
  info: {
    marginBottom: 18,

    "& svg": {
      color: theme.palette.primary.dark,
      marginRight: 12,
      verticalAlign: "bottom",
    },
  },
  name: {
    fontWeight: 600,
    marginBottom: 18,
    textAlign: "center",
  },
  imgBox: {
    position: "relative",
    textAlign: "center",
  },
  userAvatar: {
    width: 100,
    height: 100,
    objectFit: "cover",
    borderRadius: "50%",
  },
}));

/**
 * @param {boolean} open
 * @param {function} onClose
 * @param {number|string} userId
 * @param {Object} rest
 * @return {null|*}
 * @constructor
 */
const UserInfoDialog = ({ open, onClose, userId, ...rest }) => {
  const classes = useStyles();
  const t = useTranslate();
  const { leaderInfo, requestLeaderInfo } = useBaseData();
  const [loading, setLoading] = useState(false);

  useEffectAsync(async () => {
    if (userId) {
      setLoading(true);
      await requestLeaderInfo(userId);
      setLoading(false);
    }
  }, [userId]);

  if (!leaderInfo) return null;

  let userAvatar = ic_empty_avatar;
  if (!loading && leaderInfo.photo?.url && leaderInfo.fieldsToBePublic?.photo) {
    userAvatar = leaderInfo.photo.url;
  }

  return (
    <Dialog {...rest} open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle align="center">{t("userInfo")}</DialogTitle>
      <DialogContent>
        <FlexboxVertical width="100%">
          <div className={classes.imgBox}>
            <img className={classes.userAvatar} src={userAvatar} alt="User avatar" />
          </div>
          <Typography className={classes.name} variant="body1" color="textPrimary" component="p">
            {loading ? "..." : leaderInfo.name}
          </Typography>
        </FlexboxVertical>
        {leaderInfo.fieldsToBePublic?.email && leaderInfo.email && (
          <Typography className={classes.info} variant="body1" color="textPrimary" component="p">
            <MailIcon /> {loading ? "..." : leaderInfo.email}
          </Typography>
        )}
        {leaderInfo.fieldsToBePublic?.personalLink && leaderInfo.personalLink && (
          <Typography className={classes.info} variant="body1" color="textPrimary" component="p">
            <LinkIcon />
            {loading ? "..." : leaderInfo.personalLink}
          </Typography>
        )}
        {leaderInfo.fieldsToBePublic?.phone && leaderInfo.phone && (
          <Typography className={classes.info} variant="body1" color="textPrimary" component="p">
            <PhoneIcon /> {loading ? "..." : leaderInfo.phone}
          </Typography>
        )}
        {leaderInfo.fieldsToBePublic?.aboutMe && leaderInfo.aboutMe && (
          <Typography className={classes.info} variant="body1" color="textPrimary" component="p">
            <AssignmentIndIcon /> {loading ? "..." : leaderInfo.aboutMe}
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

UserInfoDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default UserInfoDialog;
