import React from "react";
import { withStyles } from "@material-ui/core";
import orange from "@material-ui/core/colors/orange.js";
import Button from "@material-ui/core/Button";

export default withStyles({
  root: {
    backgroundColor: orange[600],
    textTransform: "none",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: orange[800],
    },
    "&:disabled": {
      backgroundColor: orange[800],
      opacity: "0.6",
    },
  },
})((props) => <Button {...props} variant="contained" />);
