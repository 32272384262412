import React, { createContext, useContext, useRef, useState } from "react";
import { get as get_, post as post_, patch as patch_, put as put_ } from "../../Utils/NetworkUtils.js";
import { useLocale } from "../i18n/I18nProvider.jsx";
import { useDialogs } from "./DialogsProvider.jsx";

/**
 * @typedef {Object} HttpProviderContext
 * @property {?React.MutableRefObject<String>} authTokenRef
 * @property {?React.MutableRefObject<Function>} signOutRef
 * @property {Boolean} isLoading
 * @property {FnHttpGet} get
 * @property {FnHttpPost} post
 * @property {FnHttpPost} patch
 * @property {FnHttpPost} put
 */

/** @type {React.Context<HttpProviderContext>} */
const httpContext = createContext({
  authTokenRef: null,
  signOutRef: null,
  isLoading: false,
  /** @type {FnHttpGet} */ get: () => {},
  /** @type {FnHttpPost} */ post: () => {},
  /** @type {FnHttpPost} */ patch: () => {},
  /** @type {FnHttpPost} */ put: () => {},
});

/**
 * @returns {HttpProviderContext}
 */
export const useHttp = () => useContext(httpContext);

/** @param {React.ReactNode} children */
const HttpProvider = ({ children }) => {
  const { locale } = useLocale();
  const snackbar = useDialogs();
  const signOutRef = useRef(null);
  const authTokenRef = useRef(null);
  const currentRequestsCount = useRef(0);
  const [isLoading, setLoading] = useState(false);

  /** @param {{error: ErrorData}} resp */
  const onUnauthorized = (resp) => {
    if (resp.error.code === 401) {
      signOutRef.current && signOutRef.current();
    }
  };

  /**
   * @param {Function} runRequest
   * @param {Function} onError
   * @param {boolean} silent if true then progress circle doesn't show
   */
  const processRequest = async (runRequest, onError, silent = false) => {
    try {
      if (!silent) currentRequestsCount.current++;
      setLoading(currentRequestsCount.current > 0);
      const response = await runRequest();
      if (response.data) {
        return response.data;
      } else if (response.error) {
        switch (response.error.code) {
          case 409:
            snackbar.warn(response.error.text);
            break;
          default:
            snackbar.error(response.error.text);
        }
        onUnauthorized(response);
        onError && onError(response.error.text);
        return null;
      }
    } catch (e) {
      snackbar.error(e.toString());
      onError && onError(e.toString());
      return null;
    } finally {
      if (!silent) currentRequestsCount.current--;
      setLoading(currentRequestsCount.current > 0);
    }
  };

  /** @type {FnHttpGet} */
  const get = async (path, onError, headers = {}, silent = false) => {
    return await processRequest(
      async () => {
        return await get_(path, locale, { authorization: authTokenRef.current, ...headers });
      },
      onError,
      silent,
    );
  };

  /** @type {FnHttpPost} */
  const post = async (path, postData, onError, headers = {}, silent = false) => {
    return await processRequest(
      async () => {
        return await post_(path, locale, postData, { authorization: authTokenRef.current, ...headers });
      },
      onError,
      silent,
    );
  };

  /** @type {FnHttpPost} */
  const patch = async (path, postData, onError, headers = {}) => {
    return await processRequest(async () => {
      return await patch_(path, locale, postData, { authorization: authTokenRef.current, ...headers });
    }, onError);
  };

  /** @type {FnHttpPost} */
  const put = async (path, postData, onError, headers = {}) => {
    return await processRequest(async () => {
      return await put_(path, locale, postData, { authorization: authTokenRef.current, ...headers });
    }, onError);
  };

  const value = {
    authTokenRef,
    signOutRef,
    isLoading,
    get,
    post,
    patch,
    put,
  };

  return <httpContext.Provider value={value}>{children}</httpContext.Provider>;
};

export default HttpProvider;
