import PropTypes from "prop-types";
import React from "react";
import Chip from "@material-ui/core/Chip";
import "../Utils/extensions.js";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(
  () => ({
    root: {
      direction: "ltr",
    },
  }),
  { name: "ChipArabic" },
);

/** @param {Number} arabicTransform
 * @param {Object} rest
 * @returns {*}
 * @constructor
 */
const ChipArabic = ({ arabicTransform, ...rest }) => {
  const classes = useStyles();
  const isArabic = rest.label && rest.label.isArabic();

  const styles = isArabic
    ? {
        fontSize: 26,
        transform: `translateY(${arabicTransform}px)`,
      }
    : undefined;

  return <Chip {...rest} className={clsx(classes.root, rest.className)} style={styles} />;
};

ChipArabic.propTypes = {
  arabicTransform: PropTypes.number,
  ...Chip.propTypes,
};

ChipArabic.defaultProps = {
  arabicTransform: 0,
};

export default ChipArabic;
