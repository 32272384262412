import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { makeStyles, Box, Grid, Card, CardActionArea, CardMedia, CardContent, Typography } from "@material-ui/core";
import { useTranslate } from "react-polyglot";
import { useAuth } from "../../Providers/Auth/AuthProvider.jsx";
import { useBaseData } from "../../Providers/Data/BaseDataProvider.jsx";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "35px 20px",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  title: {
    textTransform: "uppercase",
    color: "#00213A",
    fontSize: 24,
    fontWeight: 500,
    marginBottom: 25,
  },
  offerCards: {
    marginTop: 20,
  },
}));

/**
 *
 * @param {Order} order
 * @param {function} t
 * @returns {JSX.Element}
 * @constructor
 */
const OrderCard = ({ order, t }) => {
  const statusColors = {
    approved: "77 191 30",
    canceled: "243 41 41",
    pending: "227 120 25",
  };

  return (
    <Grid item xs={6}>
      <Card
        style={{
          boxShadow: `0px 3px 1px -1px rgb(${statusColors[order.status]}), 0px 1px 1px 0px rgb(${
            statusColors[order.status]
          }), 0px 1px 3px 0px rgb(${statusColors[order.status]})`,
        }}
      >
        <CardActionArea>
          <CardMedia
            component="img"
            alt={order.offers.image.name}
            height="240"
            image={order.offers.image.url}
            title={order.offers.image.name}
          />
          <CardContent>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="h5">
                    {order.offers.name}
                  </Typography>
                  <Typography variant="body2" color="textPrimary" component="p">
                    {order.offers.description}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">
                  {order.price} {order.currency}
                </Typography>
                <Typography variant="subtitle2" style={{ color: `rgb(${statusColors[order.status]})` }}>
                  {t(`orderStatus.${order.status}`)}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

OrderCard.propTypes = {
  order: PropTypes.object,
  t: PropTypes.func,
};

/**
 * @returns {*}
 * @constructor
 */
const Orders = () => {
  const classes = useStyles();
  const t = useTranslate();
  const { user } = useAuth();
  const { requestUserOrders, userOrders } = useBaseData();

  useEffect(() => {
    if (user) {
      requestUserOrders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Box className={classes.wrapper}>
      <Typography component="h1" variant="h5" align="center">
        {t("orders")}
      </Typography>
      <Box className={classes.offerCards}>
        <Grid container spacing={2} columns={{ md: 12 }}>
          {userOrders && userOrders.map((order) => <OrderCard key={order.id} order={order} t={t} />)}
        </Grid>
      </Box>
    </Box>
  );
};

export default Orders;
