import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import App from "./App.js";
import MyThemeProvider from "./MyThemeProvider.jsx";

ReactDOM.render(
  <MyThemeProvider>
    <CssBaseline />
    <App />
  </MyThemeProvider>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
