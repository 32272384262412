import PropTypes from "prop-types";
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useTranslate } from "react-polyglot";

const useStyles = makeStyles(() => ({
  actionsRoot: {
    justifyContent: "flex-end",
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  titledDialogContent: {
    marginBottom: 15,
  },
}));

/**
 * @param {Boolean} open
 * @param {Function} handleYes
 * @param {Function} handleCancel
 * @param {String} title
 * @param {String} message
 * @param {React.ReactNode|HTMLElement} content
 */
const ConfirmDialog = ({ open, handleYes, handleCancel, title, message, content }) => {
  const classes = useStyles();
  const t = useTranslate();

  return (
    <Dialog maxWidth="xs" open={open} onClose={handleCancel}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent className={clsx({ [classes.titledDialogContent]: !!title }, classes.dialogContent)}>
        {message && <DialogContentText>{message}</DialogContentText>}
        {content}
      </DialogContent>
      <DialogActions className={classes.actionsRoot}>
        <Button onClick={handleCancel}>{t("cancel")}</Button>
        <Button onClick={handleYes} color="secondary" autoFocus variant="contained">
          {t("yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  content: PropTypes.node,
  handleCancel: PropTypes.func.isRequired,
  handleYes: PropTypes.func.isRequired,
  message: PropTypes.string,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

export default ConfirmDialog;
