import PropTypes from "prop-types";
import React from "react";
import { Redirect, Route } from "react-router-dom";

/**
 * @param {React.ReactNode|HTMLElement} children
 * @param {Boolean} condition
 * @param {String} forbiddenRedirect
 * @param {Object} rest
 */
const ProtectedRoute = ({ children, condition, forbiddenRedirect, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        condition ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: forbiddenRedirect,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

ProtectedRoute.propTypes = {
  condition: PropTypes.bool.isRequired,
  forbiddenRedirect: PropTypes.string.isRequired,
};

export default ProtectedRoute;
