import React, { useRef, useState } from "react";
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useTranslate } from "react-polyglot";
import first from "../../assets/ic_top-1.svg";
import second from "../../assets/ic_top-2.svg";
import third from "../../assets/ic_top-3.svg";
import { useBaseData } from "../../Providers/Data/BaseDataProvider.jsx";
import SearchField from "../../SharedComponents/SearchField.jsx";
import UserInfoDialog from "./UserInfoDialog.jsx";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "35px 20px",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  title: {
    textTransform: "uppercase",
    color: "#00213A",
    fontSize: 24,
    fontWeight: 500,
    marginBottom: 25,
  },
  searchField: {
    width: "100%",
    marginBottom: 15,

    [theme.breakpoints.up("sm")]: {
      width: "49%",
    },
  },
  tableHead: {
    backgroundColor: theme.palette.primary.dark,
    "&  .MuiTableCell-head": {
      color: theme.palette.background.default,
      fontWeight: 600,
    },
  },
  leadCell: {
    fontWeight: 900,
    fontSize: 24,
    color: theme.palette.primary.dark,
    lineHeight: "40px",
    width: 150,
  },
  leadCellText: {
    marginLeft: 7,
  },
  pointCell: {
    fontWeight: 700,
    fontSize: 24,
    color: theme.palette.primary.dark,
    width: 150,
  },
  tableBody: {
    "&  .MuiTableCell-root": {
      borderBottom: "none",
      paddingTop: 5,
      paddingBottom: 5,
    },
  },
  tableRow: {
    cursor: "pointer",
  },
}));

/**
 * @returns {*}
 * @constructor
 */
const Leaderboard = () => {
  const classes = useStyles();
  const t = useTranslate();
  const { setLeaderboardFilters, filteredLeaderboard, userInfo } = useBaseData();
  const [searchTextValue, setSearchText] = useState("");
  const [userId, setUserId] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const searchTimeoutHandle = useRef(0);

  /** @param {ReactEventTarget} target */
  const searchFieldHandler = ({ target }) => {
    const text = target.value;
    setSearchText(text);
    clearTimeout(searchTimeoutHandle.current);
    if (text !== "") {
      searchTimeoutHandle.current = setTimeout(() => {
        setLeaderboardFilters({ text });
      }, 600);
    } else {
      setLeaderboardFilters({ text });
    }
  };

  /** @param {Number} userId */
  const handleRowClick = (userId) => {
    if (!userInfo?.subject?.openUserInfoEnabled) {
      return;
    }
    setUserId(userId);
    setDialogOpen(true);
  };

  /** */
  const onDialogClose = () => {
    setDialogOpen(false);
  };

  if (!filteredLeaderboard) return null;

  return (
    <Box className={classes.wrapper}>
      <Typography className={classes.title}>{t("leaderboard")}</Typography>
      <Box className={classes.searchField}>
        <SearchField value={searchTextValue} onChange={searchFieldHandler} />
      </Box>
      <TableContainer>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell>№</TableCell>
              <TableCell>{t("name")}</TableCell>
              <TableCell>{t("points")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {filteredLeaderboard.map((listMember) => (
              <TableRow
                key={listMember.id}
                hover
                className={classes.tableRow}
                onClick={() => handleRowClick(listMember.id)}
              >
                <TableCell className={classes.leadCell}>
                  {listMember.leaderboardPlace === 1 && <img src={first} alt="first" />}
                  {listMember.leaderboardPlace === 2 && <img src={second} alt="second" />}
                  {listMember.leaderboardPlace === 3 && <img src={third} alt="third" />}
                  {listMember.leaderboardPlace > 3 && (
                    <span className={classes.leadCellText}>{listMember.leaderboardPlace}</span>
                  )}
                </TableCell>
                <TableCell>{listMember.name}</TableCell>
                <TableCell className={classes.pointCell}>{listMember.points}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <UserInfoDialog open={dialogOpen} onClose={onDialogClose} userId={userId} />
    </Box>
  );
};

export default Leaderboard;
