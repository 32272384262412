import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { makeStyles, MuiThemeProvider, TextField } from "@material-ui/core";
import { overrideTheme } from "../Utils/theming.js";
import { calcTextWidth } from "../Utils/misc.js";
import { ThemeContext } from "../MyThemeProvider.jsx";

const useStyles = makeStyles((theme) => ({
  error: {
    "& .Mui-error": {
      backgroundColor: theme.palette.error.main,
    },
  },
}));

/**
 * @param {Object} appTheme
 */
const makeChipInputTheme = (appTheme) =>
  overrideTheme(appTheme, {
    MuiFormControl: {
      root: {
        verticalAlign: "unset",
        minWidth: 60,
      },
    },
    MuiFilledInput: {
      root: {
        borderRadius: 15,
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
      },
      inputMarginDense: {
        padding: "0 10px",
        paddingTop: 5,
        paddingBottom: 5,
      },
      underline: {
        "&:before": {
          display: "none",
        },
        "&:after": {
          display: "none",
        },
      },
    },
  });

/**
 * @param {Function} onChange
 * @param {Object} rest
 */
const ChipInput = ({ onChange, ...rest }) => {
  const { theme } = useContext(ThemeContext);
  const classes = useStyles();
  const [width, setWidth] = useState(0);

  const chipInputTheme = makeChipInputTheme(theme);

  /** @param {ReactEventTarget} target */
  const changeHandler = ({ target }) => {
    const v = target.value;
    const body1 = chipInputTheme.typography.body1;
    setWidth(calcTextWidth(v, `${body1.fontWeight} ${body1.fontSize} ${body1.fontFamily}`) + 22);
    onChange && onChange(v);
  };

  return (
    <MuiThemeProvider theme={chipInputTheme}>
      <TextField
        {...rest}
        className={classes.error}
        style={{ width }}
        placeholder="_____"
        onChange={changeHandler}
        variant="filled"
        size="small"
      />
    </MuiThemeProvider>
  );
};

ChipInput.propTypes = {
  onChange: PropTypes.func,
};

export default ChipInput;
