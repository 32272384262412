import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from "@material-ui/core";
import { useTranslate } from "react-polyglot";
import clsx from "clsx";
import Event from "./Event.jsx";
import EventRow from "./EventRow.jsx";
import useCompanyTitle from "../../../hooks/useCompanyTitle.js";
import useExtractRouteParamInt from "../../../hooks/useExtractRouteParamInt.js";
import { useEvents } from "../../../Providers/Data/EventsProvider.jsx";
import StringSelectInput from "../../../SharedComponents/StringSelectInput.jsx";

const useStyles = makeStyles((theme) => ({
  headText: {
    color: theme.palette.secondary.dark,
    margin: "15px 20px 0",
  },
  eventsWrapper: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      height: "100%",
    },
    overflowY: "visible",
  },
  events: {
    [theme.breakpoints.up("md")]: {
      width: ({ eventId }) => (eventId ? "50%" : "100%"),
    },
  },
  eventDetails: {
    [theme.breakpoints.up("md")]: {
      width: ({ eventId }) => (eventId ? "50%" : "0%"),
    },
  },
  eventColumn: {
    padding: 0,
    marginTop: 20,
    marginBottom: 35,
    transitionProperty: "width",
    transitionDuration: theme.transitions.duration.shortest,
    [theme.breakpoints.up("md")]: {
      width: "50%",
      paddingLeft: 10,
      height: "fit-content",
      position: "sticky",
      top: 10,
    },
  },
  eventsHead: {
    boxShadow: "2px 0px 4px rgba(0, 0, 0, 0.1)",
    color: theme.palette.primary.dark,
    textAlign: "center",
    padding: 20,
    textTransform: "uppercase",
    fontSize: 18,
    fontWeight: 500,
  },
  eventsTableWrapper: {
    maxHeight: 350,
    [theme.breakpoints.up("md")]: {
      maxHeight: "fit-content",
    },
  },
  tableCol: {
    color: theme.palette.secondary.dark,
  },
  tableRow: {
    cursor: "pointer",
    transitionProperty: "background-color",
    transitionDuration: 300,
  },
  eventInterested: {
    backgroundColor: "#e5f6de",
  },
  eventStatusBlock: {
    maxWidth: 100,
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  icon: {
    width: 50,
    height: 50,
    objectFit: "contain",
  },
  eventStatus: {
    textTransform: "uppercase",
    marginTop: 6,
  },
  checkIcon: {
    position: "absolute",
    top: 0,
    right: 10,
    color: "#EB4893",
    backgroundColor: "#e5f6de",
    borderRadius: "50%",
  },
  headItem: {
    marginBottom: 15,
    marginTop: 15,
  },
  iconInfinity: {
    verticalAlign: "middle",
  },
  leaderSelect: {
    width: "33.333%",
    backgroundColor: ({ filtersLeader }) => (filtersLeader === "" ? undefined : theme.palette.primary.dark),
  },
  tagSelect: {
    width: "33.333%",
    backgroundColor: ({ filtersTag }) => (filtersTag === "" ? undefined : theme.palette.primary.dark),
  },
  joinedFilter: {
    width: "33.333%",
    minHeight: 42,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.light,
    color: "white",
    textTransform: "uppercase",
    textAlign: "center",
    alignSelf: "stretch",

    "& .MuiSvgIcon-root": {
      color: "white",
    },
  },
}));

/** */
const Events = () => {
  const t = useTranslate();
  const { sortedEvents, setFilters, filters, leadersList, tagsList } = useEvents();
  const eventId = useExtractRouteParamInt("/events/:eventId", "eventId");
  const [eventIdForStyles, setEventIdForStyles] = useState(0); // in some cases just updating eventId doesn't update the following styles
  const classes = useStyles({ filtersLeader: filters.leader, filtersTag: filters.tag, eventId: eventIdForStyles });
  const registeredUserEvents = sortedEvents[0] ? sortedEvents[0].registeredUserEvents : 0;
  const userEventsLimit = sortedEvents[0] ? sortedEvents[0].userEventsLimit : -1;

  useCompanyTitle("title.events");

  // update eventId for styles
  useEffect(() => {
    setEventIdForStyles(eventId);
  }, [eventId]);

  return (
    <>
      <Box className={classes.headText}>
        <Typography variant="subtitle1" align="center">
          {t("Events.headNotice")}
        </Typography>
      </Box>
      <Box className={classes.headItem} width="100%">
        <StringSelectInput
          className={classes.leaderSelect}
          label={t("Events.leader")}
          value={filters.leader}
          onChange={({ target }) => setFilters({ ...filters, leader: target.value })}
          values={leadersList}
        />
        <StringSelectInput
          className={classes.tagSelect}
          label={t("Events.tag")}
          value={filters.tag}
          onChange={({ target }) => setFilters({ ...filters, tag: target.value })}
          values={tagsList}
        />
        <Box className={classes.joinedFilter}>
          <FormControlLabel
            value={filters.joined}
            control={
              <Checkbox color="primary" onChange={({ target }) => setFilters({ ...filters, joined: target.checked })} />
            }
            label={t("Events.joinedCheckbox")}
            labelPlacement="start"
          />
        </Box>
      </Box>
      <Box className={classes.headText}>
        <Typography variant="subtitle1" align="right" noWrap>
          <b>{t("Events.joined")}&nbsp;&nbsp;</b>
          {registeredUserEvents}
          {userEventsLimit !== -1 && (
            <span>
              {" "}
              / <b>{userEventsLimit}</b>
            </span>
          )}
        </Typography>
      </Box>
      <Box className={classes.eventsWrapper}>
        <Box className={clsx(classes.eventColumn, classes.events)}>
          <Typography className={classes.eventsHead}>{t("Events.title")}</Typography>
          <TableContainer className={classes.eventsTableWrapper}>
            <Table>
              <TableBody>
                {sortedEvents.map((event) => (
                  <EventRow event={event} key={event.id} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box className={clsx(classes.eventColumn, classes.eventDetails)}>{eventId && <Event />}</Box>
      </Box>
    </>
  );
};

export default Events;
