import React, { createContext, useContext, useState } from "react";
import _ from "lodash";

/**
 * @typedef {Object} AnswerUserFilesContext
 * @property {File[]} pictureFiles
 * @property {Function} setPictureFiles
 * @property {File[]} anyFiles
 * @property {Function} setAnyFiles
 * @property {RemoteMedia[]} homeworkUserDataPhotos
 * @property {Function} setHomeworkUserDataPhotos
 * @property {RemoteMedia[]} homeworkUserDataFiles
 * @property {Function} setHomeworkUserDataFiles
 * @property {FnResetFilesState} resetImage
 * @property {FnNumberVoid} removeImageFilesButtonHandler
 * @property {FnNumberVoid} removeHomeworkImageButtonHandler
 * @property {FnResetFilesState} resetFile
 * @property {FnNumberVoid} removeFilesButtonHandler
 * @property {FnNumberVoid} removeHomeworkFileButtonHandler
 */

/**
 * @callback FnResetFilesState
 * @param {ExerciseType7Answer|ExerciseType7Legacy} answer
 * @returns {void}
 */

/**
 * @type {React.Context<AnswerUserFilesContext>}
 */
const answerUserFilesContext = createContext({
  pictureFiles: [],
  setPictureFiles: () => {},
  anyFiles: [],
  setAnyFiles: () => {},
  homeworkUserDataPhotos: [],
  setHomeworkUserDataPhotos: () => {},
  homeworkUserDataFiles: [],
  setHomeworkUserDataFiles: () => {},
  resetImage: () => {},
  removeImageFilesButtonHandler: () => {},
  removeHomeworkImageButtonHandler: () => {},
  resetFile: () => {},
  removeFilesButtonHandler: () => {},
  removeHomeworkFileButtonHandler: () => {},
});

/**
 * @returns {AnswerUserFilesContext}
 */
export const useAnswerUserFiles = () => useContext(answerUserFilesContext);

/**
 * @param {React.ReactNode} children
 * @returns {React.ReactNode}
 * @constructor
 */
const AnswerUserFilesProvider = ({ children }) => {
  const [pictureFiles, setPictureFiles] = useState([]);
  const [anyFiles, setAnyFiles] = useState([]);
  const [homeworkUserDataPhotos, setHomeworkUserDataPhotos] = useState([]);
  const [homeworkUserDataFiles, setHomeworkUserDataFiles] = useState([]);

  /**
   * @param {ExerciseType7Answer|ExerciseType7Legacy} answer
   * @returns {void}
   */
  const resetImage = (answer) => {
    /** @type {Homework} */
    const exerciseHomework = _.get(answer, "homework");
    /** @type {HomeworkUserData} */
    const homeworkUserData = _.get(exerciseHomework, "userData");
    setPictureFiles([]);
    setHomeworkUserDataPhotos((homeworkUserData && homeworkUserData.photos) || []);
  };

  /**
   * @param {ExerciseType7Answer|ExerciseType7Legacy} answer
   * @returns {void}
   */
  const resetFile = (answer) => {
    setAnyFiles([]);
    const _files = _.get(answer, "homework.userData.files");
    setHomeworkUserDataFiles((_files && [..._files]) || []);
  };

  /** @param {number} index */
  const removeImageFilesButtonHandler = (index) => () => {
    pictureFiles.splice(index, 1);
    setPictureFiles([...pictureFiles]);
  };

  /** @param {number} index */
  const removeHomeworkImageButtonHandler = (index) => () => {
    homeworkUserDataPhotos.splice(index, 1);
    setHomeworkUserDataPhotos([...homeworkUserDataPhotos]);
  };

  /** @param {number} index */
  const removeFilesButtonHandler = (index) => () => {
    anyFiles.splice(index, 1);
    setAnyFiles([...anyFiles]);
  };

  /** @param {number} index */
  const removeHomeworkFileButtonHandler = (index) => () => {
    homeworkUserDataFiles.splice(index, 1);
    setHomeworkUserDataFiles([...homeworkUserDataFiles]);
  };

  const value = {
    pictureFiles,
    setPictureFiles,
    homeworkUserDataPhotos,
    setHomeworkUserDataPhotos,
    anyFiles,
    setAnyFiles,
    homeworkUserDataFiles,
    setHomeworkUserDataFiles,
    resetImage,
    removeImageFilesButtonHandler,
    removeHomeworkImageButtonHandler,
    resetFile,
    removeFilesButtonHandler,
    removeHomeworkFileButtonHandler,
  };

  return <answerUserFilesContext.Provider value={value}>{children}</answerUserFilesContext.Provider>;
};

export default AnswerUserFilesProvider;
