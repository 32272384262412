import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core";
import { indigo } from "@material-ui/core/colors";
import { useTranslate } from "react-polyglot";

const useStyles = makeStyles(() => ({
  examStatus: {
    color: ({ examStatusColor }) => examStatusColor,
    textTransform: "uppercase",
    fontWeight: 900,
    whiteSpace: "nowrap",
  },
}));

/**
 * @param {Exam} exam
 */
const ExamStatusText = ({ exam }) => {
  const t = useTranslate();
  const theme = useTheme();
  const [examStatusColor, setExamStatusColor] = useState("black");
  const classes = useStyles({ examStatusColor });
  const [examStatusText, setExamStatusText] = useState("");

  // prepare status data
  useEffect(() => {
    switch (exam.status) {
      case "finished_passed":
        setExamStatusText(t("exam.examPassed"));
        setExamStatusColor(theme.palette.primary.dark);
        break;
      case "finished_not_passed":
        setExamStatusText(t("exam.examNotPassed"));
        setExamStatusColor(theme.palette.error.dark);
        break;
      case "finished_wait_homework":
        setExamStatusText(t("exam.waitHomeworks", { smart_count: exam.waitHomeworks }));
        setExamStatusColor(indigo[900]);
        break;
    }
  }, [exam, t]);

  return (
    <Typography className={classes.examStatus} variant="h6">
      {examStatusText}
    </Typography>
  );
};

ExamStatusText.propTypes = {
  exam: PropTypes.object.isRequired,
};

export default ExamStatusText;
