import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "./AuthProvider.jsx";
import useCompanyPublicId from "../../hooks/useCompanyPublicId.js";

/**
 * @param {React.ReactNode|HTMLElement} children
 * @param {Object} rest
 */
const AuthProtectedRoute = ({ children, ...rest }) => {
  const authData = useAuth();
  const { domain } = useCompanyPublicId();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if ((authData.user && authData.user.token) || (domain && location.pathname.startsWith("/subjects"))) {
          return children;
        } else {
          return (
            <Redirect
              to={{
                pathname: domain ? "/subjects" : "/auth",
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  );
};

export default AuthProtectedRoute;
