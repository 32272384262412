import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useTranslate } from "react-polyglot";
import { useBaseData } from "../Providers/Data/BaseDataProvider.jsx";
import FlexboxVertical from "./FlexboxVertical.jsx";

const useStyles = makeStyles(() => ({
  dialogRoot: {
    paddingTop: 0,
    padding: 10,
    "&:first-child": {
      paddingTop: 0,
    },
  },
  iframe: {
    width: "100%",
    height: "calc(100vh - 200px)",
    marginBottom: 20,
    border: "none",
  },
}));

/** */
const PaymentDialog = ({ isDialogOpened, handleCloseDialog }) => {
  const classes = useStyles();
  const { preselectedOffer, requestPaymentHtml, payHtml, clearPayHtml } = useBaseData();
  const t = useTranslate();

  //request payment html
  useEffect(() => {
    if (preselectedOffer) {
      requestPaymentHtml(preselectedOffer, "offer").then();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preselectedOffer]);

  /** */
  const closeDialogPrehandler = () => {
    clearPayHtml();
    handleCloseDialog();
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={isDialogOpened && !!payHtml} onClose={closeDialogPrehandler}>
      <DialogContent className={classes.dialogRoot}>
        <FlexboxVertical data-component="PayWebview" alignItems="center">
          <iframe className={classes.iframe} src={payHtml} />
          <Button variant="contained" color="primary" onClick={closeDialogPrehandler}>
            {t("closeButton")}
          </Button>
        </FlexboxVertical>
      </DialogContent>
    </Dialog>
  );
};

PaymentDialog.propTypes = {
  isDialogOpened: PropTypes.bool,
  handleCloseDialog: PropTypes.func,
};

export default PaymentDialog;
