import React, { useState } from "react";
import { TextField } from "mui-rff";
import Button from "@material-ui/core/Button";
import { useTranslate } from "react-polyglot";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core";
import ReactFinalForm from "../../../SharedComponents/ReactFinalForm.jsx";
import { useAuth } from "../../../Providers/Auth/AuthProvider.jsx";
import useTitle from "../../../hooks/useTitle.js";
import FlexboxVertical from "../../../SharedComponents/FlexboxVertical.jsx";
import { useDialogs } from "../../../Providers/Common/DialogsProvider.jsx";
import { SNACKBAR_POSITION } from "../../../Providers/Common/MySnackbar.jsx";
import ConfirmDialog from "../../../SharedComponents/ConfirmDialog.jsx";
import { useBaseData } from "../../../Providers/Data/BaseDataProvider.jsx";
import useCompanyPublicId from "../../../hooks/useCompanyPublicId.js";
import useFavicon from "../../../hooks/useFavicon.js";

const useStyles = makeStyles(() => ({
  email: {
    margin: "10px 20px 0",
  },
}));

const ForgotButton = withStyles(() => ({
  root: {
    textTransform: "none",
    alignSelf: "center",
    marginBottom: 15,
  },
}))(Button);

/** @param {String} className */
const Password = ({ className }) => {
  const { companyName, domain } = useCompanyPublicId();
  const classes = useStyles();
  const t = useTranslate();
  const { success } = useDialogs();
  const { login, checkEmailResponse, resetPassword, company2 } = useAuth();
  const { preselectedSubject, preselectedOffer } = useBaseData();
  const [isDialogOpened, setDialogOpened] = useState(false);

  useTitle("title.login", { school: (company2 && company2.name) || companyName || domain });

  useFavicon();

  /** @param {String} password */
  const onSubmit = ({ password }) => {
    login(password, preselectedSubject, preselectedOffer);
  };

  /** */
  const restorePasswordHandler = () => {
    setDialogOpened(true);
  };

  /** */
  const userConfirmedResetting = async () => {
    setDialogOpened(false);
    if (await resetPassword()) {
      success(t("resetPassword"), SNACKBAR_POSITION.TOP_CENTER);
    }
  };

  return (
    <>
      <Typography variant="subtitle1" align="center" color="primary">
        {t("title.setPassword")}
      </Typography>
      <ReactFinalForm onSubmit={onSubmit} formClassName={className}>
        <FlexboxVertical>
          <Typography className={classes.email} variant="body2">
            {checkEmailResponse.userEmail}
          </Typography>
          <TextField
            style={{ marginTop: 10 }}
            name="password"
            type="password"
            label={t("password")}
            variant="outlined"
            fullWidth={false}
            required
          />
          <Button size="large" type="submit" variant="contained" color="primary">
            {t("enter")}
          </Button>
          <ForgotButton onClick={restorePasswordHandler}>{t("forgotPassword")}</ForgotButton>
        </FlexboxVertical>
      </ReactFinalForm>
      <ConfirmDialog
        open={isDialogOpened}
        handleYes={userConfirmedResetting}
        handleCancel={() => setDialogOpened(false)}
        message={t("resetConfirm")}
      />
    </>
  );
};

Password.propTypes = {
  className: PropTypes.string,
};

export default Password;
