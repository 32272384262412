import React, { forwardRef } from "react";
import { Box } from "@material-ui/core";

/**
 * @param {Object} props
 * @returns {*}
 * @constructor
 */
const FlexboxHorizontal = forwardRef((props, ref) => <Box {...props} ref={ref} display="flex" flexDirection="row" />);

FlexboxHorizontal.displayName = "FlexboxHorizontal";

export default FlexboxHorizontal;
