import PropTypes from "prop-types";
import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { makeStyles, withStyles } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import { useTranslate } from "react-polyglot";
import { useBaseData } from "../Providers/Data/BaseDataProvider.jsx";
import FlexboxHorizontal from "./FlexboxHorizontal.jsx";

const useStyles = makeStyles({
  langIcon: {
    margin: "0 10px 0 0",
  },
  expandIcon: {
    margin: "0 0 0 10px",
  },
});

const WhiteTextButton = withStyles({
  root: {
    color: "white",
    textTransform: "none",
  },
})(Button);

const StyledMenu = withStyles({
  paper: {
    minWidth: 150,
  },
})(Menu);

/**
 * @param {Object} buttonProps
 * @param {Object} menuProps
 * @return {*}
 * @constructor
 */
const CourseSelect = ({ buttonProps, menuProps }) => {
  const { availableSubjects, subject, chooseSubject } = useBaseData();
  const history = useHistory();
  const t = useTranslate();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  /** @param {Event} event */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /** */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /** @param {CompanySubject} subj */
  const onSubjectSelected = (subj) => {
    chooseSubject(subj.id).then();
    handleClose();
    if (window.location.pathname.startsWith("/learn/")) {
      history.replace("/");
    }
  };

  const addedSubjects =
    (availableSubjects && availableSubjects.filter((subj) => subj.added === true || subj.added === undefined)) || [];

  const currentCourse = (availableSubjects && availableSubjects.find((subj) => subj.id === subject.subjectId)) || {
    name: "",
  };

  return (
    <FlexboxHorizontal data-tag="center" alignItems="center" color="white">
      <WhiteTextButton {...buttonProps} size="small" onClick={handleClick}>
        <b>
          <u>{t("courses")}</u>&nbsp;▸&nbsp;
        </b>
        {currentCourse.name}
        <ExpandMoreRoundedIcon className={classes.expandIcon} />
      </WhiteTextButton>
      <StyledMenu
        id="lang-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        {...menuProps}
      >
        {addedSubjects.map((subject) => (
          <MenuItem key={subject.id} onClick={() => onSubjectSelected(subject)}>
            {subject.name}
          </MenuItem>
        ))}
      </StyledMenu>
    </FlexboxHorizontal>
  );
};

CourseSelect.propTypes = {
  buttonProps: PropTypes.object,
  menuProps: PropTypes.object,
};

export default CourseSelect;
