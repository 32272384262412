import React from "react";
import * as PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import { Box, makeStyles } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import { darken, fade } from "@material-ui/core/styles";
import LinearProgressBar from "../../../SharedComponents/LinearProgressBar.jsx";
import LockIcon from "../../../SharedComponents/LockIcon.jsx";
import { useBaseData } from "../../../Providers/Data/BaseDataProvider.jsx";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    margin: "14px 7px 7px",
    maxWidth: 200,
    height: 270,
    width: "100%",
    borderRadius: 10,
    textAlign: "center",
    position: "relative",
    backgroundImage: ({ hasImage }) =>
      hasImage ? `linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.00) 29.14%), url(${hasImage})` : null,
    backgroundSize: "cover",
    backgroundPosition: "bottom, center",
    transform: ({ isActive }) => (isActive ? "translateY(-14px)" : null),
    transition: "all 200ms",
    [theme.breakpoints.up("lg")]: {
      maxWidth: 180,
    },

    "&.MuiPaper-elevation0": {
      boxShadow: ({ color }) => `0px 3px 8px 0px ${fade(darken(color, 0.5), 0.6)}`,
    },
  },
  cardHeader: {
    backgroundColor: ({ hasImage, color }) => (hasImage ? "rgba(255, 255, 255, 0.70)" : color),
    padding: 10,
    minHeight: 37,
  },
  cardTitle: {
    color: ({ hasImage }) => (hasImage ? "black" : "white"),
    fontWeight: 500,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 165,
  },
  cardActionArea: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "flex-start",
  },
  cardContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  branchName: {
    maxHeight: 150,
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 5,
    "-webkit-box-orient": "vertical",
  },
  percent: {
    margin: "0 0 10px 0",
    color: ({ hasImage }) => (hasImage ? "white" : theme.palette.primary.main),
  },
  checkIcon: {
    position: "absolute",
    bottom: 5,
    right: 10,
  },
}));

/**
 * @param {Branch} branch
 * @param {Level} level
 * @param {Boolean} isActive
 * @param {function} onClick
 * @returns {*}
 * @constructor
 */
const BranchCard = ({ branch, isActive, onClick }) => {
  const illustrationImage = (branch.illustrationImageUrl || branch.illustrationImage?.url)?.replace(/\\/g, "/");
  const classes = useStyles({ color: branch.color, isActive, hasImage: illustrationImage });
  const { userInfo } = useBaseData();

  return (
    <Card className={classes.cardRoot} elevation={isActive ? 0 : 3}>
      <CardActionArea className={classes.cardActionArea} onClick={onClick}>
        <CardHeader
          className={classes.cardHeader}
          title={illustrationImage ? branch.name : ""}
          titleTypographyProps={{ variant: "body2", className: classes.cardTitle }}
        />
        <CardContent className={classes.cardContent}>
          <Box height="70%" display="flex" flexDirection="column" justifyContent="center">
            <Typography className={classes.branchName} variant="body1">
              {illustrationImage ? "" : branch.name}
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.percent} variant="subtitle2">
              {`${branch.percent.toFixed()}%`}
            </Typography>
            <LinearProgressBar variant="determinate" value={branch.percent} radius="0px" noborder={illustrationImage} />
          </Box>
          {branch.learned && <CheckRoundedIcon className={classes.checkIcon} color="primary" fontSize="large" />}
        </CardContent>
      </CardActionArea>
      {(!branch.open || !(userInfo.isActive || userInfo.isDemoMode)) && <LockIcon />}
    </Card>
  );
};

BranchCard.propTypes = {
  branch: PropTypes.object,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
};

export default BranchCard;
