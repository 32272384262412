import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import CardActionArea from "@material-ui/core/CardActionArea";
import CircularProgressWithLabel from "../../../SharedComponents/CircularProgressWithLabel.jsx";
import LockIcon from "../../../SharedComponents/LockIcon.jsx";
import { useDeckData } from "../../../Providers/Data/DeckDataProvider.jsx";
import LinearProgressBar from "../../../SharedComponents/LinearProgressBar.jsx";

const useStyles = makeStyles((theme) => ({
  boxRoot: {
    maxWidth: 95,
    display: "flex",
    flexDirection: "column",
    margin: "0 9px",
    opacity: ({ disabled }) => (disabled ? 0.5 : 1),
    [theme.breakpoints.up("lg")]: {
      margin: "10px 9px 0",
    },
  },
  cardRoot: {
    position: "relative",
    width: 95,
    height: 97,
    borderRadius: 15,
    backgroundImage: ({ illustrationImage }) =>
      illustrationImage ? `linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.00) 55%), url(${illustrationImage})` : null,
    backgroundSize: "cover",
    backgroundPosition: "bottom, center",
    cursor: ({ disabled }) => (disabled ? "pointer" : "default"),
  },
  cardContent: {
    display: "flex",
    width: "100%",
    height: "100%",
  },
  linearProgress: {
    width: "100%",
    margin: "auto 0 0",
  },
  deckName: {
    textAlign: "center",
    margin: "5px 0 0",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  lockIcon: {
    top: 2,
    right: 2,
  },
}));

/**
 * @param {Deck} deck
 * @returns {*}
 * @constructor
 */
const SmallDeckCard = ({ disabled }) => {
  const { deck, branch, onCardClick, progressValue } = useDeckData();
  const illustrationImage = (deck.illustrationImageUrl || deck.illustrationImage?.url)?.replace(/\\/g, "/");
  const classes = useStyles({ disabled, illustrationImage });

  if (!branch) {
    return null;
  }

  const cardContent = (
    <CardContent className={classes.cardContent}>
      {!illustrationImage && (
        <CircularProgressWithLabel value={deck.percent} componentSize={65} textValue={progressValue} />
      )}
      {illustrationImage && (
        <LinearProgressBar
          variant="determinate"
          className={classes.linearProgress}
          value={deck.percent}
          radius="0px"
          height={10}
          noborder={illustrationImage}
        />
      )}
    </CardContent>
  );

  return (
    <Box className={classes.boxRoot}>
      <Card className={classes.cardRoot} elevation={disabled ? 1 : 4}>
        {disabled && <CardActionArea onClick={onCardClick}>{cardContent}</CardActionArea>}
        {!disabled && cardContent}
        {(!deck.open || !branch.open) && <LockIcon className={classes.lockIcon} />}
      </Card>
      <Typography className={classes.deckName} variant="caption">
        {deck.name}
      </Typography>
    </Box>
  );
};

SmallDeckCard.propTypes = {
  disabled: PropTypes.bool,
};

export default SmallDeckCard;
