import PropTypes from "prop-types";
import React from "react";
import Link from "@material-ui/core/Link";

/**
 * @param {string} href
 * @param {string} fileName
 * @param {string} linkText
 * @param {string} className
 */
const DownloadLink = ({ href, fileName, linkText, className }) => {
  /**
   * @param {Event} event
   */
  const onLinkClick = (event) => {
    // This approach is to prevent opening some files in the current browser tab
    event.preventDefault();
    fetch(href)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      });
  };

  return (
    <Link className={className} href={href} variant="body1" onClick={onLinkClick}>
      {linkText}
    </Link>
  );
};

DownloadLink.propTypes = {
  fileName: PropTypes.string,
  href: PropTypes.string,
  linkText: PropTypes.string,
  className: PropTypes.string,
};

export default DownloadLink;
