import React from "react";
import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";

export default withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    textTransform: "none",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    "&:disabled": {
      backgroundColor: theme.palette.primary.dark,
      opacity: "0.6",
    },
  },
}))((props) => <Button {...props} variant="contained" />);
