import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslate } from "react-polyglot";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import PrimarySmallRoundedButton from "../../../SharedComponents/PrimarySmallRoundedButton.jsx";
import iconSad from "../../../assets/iconSad.svg";
import FlexboxHorizontal from "../../../SharedComponents/FlexboxHorizontal.jsx";
import { useBaseData } from "../../../Providers/Data/BaseDataProvider.jsx";

/** */
const InactiveUserBar = ({ classes }) => {
  const t = useTranslate();
  const history = useHistory();
  const { userInfo, company } = useBaseData();
  const [dialogOpen, setDialogOpen] = useState(false);

  const waitOffersCount = userInfo.offers.filter((offer) => offer.status === "pending").length;

  if ((userInfo.isActive || userInfo.isDemoMode) && waitOffersCount === 0) {
    return null;
  } else if ((userInfo.isActive || userInfo.isDemoMode) && waitOffersCount > 0) {
    return (
      <FlexboxHorizontal className={clsx(classes.warningBar, classes.inactiveUserBar)}>
        <img className={classes.sadIcon} src={iconSad} alt="" />
        <Typography className={classes.inactiveUserBarText}>{t("youAreHaveNotPaidOffers")}</Typography>
        <PrimarySmallRoundedButton
          className={classes.moreButton}
          onClick={() => {
            history.push("/profile");
          }}
        >
          {t("check")}
        </PrimarySmallRoundedButton>
      </FlexboxHorizontal>
    );
  }

  /** */
  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <FlexboxHorizontal className={clsx(classes.warningBar, classes.inactiveUserBar)}>
        <img className={classes.sadIcon} src={iconSad} alt="" />
        <Typography className={classes.inactiveUserBarText}>{t("youAreDeactivatedShort")}</Typography>
        <PrimarySmallRoundedButton
          className={classes.moreButton}
          onClick={() => {
            setDialogOpen(true);
          }}
        >
          {t("more")}
        </PrimarySmallRoundedButton>
      </FlexboxHorizontal>
      <Dialog maxWidth="xs" open={dialogOpen} onClose={handleClose}>
        <DialogContent className={classes.inactiveUserDialogContent}>
          <img className={classes.inactiveUserDialogContentSadIcon} src={iconSad} alt="" />
          <Typography className={classes.inactiveUserDialogContentText}>{t("youAreDeactivated")}</Typography>
          <Typography className={classes.inactiveUserDialogContentCompanyName}>{company.name}</Typography>
          <Typography className={classes.inactiveUserDialogContentCompanyContacts}>
            <a href={`tel:${company.phone}`}>{company.phone}</a>
          </Typography>
          <Typography className={classes.inactiveUserDialogContentCompanyContacts}>
            <a href={`mailto:${company.email}`}>{company.email}</a>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" fullWidth autoFocus variant="contained">
            {t("exit")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

InactiveUserBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default InactiveUserBar;
