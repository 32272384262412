import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import Carousel from "../../../SharedComponents/Carousel.jsx";
import BranchCard from "./BranchCard.jsx";
import { useCourseProvider } from "../../../Providers/Data/CourseProvider.jsx";
import useMediaQueryUp from "../../../hooks/useMediaQueryUp.js";

const useStyles = makeStyles((theme) => ({
  branchListRoot: {
    width: "calc(100% + 93px)",
    transform: "translateX(-47px)",
    "& .slick-slide > div": {
      display: "flex",
      flexDirection: "column",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
      transform: "none",
    },
  },
  condensedSlidesLg: {
    "& .slick-slide": {
      width: "196px !important",
    },
  },
  condensedSlides: {
    "& .slick-slide": {
      width: "215px !important",
    },
  },
  carouselArrows: {
    margin: "10px 0 6px",
    borderRadius: 10,
  },
}));

/**
 * @returns {null|*}
 * @constructor
 */
const BranchList = () => {
  const classes = useStyles();
  const { branchesForLevel, currentBranch, saveCurrentBranch } = useCourseProvider();
  const lg = useMediaQueryUp("lg");

  if (!branchesForLevel || branchesForLevel.length === 0) {
    return null;
  }

  const initialSlideIndex = branchesForLevel.findIndex((element) =>
    currentBranch ? currentBranch.id === element.id : element.percent.toFixed() < 100,
  );
  const slidesToShow = branchesForLevel.length > 4 ? 4 : branchesForLevel.length;

  return (
    <Carousel
      className={clsx(classes.branchListRoot, {
        [classes.condensedSlidesLg]: branchesForLevel.length < 4 && lg,
        [classes.condensedSlides]: branchesForLevel.length < 4 && !lg,
      })}
      arrowButtonsClassName={classes.carouselArrows}
      sliderSettings={{ slidesToShow, initialSlide: initialSlideIndex, slidesToScroll: 4 }}
    >
      {branchesForLevel.map((branch, i) => (
        <BranchCard
          key={branch.id}
          branch={branch}
          isActive={currentBranch.id === branch.id}
          onClick={() => saveCurrentBranch(branchesForLevel[i])}
        />
      ))}
    </Carousel>
  );
};

export default BranchList;
