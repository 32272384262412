import * as querystring from "querystring";
import { useHistory } from "react-router-dom";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useHttp } from "../Common/HttpProvider.jsx";
import { useLocale } from "../i18n/I18nProvider.jsx";
import { useBaseData } from "./BaseDataProvider.jsx";
import useExtractRouteParamInt from "../../hooks/useExtractRouteParamInt.js";

/**
 * @callback goToFinishedHomework
 * @param {Number} homeworkId
 * @returns {Promise<void>}
 */

/**
 * @callback saveTodoHomework
 * @param {Object} payload
 * @returns {Promise<void>}
 */

/**
 * @typedef {Object} HomeworksProviderContext
 * @property {PendingHomework[]} pendingHomeworks
 * @property {FinishedHomework[]} finishedHomeworks
 * @property {?ToDoHomework} currentToDoHomework
 * @property {goToFinishedHomework} goToFinishedHomework
 * @property {saveTodoHomework} saveTodoHomework
 */

/** @type {React.Context<HomeworksProviderContext>} */
const HomeworksContext = createContext({
  pendingHomeworks: [],
  finishedHomeworks: [],
  currentToDoHomework: null,
  /** @type {goToFinishedHomework} */ goToFinishedHomework: () => {},
  /** @type {saveTodoHomework} */ saveTodoHomework: () => {},
  /** @type FnAsyncVoid */ showMorePendingHW: () => {},
  /** @type FnAsyncVoid */ showMoreFinishedHW: () => {},
});

/** @returns {HomeworksProviderContext} */
export const useHomeworks = () => useContext(HomeworksContext);

/**
 * @param {React.ReactNode} children
 * @returns {React.ReactNode}
 * @constructor
 */
const HomeworksProvider = ({ children }) => {
  const history = useHistory();
  const { get, post, patch } = useHttp();
  const { locale } = useLocale();
  const { refreshUserInfo, subject } = useBaseData();
  const homeworkTodoId = useExtractRouteParamInt("/homeworks/todo/:id", "id");
  const homeworkFinishedId = useExtractRouteParamInt("/homeworks/finished/:id", "id");
  const [pendingHomeworks, setPendingHomeworks] = useState(/** @type {PendingHomework[]} */ []);
  const [finishedHomeworks, setFinishedHomeworks] = useState(/** @type {FinishedHomework[]} */ []);
  const [currentToDoHomework, setCurrentToDoHomework] = useState(/** @type {ToDoHomework} */ null);
  const [skipPendingHW, setSkipPendingHW] = useState(/** @type {Number} */ 10);
  const [skipFinishedHW, setSkipFinishedHW] = useState(/** @type {Number} */ 10);

  const subjectId = subject?.subjectId;

  /** */
  const requestData = async () => {
    const respPending = await get(`homeworks/pending?${querystring.stringify({ skip: 0, limit: 10 })}`);
    if (respPending) {
      setPendingHomeworks(respPending);
      const respFinished = await get(`homeworks?${querystring.stringify({ skip: 0, limit: 10 })}`);
      if (respFinished) {
        setFinishedHomeworks(respFinished);
      }
    }
  };

  /** */
  const showMorePendingHW = async () => {
    const respPending = await get(`homeworks/pending?${querystring.stringify({ skip: skipPendingHW, limit: 10 })}`);
    if (respPending) {
      setPendingHomeworks([...pendingHomeworks, ...respPending]);
      setSkipPendingHW(skipPendingHW + 10);
    }
  };

  /** */
  const showMoreFinishedHW = async () => {
    const respFinished = await get(`homeworks?${querystring.stringify({ skip: skipFinishedHW, limit: 10 })}`);
    if (respFinished) {
      setFinishedHomeworks([...finishedHomeworks, ...respFinished]);
      setSkipFinishedHW(skipFinishedHW + 10);
    }
  };

  // load homeworks at start
  useEffect(() => {
    if (homeworkTodoId || homeworkFinishedId) return;
    requestData().then();
    refreshUserInfo();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, homeworkTodoId, homeworkFinishedId, subjectId]);

  // load todoHomework if id specified
  useEffect(() => {
    if (!homeworkTodoId) {
      setCurrentToDoHomework(null);
      return;
    }
    /** */
    const requestToDoHomework = async () => {
      /** @type {ToDoHomework} */
      const homework = await get(`homeworks_todo/${homeworkTodoId}`);
      setCurrentToDoHomework(homework);
    };
    requestToDoHomework().then();
  }, [homeworkTodoId]);

  // load finished homework if id specified
  useEffect(() => {
    if (!homeworkFinishedId) {
      setCurrentToDoHomework(null);
      return;
    }
    /** */
    const requestToDoHomework = async () => {
      const homework = await get(`homeworks/${homeworkFinishedId}?${querystring.stringify({ returnObject: true })}`); // homeworks/8515?returnObject=true
      const exercise = homework.item;
      exercise.question = exercise.data.question;
      exercise.homework = { userData: exercise.userData, status: exercise.status, teacherData: exercise.teacherData };
      setCurrentToDoHomework(exercise);
    };
    requestToDoHomework().then();
  }, [homeworkFinishedId]);

  /**
   * @param {Number} homeworkId
   * @returns {Promise<void>}
   */
  const goToFinishedHomework = async (homeworkId) => {
    /** @type {Homework} */
    const homework = await get(`homeworks/${homeworkId}`);
    if (homework) {
      //history.push(`/learn/${homework.deck.id}/exercise/${homework.exercise.id}`);
    }
  };

  /** */
  const saveTodoHomework = async (payload) => {
    let postResult;
    if (homeworkTodoId) {
      postResult = await post(`homeworks_todo/${homeworkTodoId}`, payload);
    }
    if (homeworkFinishedId) {
      postResult = await patch(`homeworks/${homeworkFinishedId}`, payload);
    }
    if (postResult) {
      history.push("/homeworks");
    }
  };

  const value = {
    pendingHomeworks,
    finishedHomeworks,
    currentToDoHomework,
    goToFinishedHomework,
    saveTodoHomework,
    showMorePendingHW,
    showMoreFinishedHW,
  };

  return <HomeworksContext.Provider value={value}>{children}</HomeworksContext.Provider>;
};

export default HomeworksProvider;
