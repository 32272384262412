import * as querystring from "query-string";

/** @param {String} url
 * @returns {string[]|string|null|*}
 */
export function youtubeGetId(url) {
  if (url.indexOf("youtube.com/shorts") !== -1 || url.indexOf("youtube.com/live") !== -1) {
    // youtube shorts or live
    const parts = url.split("/");
    return parts[parts.length - 1].match(/[^?^&]+/)[0];
  } else if (url.indexOf("youtu.be") !== -1) {
    // youtu.be
    const parts = url.split("/");
    return parts[parts.length - 1].match(/[^?^&]+/)[0];
  } else {
    // youtube.com
    return querystring.parseUrl(url).query["v"];
  }
}

/** @param {String} url
 * @returns {{zero: string, default: string, mqdefault: string, sddefault: string, maxresdefault: string, hqdefault: string}}
 */
export function youtubeGetThumbUrl(url) {
  const id = youtubeGetId(url);
  const base = `https://img.youtube.com/vi/${id}/`;

  return {
    zero: `${base}0.jpg`,
    default: `${base}default.jpg`,
    sddefault: `${base}sddefault.jpg`,
    mqdefault: `${base}mqdefault.jpg`,
    hqdefault: `${base}hqdefault.jpg`,
    maxresdefault: `${base}maxresdefault.jpg`,
  };
}
