import PropTypes from "prop-types";
import React, { forwardRef, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded.js";
import { Document, Page } from "react-pdf";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded.js";
import clsx from "clsx";
import Chip from "@material-ui/core/Chip";
import FullscreenIcon from "@material-ui/icons/Fullscreen.js";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit.js";
import { makeStyles } from "@material-ui/core";
import FlexboxHorizontal from "./FlexboxHorizontal.jsx";
import { useFullscreenMode } from "../hooks/useFullscreenMode.js";

const useStyles = makeStyles(() => ({
  pdfToolbar: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    alignItems: "center",
  },
  pdfToolbarMargin: {
    marginTop: 10,
  },
  fullscreenButton: {
    minWidth: 36,
    backgroundColor: "rgba(230, 230, 230, 0.4)",
    marginLeft: 10,
    "&:hover": {
      backgroundColor: "rgba(230, 230, 230, 0.9)",
    },
  },
}));

/**
 * @param {string} pdfPath
 * @param {boolean} showPageNumber
 * @param {boolean} showFullscreenButton
 * @param {boolean} showNavigationButtons
 * @param {function} onDocumentLoaded
 * @param {function} onPageChanged
 * @param {number} width
 * @returns {*}
 * @constructor
 */
const PdfViewer = forwardRef(
  (
    { pdfPath, showPageNumber, showFullscreenButton, showNavigationButtons, onDocumentLoaded, onPageChanged, width },
    ref,
  ) => {
    const classes = useStyles();
    const [numPages, setNumPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    /** @type {React.MutableRefObject<HTMLElement>} */
    const pdfWrapperRef = useRef();
    const { isFullscreen } = useFullscreenMode();

    /** */
    const onDocumentLoadSuccess = ({ numPages }) => {
      setNumPages(numPages);
      onDocumentLoaded && onDocumentLoaded(numPages);
    };

    // /**
    //  * @param {Number} increment
    //  * @returns {function(...[*]=)}
    //  */
    // const changePage = (increment) => () => {
    //   const newPageNumber = currentPage + increment;
    //   if (newPageNumber < 1 || newPageNumber > numPages) {
    //     return;
    //   }
    //   setCurrentPage(newPageNumber);
    //   onPageChanged && onPageChanged(newPageNumber);
    // };

    /** */
    const nextPage = () => {
      const newPageNumber = currentPage + 1;
      if (newPageNumber > numPages) {
        return;
      }
      setCurrentPage(newPageNumber);
      onPageChanged && onPageChanged(newPageNumber);
    };

    /** */
    const prevPage = () => {
      const newPageNumber = currentPage - 1;
      if (newPageNumber < 1) {
        return;
      }
      setCurrentPage(newPageNumber);
      onPageChanged && onPageChanged(newPageNumber);
    };

    // /** @param {React.ReactNode} node */
    // const onRefChange = (node) => {
    //   pdfWrapperRef.current = node;
    //   if (ref) {
    //     ref.current = {
    //       pdfWrapper: node,
    //       changePage,
    //     };
    //   }
    // };

    if (ref) {
      ref.current = {
        pdfWrapper: pdfWrapperRef.current,
        nextPage,
        prevPage,
      };
    }

    return (
      <FlexboxHorizontal
        ref={pdfWrapperRef}
        data-tag="pdf wrapper"
        width="100%"
        justifyContent={showNavigationButtons ? "space-between" : "center"}
        position="relative"
      >
        {numPages && showNavigationButtons && (
          <Button color="primary" disabled={currentPage === 1} onClick={prevPage}>
            <ArrowBackIosRoundedIcon />
          </Button>
        )}
        <Document file={pdfPath} onLoadSuccess={onDocumentLoadSuccess}>
          <Page
            pageNumber={currentPage}
            width={isFullscreen ? undefined : width || 600}
            height={isFullscreen ? pdfWrapperRef.current.getBoundingClientRect().height : undefined}
            renderAnnotationLayer={true}
            renderTextLayer={true}
          />
        </Document>
        {numPages && (
          <>
            {showNavigationButtons && (
              <Button color="primary" disabled={currentPage === numPages} onClick={nextPage}>
                <ArrowForwardIosRoundedIcon />
              </Button>
            )}
            <FlexboxHorizontal className={clsx(classes.pdfToolbar, { [classes.pdfToolbarMargin]: isFullscreen })}>
              {showPageNumber && <Chip label={`${currentPage} / ${numPages}`} />}
              {!isFullscreen && showFullscreenButton && (
                <Button
                  className={classes.fullscreenButton}
                  onClick={() => pdfWrapperRef.current.requestFullscreen().then()}
                >
                  <FullscreenIcon />
                </Button>
              )}
              {isFullscreen && (
                <Button className={classes.fullscreenButton} onClick={() => document.exitFullscreen()}>
                  <FullscreenExitIcon />
                </Button>
              )}
            </FlexboxHorizontal>
          </>
        )}
      </FlexboxHorizontal>
    );
  },
);

PdfViewer.propTypes = {
  onDocumentLoaded: PropTypes.func,
  onPageChanged: PropTypes.func,
  pdfPath: PropTypes.string.isRequired,
  showFullscreenButton: PropTypes.bool,
  showPageNumber: PropTypes.bool,
  showNavigationButtons: PropTypes.bool,
  width: PropTypes.number,
};

PdfViewer.defaultProps = {
  showFullscreenButton: true,
  showPageNumber: true,
  showNavigationButtons: true,
};

PdfViewer.displayName = "PdfViewer";

export default PdfViewer;
