import React, { useEffect, useRef, useState } from "react";
import Slide from "@material-ui/core/Slide";
import { useTranslate } from "react-polyglot";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close.js";
import Button from "@material-ui/core/Button";
import ZoomOutOutlinedIcon from "@material-ui/icons/ZoomOutOutlined.js";
import ZoomInOutlinedIcon from "@material-ui/icons/ZoomInOutlined.js";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded.js";
import Chip from "@material-ui/core/Chip";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded.js";
import DialogContent from "@material-ui/core/DialogContent";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import PdfViewer from "../../../../../SharedComponents/PdfViewer.jsx";
import FlexboxHorizontal from "../../../../../SharedComponents/FlexboxHorizontal.jsx";

const useStyles = makeStyles((theme) => ({
  additionalStuffButton: {
    position: "absolute",
    top: 50,
    right: 3,
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.light,
    padding: 3,
    color: "white",
    "&>h2": {
      display: "flex",
    },
  },
  dialogContent: {
    padding: 0,
  },
  dialogPageChip: {
    color: "white",
    borderColor: "white",
  },
  space: {
    width: theme.spacing(2),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * @param {string} pdfUrl
 */
const Appendix = ({ pdfUrl }) => {
  const t = useTranslate();
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [pageValue, setPageValue] = useState("0 / 0");
  const [pdfPagesCount, setPdfPagesCount] = useState(0);
  const [pdfPageWidth, setPdfPageWidth] = useState(600);
  const pdfViewerRef = useRef();

  // reset values on dialog open
  useEffect(() => {
    if (dialogOpen) {
      setPdfPageWidth(600);
      setPdfPagesCount(0);
      setPageValue("0 / 0");
    }
  }, [dialogOpen]);

  /** */
  const openDialog = () => {
    setDialogOpen(true);
  };

  /** */
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  /**
   * @param {number} pagesCount
   */
  const onPdfLoaded = (pagesCount) => {
    setPdfPagesCount(pagesCount);
    setPageValue(`1 / ${pagesCount}`);
  };

  /**
   * @param {number} newPageIndex
   */
  const onPdfPageChanged = (newPageIndex) => {
    setPageValue(`${newPageIndex} / ${pdfPagesCount}`);
  };

  /** */
  const zoomIn = () => {
    setPdfPageWidth(pdfPageWidth * 1.5);
  };

  /** */
  const zoomOut = () => {
    setPdfPageWidth(pdfPageWidth / 1.5);
  };

  return (
    <>
      <Tooltip title={t("Exercises.appendix.pdf.tooltipButton")}>
        <IconButton className={classes.additionalStuffButton} onClick={openDialog}>
          <PictureAsPdfOutlinedIcon fontSize="large" />
        </IconButton>
      </Tooltip>
      <Dialog open={dialogOpen} fullScreen onClose={handleDialogClose} TransitionComponent={Transition}>
        <DialogTitle className={classes.dialogTitle}>
          <IconButton aria-label="close" onClick={handleDialogClose} color="inherit">
            <CloseIcon fontSize="small" />
          </IconButton>
          <FlexboxHorizontal data-tag="pdf control buttons" flexGrow={1} justifyContent="center" alignItems="center">
            <Button color="inherit" onClick={zoomOut}>
              <ZoomOutOutlinedIcon />
            </Button>
            <Button color="inherit" onClick={zoomIn}>
              <ZoomInOutlinedIcon />
            </Button>
            <div className={classes.space} />
            <Button color="inherit" onClick={() => pdfViewerRef.current.prevPage()}>
              <ArrowBackIosRoundedIcon fontSize="small" />
            </Button>
            <Chip className={classes.dialogPageChip} label={pageValue} variant="outlined" />
            <Button color="inherit" onClick={() => pdfViewerRef.current.nextPage()}>
              <ArrowForwardIosRoundedIcon fontSize="small" />
            </Button>
          </FlexboxHorizontal>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <PdfViewer
            ref={pdfViewerRef}
            pdfPath={pdfUrl}
            showFullscreenButton={false}
            showPageNumber={false}
            showNavigationButtons={false}
            onDocumentLoaded={onPdfLoaded}
            onPageChanged={onPdfPageChanged}
            width={pdfPageWidth}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

Appendix.propTypes = {
  pdfUrl: PropTypes.string.isRequired,
};

export default Appendix;
