import PropTypes from "prop-types";
import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import icLock from "../assets/ic_lock.png";

const useStyles = makeStyles(() => ({
  lockIcon: {
    position: "absolute",
    width: 20,
    right: 10,
    top: 9,
  },
}));

/** @param {String} className
 * @param {Object} rest
 * @returns {*}
 * @constructor
 */
const LockIcon = ({ className, ...rest }) => {
  const classes = useStyles();

  return <img {...rest} src={icLock} alt="locked" className={clsx(classes.lockIcon, className)} />;
};

LockIcon.propTypes = {
  className: PropTypes.string,
};

export default LockIcon;
