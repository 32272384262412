import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import YouTube from "react-youtube";

/**
 * @param {FnYtCallback} onPlayback
 * @param {String} startTime
 * @param {Object} rest
 * @returns {*}
 * @constructor
 */
const YtPlayer = ({ onPlayback, startTime, ...rest }) => {
  const playerRef = useRef(null);

  /** @param {*} target */
  const youTubeReadyHandler = ({ target }) => {
    playerRef.current = target;
  };

  useEffect(() => {
    const handle = setInterval(() => {
      if (onPlayback) {
        const player = playerRef.current;
        if (!player) {
          return;
        }
        const state = player.getPlayerState();
        if (state === 1) {
          const duration = player.getDuration();
          const currentTime = player.getCurrentTime();
          const percent = currentTime / duration;
          onPlayback(currentTime, percent, duration);
        }
      }
    }, 250);

    return () => {
      clearInterval(handle);
    };
  }, [onPlayback]);

  return (
    <YouTube {...rest} onReady={youTubeReadyHandler} opts={{ playerVars: { modestbranding: 1, start: startTime } }} />
  );
};

YtPlayer.propTypes = {
  onPlayback: PropTypes.func,
  startTime: PropTypes.string,
};

export default YtPlayer;
