import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useBaseData } from "./BaseDataProvider.jsx";

/**
 * @param {(React.ReactNode|HTMLElement)} children
 * @param {Object} rest
 * @returns {React.ReactNode}
 * @constructor
 */
const CompanyProtectedRoute = ({ children, ...rest }) => {
  const { subject, userInfo, company } = useBaseData();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        subject && subject.token && userInfo && company ? (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        ) : (
          React.cloneElement(children)
        )
      }
    />
  );
};

export default CompanyProtectedRoute;
