import React, { createContext, useContext, useEffect, useState } from "react";
import { useHttp } from "../Common/HttpProvider.jsx";
import { useLocale } from "../i18n/I18nProvider.jsx";
import { useBaseData } from "./BaseDataProvider.jsx";

/**
 * @typedef {Object} MissionsProviderContext
 * @property {Mission[]} missions
 */

/** @type {React.Context<MissionsProviderContext>} */
const missionsContext = createContext({
  missions: [],
});

/** @returns {MissionsProviderContext} */
export const useMissions = () => useContext(missionsContext);

/**
 * @param {React.ReactNode} children
 * @returns {React.ReactNode}
 * @constructor
 */
const MissionsProvider = ({ children }) => {
  const { get } = useHttp();
  const { locale } = useLocale();
  const { subject } = useBaseData();
  const [missions, setMissions] = useState(/** @type {Mission[]} */ []);

  const subjectId = subject?.subjectId;

  /** */
  const requestData = async () => {
    const resp = await get("missionsTree");
    if (resp) {
      setMissions(resp);
    }
  };

  // load news at start
  useEffect(() => {
    requestData().then();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, subjectId]);

  const value = {
    missions,
  };

  return <missionsContext.Provider value={value}>{children}</missionsContext.Provider>;
};

export default MissionsProvider;
