import PropTypes from "prop-types";
import React from "react";
import { useTranslate } from "react-polyglot";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import FlexboxHorizontal from "./FlexboxHorizontal.jsx";

const useStyles = makeStyles(() => ({
  title: {
    marginRight: 20,
  },
}));

/**
 * @param {string} scr
 * @param {string} title - Translatable string
 * @returns {*}
 * @constructor
 */
const SimpleAudioPlayer = ({ src, title }) => {
  const t = useTranslate();
  const classes = useStyles();

  return (
    <FlexboxHorizontal alignItems="center" justifyContent="center" marginTop="10px">
      <Typography className={classes.title}>{t(title)}:</Typography>
      <audio controls src={src} />
    </FlexboxHorizontal>
  );
};

SimpleAudioPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default SimpleAudioPlayer;
