/**
 * @param {String} key
 * @returns {Object}
 */
export const loadObjectFromStorage = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

/**
 * @param {String} key
 * @param {Object} value
 */
export const saveObjectToStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};
