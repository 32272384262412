import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import grey from "@material-ui/core/colors/grey";
import { useTranslate } from "react-polyglot";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { useHistory } from "react-router-dom";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useBaseData } from "../../Providers/Data/BaseDataProvider.jsx";
import ic_notif from "../../assets/ic_color_notification.png";
import ic_notif_permission from "../../assets/image_notif_permission.png";
import ic_certificate from "../../assets/ic_certificate.png";
import ic_empty_avatar from "../../assets/ic_empty_avatar.svg";
import useSubjectSelector from "../../hooks/useSubjectSelector.js";
import useCompanyTitle from "../../hooks/useCompanyTitle.js";
import StudentProgressStatistic from "../../SharedComponents/StudentProgressStatistic.jsx";
import { useAuth } from "../../Providers/Auth/AuthProvider.jsx";
import SubjectButton from "./SharedComponents/SubjectButton.jsx";
import BaseAccordion from "../../SharedComponents/BaseAccordion.jsx";
import FlexboxVertical from "../../SharedComponents/FlexboxVertical.jsx";
import FlexboxHorizontal from "../../SharedComponents/FlexboxHorizontal.jsx";
import YellowButton from "../../SharedComponents/YellowButton.jsx";
import EditUserInfoDialog from "./SharedComponents/EditUserInfoDialog.jsx";
import OfferCard from "../Subjects/SharedComponents/OfferCard.jsx";
import { isNotificationsSupported } from "../../Utils/firebase.js";
import ContactUs from "../../SharedComponents/ContactUs.jsx";
import { useDialogs } from "../../Providers/Common/DialogsProvider.jsx";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexWrap: "wrap",
    padding: "30px 0 10px",
  },
  infoItem: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      width: "35%",
      flexDirection: "column",
    },
  },
  cardImg: {
    width: 100,
  },
  userAvatar: {
    width: 100,
    height: 100,
    objectFit: "cover",
    borderRadius: "50%",
  },
  card: {
    backgroundColor: grey[50],
    fontSize: 12,
    marginBottom: 20,
    width: "49%",
    borderRadius: 10,
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    "&:last-child": {
      width: "100%",
    },
  },
  cardContent: {
    display: "flex",
    alignItems: "flex-start",
  },
  cardText: {
    marginLeft: 20,
    width: "100%",
  },
  cardBtns: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginLeft: "auto",
  },
  certificateText: {
    marginLeft: 20,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignSelf: "center",
    alignItems: "center",
  },
  certificateBtns: {
    display: "flex",
    marginLeft: "auto",
    flexDirection: "row",
  },
  cardBtn: {
    color: theme.palette.primary.dark,
  },
  imgBox: {
    position: "relative",
  },
  mainItem: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "63%",
    },
  },
  title: {
    textTransform: "uppercase",
    fontWeight: 500,
    marginBottom: 35,
  },
  levelBtns: {
    maxWidth: 415,
    margin: "0 auto",
  },
  expandableList: {
    padding: 0,
    flexDirection: "column",
  },
  more: {
    backgroundColor: theme.palette.grey[100],
  },
  dialogRoot: {
    paddingTop: 0,
    padding: 0,
    "&:first-child": {
      paddingTop: 0,
    },
  },
  dialogHeader: {
    backgroundColor: theme.palette.primary.dark,
    color: "white",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: 52,
  },
  dialogHeaderText: {
    fontSize: 20,
    marginLeft: 20,
  },
  yellowButton: {
    margin: 8,
    "& b": {
      marginLeft: 5,
    },
  },
  btnMargin: {
    margin: "8px 0",
  },
  alertImg: {
    width: "100%",
    marginTop: 10,
  },
  temporaryLogoWrapper: {
    height: 300,
    alignSelf: "center",
    marginBottom: 20,
    "&>img": {
      height: "100%",
    },
  },
  offerCards: {
    marginTop: 20,
  },
  label: {
    height: 40,
    minWidth: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    position: "absolute",
    top: 5,
    left: "100%",
    transform: "translateX(-70%)",
    fontSize: 14,
    fontWeight: 600,
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.background.default,
  },
}));

/* eslint-disable react/prop-types */
/**
 * @param {boolean} open
 * @param {function} onClose
 * @param {Certificate[]} data
 * @param {*} rest
 * @returns {*}
 * @constructor
 */
const CertificatesDialog = ({ open, onClose, data, ...rest }) => {
  const classes = useStyles();
  const t = useTranslate();

  return (
    <Dialog {...rest} open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t("yourCertificates")}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {data?.map((certificate) => (
          <div key={certificate.path} className={classes.cardContent}>
            <div className={classes.imgBox}>
              <img className={classes.cardImg} src={ic_certificate} alt="Сertificate" />
            </div>
            <div className={classes.certificateText}>
              <Typography gutterBottom>{certificate.templateName}</Typography>
              <div className={classes.certificateBtns}>
                <IconButton
                  className={classes.cardBtn}
                  component="a"
                  href={certificate.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <VisibilityIcon />
                </IconButton>
              </div>
            </div>
          </div>
        ))}
      </DialogContent>
    </Dialog>
  );
};

/** */
const Profile = () => {
  const classes = useStyles();
  const history = useHistory();
  const { company2 } = useAuth();
  const {
    chooseSubject,
    availableSubjects,
    userInfo,
    subject,
    counts,
    requestCounts,
    setPreselectedSubject,
    setPreselectedOffer,
    preselectedOffer,
  } = useBaseData();
  const { alert } = useDialogs();
  const t = useTranslate();
  const { subjectHandler, subjectId, setSubjectId, selectedSubject } = useSubjectSelector();
  const [isDialogOpened, setDialogOpened] = useState(false);
  const [isUserEditDialogOpened, setUserEditDialogOpened] = useState(false);
  const [isCertDialogOpen, setCertDialogOpen] = useState(false);

  useCompanyTitle("title.profile");

  // preload data
  useEffect(() => {
    requestCounts().then();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject, preselectedOffer]);

  // init subject state with current values in data provider
  useEffect(() => {
    setSubjectId(subject.subjectId);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject, preselectedOffer]);

  /** */
  const notifButtonHandler = () => {
    if (!isNotificationsSupported()) return;

    if (Notification.permission === "denied") {
      alert({
        buttonText: t("closeButton"),
        title: t("notificationsAlert.permissionDenied.title"),
        content: (
          <>
            <Typography gutterBottom>{t("notificationsAlert.permissionDenied.body")}</Typography>
            <Typography gutterBottom>{t("notificationsAlert.instructions")}</Typography>
            <img className={classes.alertImg} src={ic_notif_permission} alt="logo" />
          </>
        ),
      });
    } else if (Notification.permission === "granted") {
      alert({
        buttonText: t("closeButton"),
        title: t("notificationsAlert.permissionGranted.title"),
        content: (
          <>
            <Typography gutterBottom>{t("notificationsAlert.permissionGranted.body")}</Typography>
            <Typography gutterBottom>{t("notificationsAlert.instructions")}</Typography>
            <img className={classes.alertImg} src={ic_notif_permission} alt="logo" />
          </>
        ),
      });
    } else {
      Notification.requestPermission().then();
    }
  };

  /**
   * @param {CompanySubject} subj
   */
  const subjectButtonClick = (subj) => () => {
    subjectHandler(subj)();
    setDialogOpened(true);
  };

  /** */
  const handleCloseDialog = () => {
    setDialogOpened(false);
  };

  /** */
  const yellowButtonHandler = () => {
    if (selectedSubject.price === 0 || selectedSubject.purchased) {
      chooseSubject(subjectId).then();
      setDialogOpened(false);
    } else {
      setPreselectedSubject(subjectId);
      history.push("/pay");
    }
  };

  /** */
  const offerButtonHandler = async (offer) => {
    setPreselectedOffer(offer.id);
  };

  useEffect(() => {
    if (subjectId && !preselectedOffer) {
      chooseSubject(subjectId).then();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preselectedOffer]);

  if (!availableSubjects || !counts || !company2) {
    return null;
  }

  const addedSubjects =
    (availableSubjects && availableSubjects.filter((subj) => subj.added === true || subj.added === undefined)) || [];
  const notAddedSubjects = (availableSubjects && availableSubjects.filter((subj) => subj.added === false)) || [];

  const companyLogoUrl = company2.logo.url;

  return (
    <>
      <Box className={classes.wrapper}>
        <Box className={classes.infoItem}>
          <Card className={classes.card} elevation={4}>
            <CardContent className={classes.cardContent}>
              <img className={classes.cardImg} src={companyLogoUrl} alt="logo" />
              <div className={classes.cardText}>
                <Typography gutterBottom>{company2.name}</Typography>
                <Typography gutterBottom color="textSecondary">
                  {company2.description}
                </Typography>
                <Typography gutterBottom>{company2.phone}</Typography>
                <Typography>
                  <i>{company2.email}</i>
                </Typography>
              </div>
            </CardContent>
          </Card>
          <ContactUs />
          <Card className={classes.card} elevation={4}>
            <CardContent className={classes.cardContent}>
              <div className={classes.imgBox}>
                <img
                  className={classes.userAvatar}
                  src={userInfo.photo?.url ? userInfo.photo.url : ic_empty_avatar}
                  alt="User avatar"
                />
              </div>
              <div className={classes.cardText}>
                <Typography gutterBottom>{userInfo.name}</Typography>
                <Typography gutterBottom color="textSecondary">
                  {userInfo.email}
                </Typography>
                <Typography gutterBottom color="textSecondary">
                  {userInfo.phone}
                </Typography>
              </div>
              <div className={classes.cardBtns}>
                {/*<IconButton className={classes.cardBtn}>*/}
                {/*  <SettingsIcon />*/}
                {/*</IconButton>*/}
                <IconButton className={classes.cardBtn} onClick={() => setUserEditDialogOpened(true)}>
                  <EditIcon />
                </IconButton>
              </div>
            </CardContent>
            <CardContent className={classes.cardContent}>
              <div className={classes.imgBox}>
                <img className={classes.cardImg} src={ic_notif} alt="Notification" />
              </div>
              <div className={classes.cardText}>
                <Typography gutterBottom>
                  {isNotificationsSupported() && Notification.permission === "granted"
                    ? t("notificationsAlert.permissionGranted.title")
                    : t("notificationsAlert.permissionDefault.title")}
                </Typography>
                <Typography gutterBottom color="textSecondary">
                  {t("notificationsAlert.permissionDefault.body")}
                </Typography>
                <YellowButton onClick={notifButtonHandler} className={classes.btnMargin}>
                  {isNotificationsSupported() && Notification.permission === "granted"
                    ? t("notificationsAlert.unsubscribe")
                    : t("notificationsAlert.subscribe")}
                </YellowButton>
              </div>
            </CardContent>
          </Card>
          {userInfo.certificates && (
            <Card className={classes.card} elevation={4}>
              <CardContent className={classes.cardContent}>
                <div className={classes.imgBox}>
                  <img className={classes.cardImg} src={ic_certificate} alt="Сertificate" />
                  <span className={classes.label}>{userInfo.certificates.length}</span>
                </div>
                <div className={classes.certificateText}>
                  <Typography gutterBottom>{t("yourCertificates")}</Typography>
                  <div className={classes.certificateBtns}>
                    <IconButton className={classes.cardBtn} onClick={() => setCertDialogOpen(true)}>
                      <FormatListBulletedIcon />
                    </IconButton>
                  </div>
                </div>
              </CardContent>
            </Card>
          )}
          <StudentProgressStatistic />
        </Box>
        <Box className={classes.mainItem}>
          <Typography className={classes.title} align="center">
            {t("changeCourse")}
          </Typography>
          <Box className={classes.levelBtns}>
            {addedSubjects.map((subject_) => (
              <SubjectButton
                key={subject_.id}
                onClick={subjectButtonClick(subject_)}
                subject={subject_}
                selectedSubjectId={subjectId}
              />
            ))}
            {notAddedSubjects.length > 0 && (
              <Box marginTop="15px">
                <BaseAccordion
                  summary={<Typography>{t("more")}</Typography>}
                  summaryClassName={classes.more}
                  detailsClassName={classes.expandableList}
                >
                  <FlexboxVertical>
                    {notAddedSubjects.map((subject) => (
                      <SubjectButton
                        key={subject.id}
                        selectedSubjectId={subjectId}
                        subject={subject}
                        onClick={subjectButtonClick(subject)}
                      />
                    ))}
                  </FlexboxVertical>
                </BaseAccordion>
              </Box>
            )}
          </Box>
          <Box className={classes.offerCards}>
            <Grid container spacing={2} columns={{ md: 12 }}>
              {subject &&
                subject?.offers.map((offer, index) => (
                  <OfferCard key={index} offer={offer} user={userInfo} offerButtonHandler={offerButtonHandler} />
                ))}
            </Grid>
          </Box>
        </Box>
      </Box>
      {selectedSubject && (
        <Dialog
          fullWidth
          maxWidth={selectedSubject.available !== false ? "sm" : "md"}
          open={isDialogOpened}
          onClose={handleCloseDialog}
        >
          <DialogContent className={classes.dialogRoot}>
            <FlexboxVertical data-tag="subject dialog content root">
              <FlexboxHorizontal data-tag="subject dialog header" className={classes.dialogHeader}>
                <Typography className={classes.dialogHeaderText} variant="caption">
                  {selectedSubject.name}
                </Typography>
                {selectedSubject.id !== subject.subjectId && selectedSubject.available !== false && (
                  <YellowButton className={classes.yellowButton} onClick={yellowButtonHandler}>
                    {selectedSubject.price > 0 && !selectedSubject.purchased && (
                      <Typography>
                        {t("buySubject")}
                        <b>{`${selectedSubject.price} ${selectedSubject.currency.toUpperCase()}`}</b>
                      </Typography>
                    )}
                    {(selectedSubject.price === 0 || selectedSubject.purchased) && (
                      <Typography>{t("changeCourse")}</Typography>
                    )}
                  </YellowButton>
                )}
              </FlexboxHorizontal>
              <FlexboxVertical data-tag="subject dialog content" padding="20px">
                {selectedSubject.available === false ? (
                  <>
                    <Box className={classes.offerCards}>
                      <Grid container spacing={2} columns={{ md: 12 }}>
                        {selectedSubject &&
                          selectedSubject?.offers.map((offer, index) => (
                            <OfferCard
                              key={index}
                              offer={offer}
                              user={userInfo}
                              offerButtonHandler={offerButtonHandler}
                            />
                          ))}
                      </Grid>
                    </Box>
                  </>
                ) : (
                  <>
                    <div className={classes.temporaryLogoWrapper}>
                      <img
                        src={(
                          selectedSubject.illustrationImageUrl ||
                          selectedSubject.illustrationImage?.url ||
                          companyLogoUrl
                        )?.replaceAll("\\", "/")}
                        alt=""
                      />
                    </div>
                  </>
                )}

                <Typography variant="body1">{selectedSubject.description}</Typography>
              </FlexboxVertical>
            </FlexboxVertical>
          </DialogContent>
        </Dialog>
      )}
      <EditUserInfoDialog open={isUserEditDialogOpened} onClose={() => setUserEditDialogOpened(false)} />
      <CertificatesDialog
        open={isCertDialogOpen}
        onClose={() => setCertDialogOpen(false)}
        data={userInfo.certificates}
      />
    </>
  );
};

export default Profile;
