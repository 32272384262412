import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { useTranslate } from "react-polyglot";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import FlexboxVertical from "../../../SharedComponents/FlexboxVertical.jsx";
import { useHomeworks } from "../../../Providers/Data/HomeworksProvider.jsx";
import FlexboxHorizontal from "../../../SharedComponents/FlexboxHorizontal.jsx";
import { ReactComponent as Coffeebooks } from "../../../assets/ic_book&coffee.svg";
import { HOMEWORK_STATUS_COLORS } from "../../../constants.js";
import ColoredCircle from "../../../SharedComponents/ColoredCircle.jsx";
import { useLocale } from "../../../Providers/i18n/I18nProvider.jsx";
import { stripHtmlTags } from "../../../Utils/misc";

const useStyles = makeStyles((theme) => ({
  itemRoot: {
    cursor: "pointer",
    transitionProperty: "background",
    transitionDuration: theme.transitions.duration.standard,
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  itemContent: {
    position: "relative",
    padding: `20px 0`,
  },
  leftBlock: {
    margin: "0 20px",
    minWidth: 100,
    alignItems: "center",
    justifyContent: "center",
  },
  rightBlock: {
    "&>*:not(:first-child)": {
      marginTop: 10,
    },
  },
  icon: {
    width: 50,
    height: 50,
    "& path": {
      fill: theme.palette.primary.main,
    },
  },
  hr: {
    width: "100%",
    borderStyle: "solid",
    color: theme.palette.grey[300],
    margin: 0,
  },
  date: {
    color: theme.palette.grey[600],
    fontWeight: 500,
    marginTop: 5,
  },
  status: {
    position: "absolute",
    right: 5,
    bottom: 5,
    color: ({ status }) => HOMEWORK_STATUS_COLORS[status],
    alignItems: "center",
  },
  statusText: {
    fontWeight: 800,
    marginRight: 10,
  },
  cutText: {
    wordBreak: "break-word",
    fontWeight: 700,
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
  },
  bold: {
    fontWeight: 700,
  },
}));

/**
 * @param {FinishedHomework} finishedHomework
 */
const ListItem = ({ finishedHomework }) => {
  const classes = useStyles({ status: finishedHomework.status });
  const t = useTranslate();
  const { locale } = useLocale();
  const history = useHistory();

  /** */
  const itemClickHandler = () => {
    history.push(`/homeworks/finished/${finishedHomework.id}`);
  };

  return (
    <FlexboxVertical
      data-component="ListItem"
      position="relative"
      className={classes.itemRoot}
      onClick={itemClickHandler}
    >
      <FlexboxHorizontal className={classes.itemContent}>
        <FlexboxVertical className={classes.leftBlock}>
          <Coffeebooks className={classes.icon} alt="Coffee and books" />
          <Typography className={classes.date}>
            {new Date(finishedHomework.createdAt).toLocaleDateString(locale)}
          </Typography>
        </FlexboxVertical>
        <FlexboxVertical className={classes.rightBlock}>
          <Typography className={classes.cutText}>{stripHtmlTags(finishedHomework.data.question.text)}</Typography>
          {finishedHomework.type === "exercise" && (
            <>
              <Typography>
                <span className={classes.bold}>{t("homeworks.topic")}</span>&nbsp;&nbsp;
                <span>{finishedHomework.data.branch ? finishedHomework.data.branch.name : "-"}</span>
              </Typography>
              <Typography>
                <span className={classes.bold}>{t("homeworks.lesson")}</span>&nbsp;&nbsp;
                <span>{finishedHomework.data.deck ? finishedHomework.data.deck.name : "-"}</span>
              </Typography>
            </>
          )}
        </FlexboxVertical>
        <FlexboxHorizontal className={classes.status}>
          <Typography className={classes.statusText} color="inherit">
            {t(`status.${finishedHomework.status}`)}
          </Typography>
          <ColoredCircle color={HOMEWORK_STATUS_COLORS[finishedHomework.status]} size={20} inline />
        </FlexboxHorizontal>
      </FlexboxHorizontal>
      <hr className={classes.hr} />
    </FlexboxVertical>
  );
};

ListItem.propTypes = {
  finishedHomework: PropTypes.object.isRequired,
};

/** */
const FinishedList = () => {
  const { finishedHomeworks, showMoreFinishedHW } = useHomeworks();
  const t = useTranslate();

  return (
    <FlexboxVertical data-component="FinishedList">
      {finishedHomeworks.map((homework) => (
        <ListItem key={homework.updatedAt} finishedHomework={homework} />
      ))}
      {finishedHomeworks.length >= 10 && (
        <Button variant="contained" color="primary" size="small" fullWidth onClick={showMoreFinishedHW}>
          {t("showMore")}
        </Button>
      )}
    </FlexboxVertical>
  );
};

export default FinishedList;
