import { useEffect } from "react";
import { useTranslate } from "react-polyglot";
import useCompanyPublicId from "./useCompanyPublicId.js";
import { IS_APP_STUDYLESS } from "../constants.js";

/**
 * @param {String} text
 * @param {Object} [args]
 */
const useTitle = (text, args) => {
  const t = useTranslate();
  const { companyName, domain } = useCompanyPublicId();

  useEffect(() => {
    let args_ = args;
    if (!companyName && !domain && !IS_APP_STUDYLESS) {
      if (args_ && args_.school) {
        args_.school = `Skillzrun - ${args_.school}`;
      } else {
        args_ = { school: "Skillzrun" };
      }
    }
    document.title = t(text, args_);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, args]);
};

export default useTitle;
