import PropTypes from "prop-types";
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslate } from "react-polyglot";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles, withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import yellow from "@material-ui/core/colors/yellow";
import FlatGreyButton from "../../../../SharedComponents/FlatGreyButton.jsx";
import FlatPrimaryButton from "../../../../SharedComponents/FlatPrimaryButton.jsx";
import FlexboxVertical from "../../../../SharedComponents/FlexboxVertical.jsx";
import FlexboxHorizontal from "../../../../SharedComponents/FlexboxHorizontal.jsx";
import icQuestion from "../../../../assets/ic_exam_info_exercises.svg";
import icOne from "../../../../assets/ic_exam_info_one_try.svg";
import icTime from "../../../../assets/ic_time.svg";
import icExclamation from "../../../../assets/ic_exam_info_pause.svg";

export const Title = withStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: 4,
    "& .MuiTypography-root": {
      fontWeight: 900,
      textTransform: "uppercase",
    },
  },
}))(DialogTitle);

export const Content = withStyles(() => ({
  root: {
    padding: "8px 34px 24px",
  },
}))(DialogContent);

export const Actions = withStyles(() => ({
  root: {
    padding: 0,
    "&>.MuiButtonBase-root": {
      width: "50%",
    },
  },
  spacing: {
    "&>:not(:first-child)": {
      margin: 0,
    },
  },
}))(DialogActions);

const useStyles = makeStyles(() => ({
  deckName: {
    alignSelf: "center",
    marginBottom: 6,
    fontSize: 18,
  },
  examDescription: {
    marginBottom: 16,
    fontSize: 18,
  },
  examInfo: {
    "& img": {
      width: 24,
      height: 24,
      marginRight: 12,
    },
    "& .MuiTypography-body1": {
      fontWeight: 500,
    },
  },
  infoItem: {
    "&:not(:first-child)": {
      marginTop: 4,
    },
  },
  bold: {
    fontWeight: 700,
    backgroundColor: yellow[600],
  },
}));

/**
 * @callback ExamPopup.OnClose
 * @param {Boolean} positive
 * @returns {void}
 */

/**
 * @param {Boolean} open
 * @param {ExamPopup.OnClose} onClose
 * @param {Deck} deck
 */
const ExamPopup = ({ open, onClose, deck }) => {
  const classes = useStyles();
  const t = useTranslate();

  if (!deck.exam) {
    return null;
  }

  /** */
  const cancel = () => {
    onClose(false);
  };

  const items = [
    {
      key: 1,
      icon: icQuestion,
      text: t("exam.questions", { smart_count: deck.exercises.count }),
    },
    {
      key: 2,
      icon: icOne,
      text: t("exam.oneAttempt"),
    },
    {
      key: 3,
      icon: icTime,
      text: t("exam.timeLimit", { limit: deck.exam.timeLimit }),
    },
    {
      key: 4,
      icon: icExclamation,
      text: t("exam.noPause"),
    },
  ];

  return (
    <Dialog open={open} onClose={cancel} fullWidth maxWidth="sm">
      <Title>{t("exam.popupTitle")}</Title>
      <Content>
        <FlexboxVertical className={classes.examInfo}>
          <Typography className={classes.deckName}>{deck.name}</Typography>
          <Typography className={classes.examDescription}>{t("exam.popupDescr1")}&nbsp;</Typography>
          <Typography className={classes.examDescription}>
            <span className={classes.bold}>{t("exam.popupDescr2")}</span>
            &nbsp;{t("exam.popupDescr3")}
          </Typography>
          {items.map((item) => (
            <FlexboxHorizontal key={item.key} className={classes.infoItem}>
              <img src={item.icon} alt="" />
              <Typography>{item.text}</Typography>
            </FlexboxHorizontal>
          ))}
        </FlexboxVertical>
      </Content>
      <Actions>
        <FlatGreyButton onClick={cancel}>{t("exam.cancelButton")}</FlatGreyButton>
        <FlatPrimaryButton onClick={() => onClose(true)}>{t("exam.positiveButton")}</FlatPrimaryButton>
      </Actions>
    </Dialog>
  );
};

ExamPopup.propTypes = {
  deck: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ExamPopup;
