import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslate } from "react-polyglot";
import { Box, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DoneRoundedIcon from "@material-ui/icons/DoneRounded";
import Webcam from "react-webcam";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CameraRoundedIcon from "@material-ui/icons/CameraRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import Fade from "@material-ui/core/Fade";
import ErrorText from "../../../../../SharedComponents/ErrorText.jsx";

const useDialogStyles = makeStyles(() => ({
  dialogContent: {
    position: "relative",
  },
  initString: {
    position: "absolute",
    top: 95,
    left: 20,
    right: 20,
    textAlign: "center",
  },
  cameraButtons: {
    backgroundColor: "rgba(0,0,0,0.5)",
    position: "absolute",
    left: 24,
    right: 24,
    bottom: 13,
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  snapshotButton: {
    color: "white",
    borderRadius: "50%",
    "& svg": {
      width: 70,
      height: 70,
    },
  },
}));

/**
 * @param {Function} onClose
 * @param {Function} onCaptured
 * @param {Object} rest
 * @returns {*}
 * @constructor
 */
const WebCamDialog = ({ onClose, onCaptured, ...rest }) => {
  const classes = useDialogStyles();
  const t = useTranslate();
  const webcamRef = useRef(null);
  const contentRef = useRef(/** @type {HTMLElement} */ null);
  const [isCameraInitialized, setCameraInitialized] = useState(false);
  const [cameraError, setCameraError] = useState(null);
  const [cameraScreenshot, setCameraScreenshot] = useState(null);
  const [contentSize, setContentSize] = useState({ w: 0, h: 0 });

  /** */
  const cameraInitializationHandler = () => {
    setCameraInitialized(true);
  };

  /** @param {DOMException} error */
  const cameraInitializationFailedHandler = (error) => {
    setCameraError(error.message);
  };

  /** */
  const takePhoto = () => {
    const rect = contentRef.current.getBoundingClientRect();
    setContentSize({ w: rect.width, h: rect.height });
    setCameraScreenshot(webcamRef.current.getScreenshot());
  };

  /** */
  const resetCapturing = () => {
    setCameraInitialized(false);
    setCameraError(null);
    setCameraScreenshot(null);
  };

  /** */
  const handleClose = () => {
    setTimeout(() => {
      resetCapturing();
    }, 400);
    onClose && onClose();
  };

  /** */
  const done = () => {
    onCaptured && onCaptured(cameraScreenshot);
    handleClose();
  };

  return (
    <Dialog {...rest} onClose={handleClose} maxWidth="md">
      <DialogContent
        ref={contentRef}
        className={classes.dialogContent}
        style={{ width: contentSize.w || undefined, height: contentSize.h || undefined }}
      >
        {!cameraScreenshot && (
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={720}
            height={540}
            onUserMedia={cameraInitializationHandler}
            onUserMediaError={cameraInitializationFailedHandler}
          />
        )}
        <Fade in={isCameraInitialized && !cameraScreenshot} timeout={{ enter: 0, exit: 700 }}>
          <Box className={classes.cameraButtons}>
            <Button className={classes.snapshotButton} onClick={takePhoto}>
              <CameraRoundedIcon />
            </Button>
          </Box>
        </Fade>
        {cameraScreenshot && <img src={cameraScreenshot} alt="" />}
        {!isCameraInitialized && !cameraError && (
          <Typography className={classes.initString}>{t("Exercises.type7.cameraInitialization")}</Typography>
        )}
        {cameraError && <ErrorText className={classes.initString}>{cameraError}</ErrorText>}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" disabled={!cameraScreenshot} onClick={resetCapturing}>
          <CloseRoundedIcon />
        </Button>
        <Button color="secondary" variant="contained" disabled={!cameraScreenshot} onClick={done}>
          <DoneRoundedIcon />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

WebCamDialog.propTypes = {
  onClose: PropTypes.func,
  onCaptured: PropTypes.func,
};

export default WebCamDialog;
