import React from "react";
import { makeStyles, TableCell, TableRow, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslate } from "react-polyglot";
import useDateUtils from "../../../hooks/useDateUtils.js";
import { ReactComponent as School } from "../../../assets/ic_school.svg";
import { ReactComponent as Laptop } from "../../../assets/ic_laptop.svg";
import FlexboxVertical from "../../../SharedComponents/FlexboxVertical.jsx";
import useExtractRouteParamInt from "../../../hooks/useExtractRouteParamInt.js";

const useStyles = makeStyles((theme) => ({
  tableRow: {
    cursor: "pointer",
    transitionProperty: "background-color",
    transitionDuration: 300,
  },
  eventInterested: {
    backgroundColor: "#e5f6de",
  },
  eventSelected: {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    "& td": {
      fontWeight: 700,
    },
  },
  tableCol: {
    color: theme.palette.secondary.dark,
  },
  eventLeader: {
    marginTop: 8,
  },
  eventStatusBlock: {
    maxWidth: 100,
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  icon: {
    width: 50,
    height: 50,
    "& path": {
      fill: theme.palette.primary.main,
    },
  },
  eventStatus: {
    textTransform: "uppercase",
    marginTop: 6,
  },
  checkIcon: {
    position: "absolute",
    top: 0,
    right: 10,
    color: "#EB4893",
    backgroundColor: "#e5f6de",
    borderRadius: "50%",
  },
}));

/** */
const EventRow = ({ event }) => {
  const eventId = useExtractRouteParamInt("/events/:eventId", "eventId");
  const classes = useStyles();
  const history = useHistory();
  const t = useTranslate();
  const { format } = useDateUtils();
  const date = new Date(event.date);

  /**
   * @param {Number} id
   * @returns {Function}
   */
  const onEventClick = (id) => () => {
    history.push(`/events/${id}`);
  };

  return (
    <TableRow
      className={clsx(classes.tableRow, {
        [classes.eventInterested]: event.interested,
        [classes.eventSelected]: eventId === event.id,
      })}
      onClick={onEventClick(event.id)}
      hover
    >
      <TableCell className={classes.tableCol} align="center">
        <b>{format(date, "EEEEEE")}</b> <br /> <b>{format(date, "dd")}</b>
      </TableCell>
      <TableCell className={classes.tableCol}>
        {event.name}
        {event.leader && (
          <Typography variant="body2" className={classes.eventLeader}>
            {t("Events.leader")}: {event.leader.name}
          </Typography>
        )}
      </TableCell>
      <TableCell className={classes.tableCol} align="center">
        {format(date, "HH:mm")}
      </TableCell>
      <TableCell align="center">
        <FlexboxVertical className={classes.eventStatusBlock}>
          {event.online ? (
            <Laptop className={classes.icon} alt="laptop icon" />
          ) : (
            <School className={classes.icon} alt="building icon" />
          )}
          {event.interested && <CheckCircleRoundedIcon className={classes.checkIcon} />}
          <Typography className={classes.eventStatus} variant="body2" color="primary">
            {event.online ? "online" : "offline"}
          </Typography>
        </FlexboxVertical>
      </TableCell>
    </TableRow>
  );
};

EventRow.propTypes = {
  event: PropTypes.object,
};

export default EventRow;
