import React from "react";
import PropTypes from "prop-types";
import { useTranslate } from "react-polyglot";
import { Box, makeStyles, Typography, Dialog, DialogContent, DialogTitle, Chip } from "@material-ui/core";
import { TextField } from "mui-rff";
import ReactFinalForm from "../ReactFinalForm.jsx";
import { useCourseProvider } from "../../Providers/Data/CourseProvider.jsx";
import useDateUtils from "../../hooks/useDateUtils.js";
import FeedbackDialogActions from "./FeedbackDialogActions.jsx";
import FeedbackDialogSlider from "./FeedbackDialogSlider.jsx";
import FlexboxHorizontal from "../FlexboxHorizontal.jsx";

const useStyles = makeStyles({
  instructionText: {
    textAlign: "center",
    fontWeight: 500,
  },
  defaultInstruction: {
    fontSize: 12,
    textAlign: "center",
  },
  content: {
    padding: 15,
  },
  dateBox: {
    fontSize: 12,
    marginRight: 5,
  },
  date: {
    fontSize: 12,
    fontWeight: 600,
    marginRight: 5,
  },
  boxLeader: {
    fontSize: 16,
  },
});

const useDialogStyles = makeStyles({
  root: {
    "& .MuiDialogTitle-root": {
      width: "100%",
      backgroundColor: "#F8F9FE",
      marginTop: 15,
      marginBottom: 15,
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F8F9FE",
      borderRadius: 12,
    },
  },
});

/**
 * @param {EventToProvideReview} data
 * @param {string} instructionText
 * @param {'event'|'deck'} resource
 * @param {function} onClose
 * @param {boolean} open
 */
const FeedbackDialog = ({ data, instructionText, resource, onClose, open }) => {
  const classes = useStyles();
  const dialogClasses = useDialogStyles();
  const { postUserReviews } = useCourseProvider();
  const t = useTranslate();
  const { format } = useDateUtils();

  /** @param {FeedbackDataForm} data
   * @param form
   */
  const onSubmit = async ({ rating, text }, form) => {
    const eventId = resource === "event" ? data.id : null;
    const deckId = resource === "deck" ? data.id : null;
    const postData = { eventId, deckId, reviewOf: resource, text, rating };

    await postUserReviews(postData);
    form.reset();
    onClose();
  };

  /** */
  const onCloseDialog = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} maxWidth="sm" fullWidth classes={dialogClasses} disableEscapeKeyDown>
      <ReactFinalForm
        onSubmit={onSubmit}
        initialValues={{
          rating: 0,
          text: "",
        }}
      >
        <Box width="80%" mr="auto" ml="auto">
          <DialogTitle>
            <FlexboxHorizontal justifyContent="space-between" alignItems="center">
              {data.date && (
                <FlexboxHorizontal justifyContent="start" alignItems="center">
                  <Typography className={classes.dateBox} color="inherit">
                    {t("feedbackDialog.dateOfEvent")}
                  </Typography>
                  <Typography className={classes.date} color="inherit">
                    {format(data.date, "dd.MM.yyyy HH:mm")}
                  </Typography>
                </FlexboxHorizontal>
              )}
              <FlexboxHorizontal justifyContent="end" alignItems="center">
                {data.tags.length > 0 &&
                  data.tags.map((tag) => (
                    <Chip className={classes.dateBox} key={tag.id} size="small" label={tag.name} />
                  ))}
              </FlexboxHorizontal>
            </FlexboxHorizontal>
            {data.name}
            {data.leader && (
              <Typography className={classes.boxLeader} color="inherit">
                {data.leader.name}
              </Typography>
            )}
          </DialogTitle>
          <DialogContent className={classes.content}>
            {instructionText && (
              <Typography className={classes.instructionText} color="inherit">
                {instructionText}
              </Typography>
            )}
            <Typography className={classes.defaultInstruction} color="inherit">
              {t("feedbackDialog.defaultInstruction")}
            </Typography>
            <FeedbackDialogSlider />
            <TextField
              placeholder={t("feedbackDialog.placeholder")}
              name="text"
              variant="outlined"
              multiline={true}
              rows={4}
              rowsMax={6}
              fullWidth
            />
          </DialogContent>
          <FeedbackDialogActions id={data.id} resource={resource} onClose={onClose} />
        </Box>
      </ReactFinalForm>
    </Dialog>
  );
};

FeedbackDialog.propTypes = {
  data: PropTypes.any.isRequired,
  instructionText: PropTypes.string,
  resource: PropTypes.oneOf(["event", "deck"]).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default FeedbackDialog;
