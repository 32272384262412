import React from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import clsx from "clsx";
import Select from "@material-ui/core/Select";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import MenuItem from "@material-ui/core/MenuItem";
import useSelectInputStyles from "./UseSelectInputStyles.jsx";

/**
 * @param {SimpleIdNameObject[]} values
 * @param {(Number|String)} value
 * @param {Function} onChange
 * @param {String} label
 * @param {String} className
 * @param {Object} rest
 * @return {React.ReactNode}
 */
const ObjectSelectInput = ({ values, value, onChange, label, className, ...rest }) => {
  const classes = useSelectInputStyles();

  return (
    <FormControl {...rest} className={clsx(className, classes.root)} size="small">
      <Select
        value={value}
        onChange={onChange}
        displayEmpty
        IconComponent={ExpandLessIcon}
        renderValue={(val) => (val === "" ? label : values.find((v) => v.id === val).name)}
      >
        <MenuItem value="">{" "}</MenuItem>
        {values.map((v) => (
          <MenuItem key={v.id} value={v.id}>
            {v.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

ObjectSelectInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  values: PropTypes.arrayOf(PropTypes.object),
};

export default ObjectSelectInput;
