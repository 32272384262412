import { LinearProgress, withStyles } from "@material-ui/core";

const LinearProgressBar = withStyles({
  root: {
    height: (props) => (props.height ? props.height : 20),
    borderRadius: 10,
    minWidth: 40,
  },
  colorPrimary: {
    backgroundColor: "white",
    border: (props) => (props.noborder ? "none" : "1px solid #DDE1EB"),
  },
  bar: {
    borderRadius: (props) => (props.radius ? props.radius : 10),
  },
})(LinearProgress);

export default LinearProgressBar;
