import { API_URL, IS_APP_STUDYLESS } from "../constants.js";

/**
 * @param {Response} resp
 * @returns {Promise<{data: null, error: {code: Number, text: string, body: Object}}|{data: Object, error: null}>}
 */
async function parseResponse(resp) {
  if (resp.ok) {
    const contentType = resp.headers.get("Content-Type");
    if (!contentType || contentType.contains("application/json")) {
      return {
        data: await resp.json(),
        error: null,
      };
    } else {
      return { data: await resp.text(), error: null };
    }
  } else {
    const response = {
      data: null,
      error: {
        code: resp.status,
        text: resp.statusText,
        body: await resp.json(),
      },
    };
    if (resp.status >= 400 && resp.status < 500) {
      response.error.text = response.error.body.message;
    }
    return response;
  }
}

/**
 * @param {String} locale
 * @param {Object} headers
 * @param {Boolean} [isFormData]
 * @returns {HeadersInit}
 */
const makeHeaders = (locale, headers, isFormData) => {
  const out = new Headers({
    Accept: "application/json",
    device: "Browser/WebApp/1/0.0.1/1.0.12",
    "company-id": IS_APP_STUDYLESS ? "1772" : "-1",
    locale,
    timezoneoffset: -new Date(Date.now()).getTimezoneOffset() * 60 * 1000,
    ...headers,
  });
  if (!isFormData) {
    out.append("Content-Type", "application/json");
  }
  return out;
};

/**
 * @param {String} url
 * @param {String} locale
 * @param {Object} headers
 * @returns {Promise<{data: null, error: {code: Number, text: string, body: Object}}|{data: Object, error: null}>}
 */
export async function get(url, locale, headers = {}) {
  const resp = await fetch(`${API_URL}/${url}`, {
    method: "GET",
    headers: makeHeaders(locale, headers),
  });
  return await parseResponse(resp);
}

/**
 * @param {String} url
 * @param {String} locale
 * @param {Object} postData
 * @param {Object} headers
 * @returns {Promise<{data: null, error: {code: Number, text: string, body: Object}}|{data: Object, error: null}>}
 */
export async function post(url, locale, postData, headers = {}) {
  const isFormData = postData instanceof FormData;
  const resp = await fetch(`${API_URL}/${url}`, {
    method: "POST",
    body: isFormData ? postData : JSON.stringify(postData),
    headers: makeHeaders(locale, headers, isFormData),
  });
  return await parseResponse(resp);
}

/**
 * @param {String} url
 * @param {String} locale
 * @param {Object} postData
 * @param {Object} headers
 * @returns {Promise<{data: null, error: {code: Number, text: string, body: Object}}|{data: Object, error: null}>}
 */
export async function patch(url, locale, postData, headers = {}) {
  const resp = await fetch(`${API_URL}/${url}`, {
    method: "PATCH",
    body: JSON.stringify(postData),
    headers: makeHeaders(locale, headers),
  });
  return await parseResponse(resp);
}

/**
 * @param {String} url
 * @param {String} locale
 * @param {Object} postData
 * @param {Object} headers
 * @returns {Promise<{data: null, error: {code: Number, text: string, body: Object}}|{data: Object, error: null}>}
 */
export async function put(url, locale, postData, headers = {}) {
  const resp = await fetch(`${API_URL}/${url}`, {
    method: "PUT",
    body: JSON.stringify(postData),
    headers: makeHeaders(locale, headers),
  });
  return await parseResponse(resp);
}
