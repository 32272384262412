import PropTypes from "prop-types";
import React, { createContext, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslate } from "react-polyglot";
import DialogContentText from "@material-ui/core/DialogContentText";
import ExamPopup from "../../Pages/Home/Learning/SharedComponents/ExamPopup.jsx";
import { useDialogs } from "../Common/DialogsProvider.jsx";
import ExamResultPopup from "../../Pages/Home/Learning/Exercises/SharedComponents/ExamResultPopup.jsx";
import { useBaseData } from "./BaseDataProvider.jsx";
import useDateUtils from "../../hooks/useDateUtils.js";

/**
 * @typedef {Object} DeckDataContext
 * @property {?Deck} deck
 * @property {?Branch} branch
 * @property {String|undefined} progressValue
 * @property {Function} onCardClick
 */

/** @type {React.Context<DeckDataContext>} */
const deckContext = createContext({
  deck: null,
  branch: null,
  onCardClick: () => {},
});

/** @returns {DeckDataContext} */
export const useDeckData = () => useContext(deckContext);

/**
 * @param {React.ReactNode} children
 * @param {Deck} deck
 * @param {Branch} branch
 * @returns {React.ReactNode}
 * @constructor
 */
const DeckDataProvider = ({ children, deck, branch }) => {
  const history = useHistory();
  const t = useTranslate();
  const { alert } = useDialogs();
  const { userInfo } = useBaseData();
  const [isExamPopupOpened, setExamPopupOpened] = useState(false);
  const [isExamResultPopupOpened, setExamResultPopupOpened] = useState(false);
  const { format } = useDateUtils();

  /** @type {ExamPopup.OnClose} */
  const examPopupCloseHandler = (positive) => {
    setExamPopupOpened(false);
    if (positive) {
      history.push(`/learn/${deck.id}`);
    }
  };

  /** @type {ExamPopup.OnClose} */
  const examResultPopupCloseHandler = (positive) => {
    setExamResultPopupOpened(false);
    if (positive) {
      history.push(`/learn/${deck.id}`);
    }
  };

  /** */
  const onCardClick = () => {
    if (branch.open && deck.open && (userInfo.isActive || userInfo.isDemoMode)) {
      //if (deck.exam && !deck.exam.startDate && deck.exercises.count > 0) {
      if (deck.exam && !deck.exam.startDate && deck.exercises.items.length > 0) {
        setExamPopupOpened(true);
      } else if (deck.exam && deck.exam.endDate) {
        setExamResultPopupOpened(true);
      } else {
        history.push(`/learn/${deck.id}`);
      }
    } else if (!deck.open || !(userInfo.isActive || userInfo.isDemoMode)) {
      let message = t("decksItemNotOpen");
      let content = null;
      if (!(userInfo.isActive || userInfo.isDemoMode)) {
        message = t("youAreDeactivated");
      } else if (branch.levelClosedReason === "accessControl") {
        message = t("levelAccessIsDenied");
      } else if (deck.closedReason === "accessControl") {
        message = t("deckAccessDenied");
        content = deck.openOnDate ? (
          <DialogContentText style={{ marginTop: 15 }}>
            {t("openOnDate", { date: format(deck.openOnDate, "dd/MM/yyyy") })}
          </DialogContentText>
        ) : null;
      } else if (branch.price > 0 && !branch.purchased) {
        message = t("decksItemNotPurchasedBranch");
      } else if (!branch.levelOpen) {
        message = t("decksItemNotOpenLevel");
      } else if (!branch.open) {
        message = t("decksItemNotOpenBranch");
      }
      alert({
        title: deck.name,
        message,
        content,
      });
    }
  };

  const progressValue = deck.exam ? `${deck.exam.points}/${deck.exam.totalPoints}` : undefined;

  const value = {
    deck,
    branch,
    progressValue,
    onCardClick,
  };

  return (
    <deckContext.Provider value={value}>
      {children}
      {deck.exam && <ExamPopup onClose={examPopupCloseHandler} open={isExamPopupOpened} deck={deck} />}
      {deck.exam && (
        <ExamResultPopup onClose={examResultPopupCloseHandler} open={isExamResultPopupOpened} exam={deck.exam} />
      )}
    </deckContext.Provider>
  );
};

DeckDataProvider.propTypes = {
  branch: PropTypes.object.isRequired,
  children: PropTypes.any,
  deck: PropTypes.object.isRequired,
};

export default DeckDataProvider;
