import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const ProgressTypography = withStyles({
  root: {
    fontWeight: 600,
    fontSize: 24,
  },
})(Typography);

export default ProgressTypography;
