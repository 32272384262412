import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import Zoom from "@material-ui/core/Zoom";
import RecordRTCPromisesHandler from "recordrtc";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded.js";
import Fade from "@material-ui/core/Fade";
import MicRoundedIcon from "@material-ui/icons/MicRounded.js";
import StopRoundedIcon from "@material-ui/icons/StopRounded.js";
import PlayArrowRoundedIcon from "@material-ui/icons/PlayArrowRounded.js";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { useTranslate } from "react-polyglot";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";
import { IS_APP_STUDYLESS } from "../../../../../constants.js";
import PrimaryFab from "../../../../../SharedComponents/PrimaryFab.jsx";
import RedFab from "../../../../../SharedComponents/RedFab.jsx";
import TransparentButton from "../../../../../SharedComponents/TransparentButton.jsx";
import FlexboxHorizontal from "../../../../../SharedComponents/FlexboxHorizontal.jsx";
import PrimaryDarkTypography from "../../../../../SharedComponents/PrimaryDarkTypography.jsx";
import { useDialogs } from "../../../../../Providers/Common/DialogsProvider.jsx";
import useUnmount from "../../../../../hooks/useUnmount.js";
import { formatTime, parseTime } from "../../../../../Utils/misc.js";

const totalTime_ = IS_APP_STUDYLESS ? "03:00" : "15:00";

/**
 * @typedef {{resumeRecording: resumeRecording, setRecordingDuration: (function(any=, any=): {onRecordingStopped: onRecordingStopped}), toURL: (function(): string), onStateChanged: RecordRTC.onStateChanged, pauseRecording: pauseRecording, save: RecordRTC.save, destroy: RecordRTC.destroy, sampleRate: number, version: string, getBlob: (function(): any), blob: null, ...}} RTCRecorder
 */

/**
 * @typedef {('idle'|'recording'|'recorded'|'playing'|'playbackPaused')} RecorderState
 */

const useStyles = makeStyles((theme) => ({
  recorderRoot: {
    backgroundColor: "white",
    alignSelf: "stretch",
    padding: 20,
    alignItems: "center",
    justifyContent: "center",
  },
  deleteButtonWrapper: {
    marginRight: theme.spacing(3),
    width: 65,
    height: 65,
  },
  timeWrapper: {
    marginLeft: theme.spacing(4),
    width: 90,
    display: "inline-flex",
    justifyContent: "space-between",
  },
  timer: {
    width: 36,
  },
  recordButtons: {
    width: 65,
    height: 65,
    position: "relative",
    "&>*": {
      position: "absolute",
      top: 0,
      left: 0,
    },
  },
}));

/**
 * @param {String} className
 * @param {Function} onAudioChanged
 * @param {ExerciseType7Legacy|ExerciseType7Answer} answer
 * @param {Boolean} isAllowedToEdit
 */
const AudioRecorder = ({ className, onAudioChanged, answer, isAllowedToEdit }) => {
  const classes = useStyles();
  const t = useTranslate();
  const snackbar = useDialogs();
  const [recorderState, setRecorderState] = useState(/** @type {RecorderState} */ "idle");
  const [audioSrc, setAudioSrc] = useState(null);
  const [totalTime, setTotalTime] = useState(totalTime_);
  const [elapsedTime, setElapsedTime] = useState("00:00");
  const [playbackProgress, setPlaybackProgress] = useState(0);
  const audioStreamRef = useRef(/** @type {?MediaStream} */ null);
  const recorderRef = useRef(/** @type {?RTCRecorder} */ null);
  const recordDataRef = useRef(/** @type {?Blob} */ null);
  const audioRef = useRef(/** @type {HTMLAudioElement} */ null);
  const recordStartTimestampRef = useRef(0);
  const recordTimerHandleRef = useRef(0);
  const autoStopRecordHandleRef = useRef(0);

  const homeworkRecordUrl = _.get(answer, "homework.userData.audio.url");
  const transitionDuration = {
    enter: 700,
    exit: 700,
  };

  /** */
  const doReset = () => {
    audioStreamRef.current = null;
    recorderRef.current = null;
    recordDataRef.current = null;
    setAudioSrc(null);
    onAudioChanged && onAudioChanged(null);
  };

  // reset
  useEffect(() => {
    doReset();
    if (answer) {
      if (homeworkRecordUrl) {
        setAudioSrc(homeworkRecordUrl);
        setRecorderState("playbackPaused");
        setTotalTime("00:00");
      } else {
        setRecorderState("idle");
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer]);

  // observing the ending of playback
  useEffect(() => {
    if (!audioRef.current) {
      return;
    }
    const player = audioRef.current;
    /** */
    const endPlayingHandler = () => {
      setRecorderState("playbackPaused");
      setTimeout(() => setPlaybackProgress(0), 300);
    };
    player.addEventListener("ended", endPlayingHandler);

    return () => {
      player.removeEventListener("ended", endPlayingHandler);
    };
  }, [audioSrc]);

  // timer maintenance
  useEffect(() => {
    if (recorderState === "recording") {
      recordStartTimestampRef.current = Date.now();
      recordTimerHandleRef.current = setInterval(() => {
        const diff = (Date.now() - recordStartTimestampRef.current) / 1000;
        setElapsedTime(formatTime(diff));
      }, 1000);
      setElapsedTime("00:00");
      setTotalTime(totalTime_);
    }
    if (recorderState === "recorded" && recordStartTimestampRef.current > 0) {
      clearInterval(recordTimerHandleRef.current);
      recordTimerHandleRef.current = 0;
      const diff = (Date.now() - recordStartTimestampRef.current) / 1000;
      recordStartTimestampRef.current = 0;
      setRecorderState("playbackPaused");
      setTotalTime(formatTime(diff));
    }
    if (recorderState === "playing" && audioRef.current) {
      const player = audioRef.current;
      /** */
      const playingProgressHandler = () => {
        const length = player.duration === Infinity ? parseTime(totalTime) : player.duration;
        const currentTime = player.currentTime;
        setPlaybackProgress((currentTime / length) * 100);
        setElapsedTime(formatTime(currentTime));
      };

      player.addEventListener("timeupdate", playingProgressHandler);

      return () => {
        player.removeEventListener("timeupdate", playingProgressHandler);
      };
    }
    if (recorderState === "playbackPaused") {
      setElapsedTime("00:00");
      if (
        homeworkRecordUrl &&
        audioRef.current &&
        (audioRef.current.duration === Infinity || isNaN(audioRef.current.duration))
      ) {
        setTotalTime("00:00");
      }
    }
    if (recorderState === "idle") {
      setElapsedTime("00:00");
      setTotalTime(totalTime_);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recorderState]);

  /** */
  const startRecord = async () => {
    setRecorderState("idle");
    try {
      audioStreamRef.current = await navigator.mediaDevices.getUserMedia({ audio: true });

      setRecorderState("recording");
      const rec = new RecordRTCPromisesHandler(audioStreamRef.current, { type: "audio", mimeType: "audio/webm" });
      recorderRef.current = rec;
      await rec.startRecording();
      autoStopRecordHandleRef.current = setTimeout(() => {
        recordButtonHandler("recorded")();
      }, (IS_APP_STUDYLESS ? 3 : 15) * 60 * 1000);
    } catch (e) {
      snackbar.error(t("Exercises.type7.audioRecordingError") + e);
    }
  };

  /** @param {Boolean} keepResult */
  const stopRecord = (keepResult) => {
    if (!recorderRef.current) {
      return;
    }
    clearTimeout(autoStopRecordHandleRef.current);
    autoStopRecordHandleRef.current = 0;
    recorderRef.current.stopRecording(() => {
      if (keepResult) {
        const blob = recorderRef.current.getBlob();
        recordDataRef.current = blob;
        setAudioSrc(URL.createObjectURL(blob));
        onAudioChanged && onAudioChanged(blob);
      }
      audioStreamRef.current.getTracks().forEach((track) => track.stop());
    });
  };

  /**
   * @param {RecorderState} state
   * @returns {function(...[*]=)}
   */
  const recordButtonHandler = (state) => () => {
    setRecorderState(state);
    switch (state) {
      case "idle":
        doReset();
        break;
      case "recording":
        startRecord().then();
        break;
      case "recorded":
        stopRecord(true);
        break;
      case "playing":
        audioRef.current.play().then();
        break;
      case "playbackPaused":
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setPlaybackProgress(0);
        break;
    }
  };

  /** @type {Number} duration */
  const audioDurationChangeHandler = ({ target: { duration } }) => {
    if (duration !== Infinity) {
      setTotalTime(formatTime(duration));
    }
  };

  useUnmount(() => {
    stopRecord(false);
  });

  return (
    <>
      <FlexboxHorizontal className={clsx(classes.recorderRoot, className)}>
        <Box className={classes.deleteButtonWrapper}>
          {isAllowedToEdit && (
            <Zoom in={recorderState === "playing" || recorderState === "playbackPaused"} timeout={300}>
              <TransparentButton onClick={recordButtonHandler("idle")}>
                <DeleteForeverRoundedIcon />
              </TransparentButton>
            </Zoom>
          )}
        </Box>
        <Box className={classes.recordButtons}>
          {(recorderState === "playbackPaused" || recorderState === "playing") && (
            <CircularProgress
              variant="determinate"
              thickness={4}
              size={90}
              value={playbackProgress}
              style={{ top: -12, left: -13 }}
            />
          )}
          <Fade in={recorderState === "idle"} timeout={transitionDuration} unmountOnExit>
            <Box>
              <RedFab onClick={recordButtonHandler("recording")}>
                <MicRoundedIcon style={{ width: 20, height: 20 }} />
              </RedFab>
            </Box>
          </Fade>
          <Fade in={recorderState === "recording"} timeout={transitionDuration} unmountOnExit>
            <Box>
              <RedFab onClick={recordButtonHandler("recorded")}>
                <StopRoundedIcon />
              </RedFab>
            </Box>
          </Fade>
          <Fade in={recorderState === "playbackPaused"} timeout={transitionDuration} unmountOnExit>
            <Box>
              <PrimaryFab onClick={recordButtonHandler("playing")}>
                <PlayArrowRoundedIcon />
              </PrimaryFab>
            </Box>
          </Fade>
          <Fade in={recorderState === "playing"} timeout={transitionDuration} unmountOnExit>
            <Box>
              <PrimaryFab onClick={recordButtonHandler("playbackPaused")}>
                <StopRoundedIcon />
              </PrimaryFab>
            </Box>
          </Fade>
        </Box>
        <Box className={classes.timeWrapper}>
          <Typography className={classes.timer} variant="caption" color="textSecondary">
            {elapsedTime}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            /
          </Typography>
          <PrimaryDarkTypography className={classes.timer} variant="caption">
            {totalTime}
          </PrimaryDarkTypography>
        </Box>
      </FlexboxHorizontal>
      {audioSrc && <audio ref={audioRef} src={audioSrc} onDurationChange={audioDurationChangeHandler} />}
    </>
  );
};

AudioRecorder.propTypes = {
  className: PropTypes.string,
  answer: PropTypes.object,
  isAllowedToEdit: PropTypes.bool,
  onAudioChanged: PropTypes.func,
};

export default AudioRecorder;
