import { makeStyles } from "@material-ui/core";

const useSelectInputStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: 0,
    overflow: "hidden",
    minHeight: 42,
    transitionProperty: "width, background-color",
    transitionDuration: 500,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    "& .MuiInputBase-root": {
      minHeight: 42,
    },
    "& .MuiInputBase-root::before": {
      display: "none",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiInput-underline.Mui-focused:after": {
      display: "none",
    },
    "& .MuiSelect-select": {
      paddingLeft: 10,
      paddingTop: 12,
      color: "white",
      textTransform: "uppercase",
      textAlign: "center",
      alignSelf: "stretch",
    },
    "& .MuiSelect-icon": {
      color: theme.palette.primary.main,
    },
  },
}));

export default useSelectInputStyles;
