import React, { createContext, useContext, useEffect, useState } from "react";
import _ from "lodash";
import { loadObjectFromStorage, saveObjectToStorage } from "../../Utils/StorageUtils.js";
import { useBaseData } from "./BaseDataProvider.jsx";

export const WATCHED_NEWS = "watched news";

/**
 * @typedef {Object} NewsProviderContext
 * @property {TheNews[]} news
 * @property {Number} unwatchedCount
 * @property {FnNumberVoid} watchNews
 */

/** @type {React.Context<NewsProviderContext>} */
const newsContext = createContext({
  news: [],
  unwatchedCount: 0,
  /** @type {FnNumberVoid} */ watchNews: () => {},
});

/** @returns {NewsProviderContext} */
export const useNews = () => useContext(newsContext);

/**
 * @param {React.ReactNode} children
 * @returns {React.ReactNode}
 * @constructor
 */
const NewsProvider = ({ children }) => {
  const { news } = useBaseData();
  const [unwatchedCount, setUnwatchedCount] = useState(0);
  const [watchedNewsIds, setWatchedNewsIds] = useState(
    /** @type {Number[]} */ loadObjectFromStorage(WATCHED_NEWS) || [],
  );

  // calculate unwatched count
  useEffect(() => {
    if (!news) return;

    if (news.length > 0) {
      const unwatched = news.length - watchedNewsIds.length;
      setUnwatchedCount(unwatched < 0 ? 0 : unwatched);
    } else {
      setUnwatchedCount(0);
    }
  }, [news, watchedNewsIds]);

  /** @type {FnNumberVoid} */
  const watchNews = (id) => {
    const union = _.union(watchedNewsIds, [id]);
    saveObjectToStorage(WATCHED_NEWS, union);
    setWatchedNewsIds(union);
  };

  const value = {
    news,
    unwatchedCount,
    watchNews,
  };

  return <newsContext.Provider value={value}>{children}</newsContext.Provider>;
};

export default NewsProvider;
