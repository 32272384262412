import PropTypes from "prop-types";
import React, { useState } from "react";
import { Box, makeStyles, withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";

const StyledTypography = withStyles({
  button: {
    fontSize: 15,
  },
})(Typography);

const StyledSwitch = withStyles((theme) => ({
  root: {
    margin: "0 10px",
  },
  track: {
    backgroundColor: "#C4C4C4 !important",
    opacity: 0.5,
  },
  switchBase: {
    color: theme.palette.secondary.main,
  },
}))(Switch);

const useStyles = makeStyles({
  bold: {
    fontWeight: 900,
  },
});

/**
 * @param {Boolean} isRight
 * @param {Function} onChanged
 * @param {String} textLeft
 * @param {String} textRight
 * @param {String} className
 */
const SkillzrunSwitch = ({ isRight, onChanged, textLeft, textRight, className }) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(isRight);

  /** @param {ReactEventTarget} event */
  const handler = ({ target }) => {
    const checked1 = target.checked;
    onChanged && onChanged(checked1);
    setChecked(checked1);
  };

  return (
    <Box className={className}>
      <StyledTypography className={!checked ? classes.bold : ""} variant="button">
        {textLeft}
      </StyledTypography>
      <StyledSwitch checked={checked} onChange={handler} />
      <StyledTypography className={checked ? classes.bold : ""} variant="button">
        {textRight}
      </StyledTypography>
    </Box>
  );
};

SkillzrunSwitch.propTypes = {
  className: PropTypes.string,
  isRight: PropTypes.bool,
  onChanged: PropTypes.func,
  textLeft: PropTypes.string,
  textRight: PropTypes.string,
};

SkillzrunSwitch.defaultProps = {
  isRight: false,
};

export default SkillzrunSwitch;
