import * as querystring from "querystring";
import { useState } from "react";
import useEffectAsync from "../hooks/useEffectAsync.js";
import { youtubeGetThumbUrl } from "../Utils/youtube.js";
import { get } from "../Utils/NetworkUtils.js";
import { loadObjectFromStorage } from "../Utils/StorageUtils.js";
import { useLocale } from "../Providers/i18n/I18nProvider.jsx";
import { VIDEO_SOURCE_TYPE } from "../constants.js";

/** @param {LearnVideo} video
 * @returns {{src: String|undefined, loading: boolean, error: boolean}}
 */
function useVideoThumbController(video) {
  const videoUrl = video.url;
  const sourceType = video.sourceType;
  const [state, setState] = useState({ loading: false, error: false, src: undefined });
  const { locale } = useLocale();
  /** @type ?LoginResponse */
  const subject = loadObjectFromStorage("subject");

  useEffectAsync(async () => {
    if (sourceType === VIDEO_SOURCE_TYPE.thirdParty) {
      if (videoUrl && (videoUrl.indexOf("youtube.com") !== -1 || videoUrl.indexOf("youtu.be") !== -1)) {
        setState({
          loading: false,
          error: false,
          src: youtubeGetThumbUrl(videoUrl).mqdefault,
        });
      } else if (videoUrl && videoUrl.indexOf("vimeo.com") !== -1) {
        setState({
          loading: true,
          error: false,
          src: undefined,
        });

        const url = `vimeoThumbs?${querystring.stringify({ url: videoUrl })}`;

        try {
          const response = await get(url, locale, { authorization: subject.token });
          setState({
            loading: false,
            error: false,
            src: response.data["smallest"],
          });
        } catch (e) {
          setState({
            loading: false,
            error: true,
            src: undefined,
          });
        }
      } else {
        setState({
          loading: false,
          error: false,
          src: undefined,
        });
      }
    } else if (sourceType === VIDEO_SOURCE_TYPE.skillzrun) {
      setState({ loading: false, error: false, src: video.thumbUrl });
    }
  }, [videoUrl, sourceType]);

  return state;
}

export default useVideoThumbController;
