import { useEffect, useState } from "react";
import { useBaseData } from "../Providers/Data/BaseDataProvider.jsx";
import useCompanyPublicId from "./useCompanyPublicId.js";
import { useAuth } from "../Providers/Auth/AuthProvider.jsx";

/**
 * @returns {{
 * subjectId: number,
 * setSubjectId: Function
 * selectedSubject: CompanySubject,
 * subjectHandler: (function(CompanySubject): function(...[*]=)),
 * }}
 */
const useSubjectSelector = () => {
  const { companyName, domain } = useCompanyPublicId();
  const { user } = useAuth();
  const { getAvailableSubjectsForCompany, getAvailableSubjects, subject } = useBaseData();
  const [subjectId, setSubjectId] = useState(0);
  const [selectedSubject, setSelectedSubject] = useState(null);

  useEffect(() => {
    if (!user) {
      getAvailableSubjectsForCompany(domain || companyName).then();
    } else if (!subject) {
      getAvailableSubjects();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** @param {CompanySubject} subject */
  const subjectHandler = (subject) => () => {
    setSubjectId(subject.id);
    setSelectedSubject(subject);
  };

  return {
    subjectId,
    setSubjectId,
    selectedSubject,
    subjectHandler,
  };
};

export default useSubjectSelector;
