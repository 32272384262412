import { Fab, withStyles } from "@material-ui/core";

const CustomFab = withStyles(() => ({
  root: {
    width: 65,
    height: 65,
  },
  label: {
    "& .MuiSvgIcon-root": {
      transform: "scale(2.5)",
    },
  },
}))(Fab);

export default CustomFab;
