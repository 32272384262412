import React from "react";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

export const SNACKBAR_POSITION = Object.freeze({
  TOP_CENTER: { vertical: "top", horizontal: "center" },
  BOTTOM_CENTER: { vertical: "bottom", horizontal: "center" },
});

/**
 * @param {Object} props
 * @returns {*}
 * @constructor
 */
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/**
 * @param {Boolean} isOpen
 * @param {Function} close
 * @param {String} severity
 * @param {String} message
 * @param {{ vertical: String, horizontal: String }} position
 * @returns {*}
 * @constructor
 */
const MySnackbar = ({ isOpen, close, severity, message, position }) => {
  return (
    <Snackbar open={isOpen} onClose={close} autoHideDuration={6000} anchorOrigin={position} message={message}>
      <Alert onClose={close} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

MySnackbar.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string,
  position: PropTypes.object,
  severity: PropTypes.string,
};

MySnackbar.defaultProps = {
  position: SNACKBAR_POSITION.BOTTOM_CENTER,
};

export default MySnackbar;
