import React from "react";
import * as PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import { Box, makeStyles } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import LinearProgressBar from "../../../SharedComponents/LinearProgressBar.jsx";
import LockIcon from "../../../SharedComponents/LockIcon.jsx";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    display: "flex",
    flexDirection: "column",
    margin: 7,
    maxWidth: 95,
    height: 120,
    width: "100%",
    borderRadius: 7,
    textAlign: "center",
    position: "relative",
    backgroundImage: ({ illustrationImage }) =>
      illustrationImage
        ? `linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.00) 29.14%), url(${illustrationImage})`
        : null,
    backgroundSize: "cover",
    backgroundPosition: "bottom, center",
    transform: ({ disabled }) => (disabled ? null : "translateY(-3px)"),
    transitionProperty: "transform, box-shadow, opacity",
    transitionDuration: 300,
    opacity: ({ disabled }) => (disabled ? 0.4 : 1),
    [theme.breakpoints.up("lg")]: {
      height: 150,
    },
  },
  cardHeader: {
    backgroundColor: ({ illustrationImage, color }) => (illustrationImage ? "rgba(255, 255, 255, 0.70)" : color),
    padding: 7,
    minHeight: 28,
  },
  cardTitle: {
    color: ({ illustrationImage }) => (illustrationImage ? "black" : "white"),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 85,
    fontSize: 10,
  },
  cardActionArea: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "flex-start",
  },
  cardContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: 8,
    "&:last-child": {
      paddingBottom: 8,
    },
  },
  branchName: {
    maxHeight: 150,
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 5,
    "-webkit-box-orient": "vertical",
  },
  percent: {
    margin: "0 0 3px 0",
    color: ({ illustrationImage }) => (illustrationImage ? "white" : theme.palette.primary.main),
  },
  checkIcon: {
    position: "absolute",
    top: 32,
    right: 10,
  },
  progressBar: {
    margin: 0,
    [theme.breakpoints.up("lg")]: {
      margin: "0 0 10px 0",
    },
  },
  lockIcon: {
    right: 2,
    top: 2,
    width: 14,
  },
}));

/**
 * @param {Branch} branch
 * @param {Level} level
 * @param {Boolean} disabled
 * @param {Function} onClick
 * @returns {*}
 * @constructor
 */
const SmallBranchCard = ({ branch, disabled, onClick }) => {
  const illustrationImage = (branch.illustrationImageUrl || branch.illustrationImage?.url)?.replace(/\\/g, "/");
  const classes = useStyles({ color: branch.color, disabled, illustrationImage });

  const cardContent = (
    <>
      <CardHeader
        className={classes.cardHeader}
        title={illustrationImage ? branch.name : ""}
        titleTypographyProps={{ variant: "body2", className: classes.cardTitle }}
      />
      <CardContent className={classes.cardContent}>
        <Box height="70%" display="flex" flexDirection="column" justifyContent="center">
          <Typography className={classes.branchName} variant="body2">
            {illustrationImage ? "" : branch.name}
          </Typography>
        </Box>
        <Box>
          <Typography className={classes.percent} variant="subtitle2" color="primary">
            {`${branch.percent.toFixed()}%`}
          </Typography>
          <LinearProgressBar
            className={classes.progressBar}
            variant="determinate"
            value={branch.percent}
            height={10}
            radius="0px"
            noborder={illustrationImage}
          />
        </Box>
        {branch.learned && <CheckRoundedIcon className={classes.checkIcon} color="primary" fontSize="small" />}
      </CardContent>
    </>
  );

  return (
    <Card className={classes.cardRoot} elevation={disabled ? 1 : 4}>
      {disabled && (
        <CardActionArea className={classes.cardActionArea} onClick={onClick}>
          {cardContent}
        </CardActionArea>
      )}
      {!disabled && cardContent}
      {!branch.open && <LockIcon className={classes.lockIcon} />}
    </Card>
  );
};

SmallBranchCard.propTypes = {
  branch: PropTypes.object,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

SmallBranchCard.defaultProps = {
  disabled: false,
};

export default SmallBranchCard;
