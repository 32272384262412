import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslate } from "react-polyglot";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import { useBaseData } from "../../../Providers/Data/BaseDataProvider.jsx";
import { calculateDaysToEndOfAccess } from "../../../Utils/misc.js";
import FlexboxHorizontal from "../../../SharedComponents/FlexboxHorizontal.jsx";
import iconWarning from "../../../assets/iconWarning.svg";
import PrimarySmallRoundedButton from "../../../SharedComponents/PrimarySmallRoundedButton.jsx";
import iconNeutral from "../../../assets/iconNeutral.svg";

/** */
const AccessDaysWarningBar = ({ classes }) => {
  const t = useTranslate();
  const history = useHistory();
  const { userInfo } = useBaseData();
  const [daysToEnd, setDaysToEnd] = useState("");
  const [daysToDemoEnd, setDaysToDemoEnd] = useState("");

  useEffect(() => {
    if (userInfo.isDemoMode) {
      const demoDays = 7;
      const endDate = userInfo.demoStartAt + demoDays * 24 * 60 * 60 * 1000;
      const endInDays = (endDate - Date.now()) / (24 * 60 * 60 * 1000);
      setDaysToDemoEnd(Math.ceil(endInDays));
    } else {
      calculateDaysToEndOfAccess(userInfo, setDaysToEnd);
    }
  }, [userInfo]);

  if (daysToEnd && daysToEnd > 0 && daysToEnd <= 7) {
    return (
      <FlexboxHorizontal className={clsx(classes.warningBar, classes.accessDaysWarningBar)}>
        <img className={classes.warningBarIcon} src={iconWarning} alt="" />
        <Typography className={classes.inactiveUserBarText}>
          {t("accessDaysWarning", { smart_count: daysToEnd })}
        </Typography>
        <PrimarySmallRoundedButton
          className={classes.warningBarButton}
          onClick={() => {
            history.push("/profile");
          }}
        >
          {t("more")}
        </PrimarySmallRoundedButton>
      </FlexboxHorizontal>
    );
  } else if (daysToDemoEnd) {
    return (
      <FlexboxHorizontal className={clsx(classes.warningBar, classes.accessDemoDaysWarningBar)}>
        <img className={classes.warningBarIcon} src={iconNeutral} alt="" />
        <Typography className={classes.inactiveUserBarText}>
          {t("accessDemoDaysWarning", { smart_count: daysToDemoEnd })}
        </Typography>
        <PrimarySmallRoundedButton
          className={classes.warningBarButton}
          onClick={() => {
            history.push("/profile");
          }}
        >
          {t("more")}
        </PrimarySmallRoundedButton>
      </FlexboxHorizontal>
    );
  } else {
    return null;
  }
};

AccessDaysWarningBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default AccessDaysWarningBar;
