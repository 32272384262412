import React from "react";
import { withStyles, TextField, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { grey } from "@material-ui/core/colors";

export default withStyles({
  root: {
    backgroundColor: grey[100],
    borderRadius: 19,
    "& fieldset": {
      border: "none",
    },
  },
})((props) => (
  <TextField
    {...props}
    type="search"
    size="small"
    variant="outlined"
    fullWidth
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon color="primary" />
        </InputAdornment>
      ),
    }}
  />
));
