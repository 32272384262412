import React, { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import Webcam from "react-webcam";
import {
  makeStyles,
  Button,
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  withStyles,
  ButtonBase,
} from "@material-ui/core";
import { useTranslate } from "react-polyglot";
import VideocamIcon from "@material-ui/icons/Videocam";
import StopIcon from "@material-ui/icons/Stop";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import DoneRoundedIcon from "@material-ui/icons/DoneRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import ErrorText from "../../../../../SharedComponents/ErrorText.jsx";
import { useAnswerUserFiles } from "../../../../../Providers/Data/AnswerUserFilesProvider.jsx";
import { useBaseData } from "../../../../../Providers/Data/BaseDataProvider.jsx";
import useDateUtils from "../../../../../hooks/useDateUtils.js";
import TimerChip from "../../../../../SharedComponents/TimerChip.jsx";
import { formatTime } from "../../../../../Utils/misc.js";

const video_capturing_timer = 600000;

const StyledButton = withStyles((theme) => ({
  root: {
    color: theme.palette.text.disabled,
    "& svg": {
      width: 40,
      height: 40,
    },
  },
}))(Button);

/**
 * @param {*} props
 */
const VideoCamButton = (props) => {
  const [open, setOpen] = useState(false);
  const { anyFiles, homeworkUserDataFiles } = useAnswerUserFiles();

  return (
    <>
      <StyledButton
        onClick={() => setOpen(!open)}
        color="secondary"
        disabled={anyFiles.length + homeworkUserDataFiles.length >= 5}
      >
        <VideocamIcon />
      </StyledButton>
      <VideoWebCamDialog {...props} open={open} onClose={() => setOpen(!open)} />
    </>
  );
};

const useDialogStyles = makeStyles(() => ({
  dialogContent: {
    position: "relative",
  },
  dialogActions: {
    justifyContent: "center",
    marginBottom: 20,
  },
  initString: {
    position: "absolute",
    top: 95,
    left: 20,
    right: 20,
    textAlign: "center",
  },
  cameraButtons: {
    backgroundColor: "rgba(0,0,0,0.5)",
    position: "absolute",
    left: 24,
    right: 24,
    bottom: 13,
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  recordButton: {
    width: 37,
    height: 37,
    color: "white",
    backgroundColor: "#FF0000",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "#FF0000",
      opacity: "0.8",
    },
  },
}));

/**
 * @param {Function} onClose
 * @param {Object} rest
 * @returns {*}
 * @constructor
 */
const VideoWebCamDialog = ({ onClose, ...rest }) => {
  const classes = useDialogStyles();
  const t = useTranslate();
  const webcamRef = useRef(/** @type {Webcam} */ null);
  const mediaRecorderRef = useRef(/** @type {MediaRecorder} */ null);
  const timerRef = useRef(/** @type {number} */ 0);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [isCameraInitialized, setCameraInitialized] = useState(false);
  const [cameraError, setCameraError] = useState(null);
  const { anyFiles, setAnyFiles, homeworkUserDataFiles } = useAnswerUserFiles();
  const { userInfo } = useBaseData();
  const { format } = useDateUtils();

  // Record timer
  useEffect(() => {
    if (capturing) {
      const timerHandle = setInterval(() => {
        setElapsedTime((prevSeconds) => prevSeconds + 1);
      }, 1000);
      return () => {
        clearInterval(timerHandle);
      };
    }
    if (!capturing || elapsedTime >= video_capturing_timer) {
      setElapsedTime(0);
    }
  }, [capturing]);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks],
  );

  const handleStartCaptureClick = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      videoBitsPerSecond: 500000,
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.addEventListener("dataavailable", handleDataAvailable);
    mediaRecorderRef.current.start();

    timerRef.current = setTimeout(() => {
      mediaRecorderRef.current.stop();
      setCapturing(false);
    }, video_capturing_timer);
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = 0;
      }
    };
  }, [setCapturing, handleDataAvailable]);

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = 0;
    }
  }, [setCapturing]);

  /** */
  const handleClose = useCallback(() => {
    setTimeout(() => {
      resetCapturing();
    }, 400);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = 0;
    }
    onClose && onClose();
  }, [onClose]);

  const handleAdd = useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      const file = new File([blob], `${userInfo.name}_${format(new Date(), "dd.MM_HH:mm")}.webm`, {
        type: "video/webm",
        lastModified: new Date(),
      });
      if (homeworkUserDataFiles.length + anyFiles.length < 5) {
        anyFiles.push(file);
        setAnyFiles([...anyFiles]);
      }
      handleClose();
    }
  }, [recordedChunks, anyFiles, handleClose, homeworkUserDataFiles?.length, setAnyFiles, userInfo?.name, format]);

  /** */
  const cameraInitializationHandler = () => {
    setCameraInitialized(true);
  };

  /** @param {DOMException} error */
  const cameraInitializationFailedHandler = (error) => {
    setCameraError(error.message);
  };

  /** */
  const resetCapturing = () => {
    setCameraInitialized(false);
    setCameraError(null);
    setRecordedChunks([]);
    setCapturing(false);
  };

  return (
    <Dialog {...rest} onClose={handleClose} maxWidth="md">
      <DialogContent className={classes.dialogContent}>
        <Webcam
          audio={true}
          ref={webcamRef}
          width={640}
          height={360}
          onUserMedia={cameraInitializationHandler}
          onUserMediaError={cameraInitializationFailedHandler}
        />
        {!isCameraInitialized && !cameraError && (
          <Typography className={classes.initString}>{t("Exercises.type7.cameraInitialization")}</Typography>
        )}
        {cameraError && <ErrorText className={classes.initString}>{cameraError}</ErrorText>}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {capturing ? (
          <>
            <ButtonBase className={classes.recordButton} onClick={handleStopCaptureClick}>
              <StopIcon />
            </ButtonBase>
            <TimerChip label={formatTime(elapsedTime)} />
          </>
        ) : (
          <ButtonBase
            className={classes.recordButton}
            disabled={recordedChunks.length > 0}
            onClick={handleStartCaptureClick}
          >
            <RadioButtonCheckedIcon />
          </ButtonBase>
        )}
        {recordedChunks.length > 0 && (
          <>
            <Button
              color="secondary"
              onClick={() => {
                setRecordedChunks([]);
              }}
            >
              <CloseRoundedIcon />
            </Button>
            <Button color="secondary" variant="contained" onClick={handleAdd}>
              <DoneRoundedIcon />
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

VideoWebCamDialog.propTypes = {
  onClose: PropTypes.func,
};

export default VideoCamButton;
