import { Breadcrumbs, Container, Link, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-polyglot";
import Box from "@material-ui/core/Box";
import useCompanyPublicId from "../../hooks/useCompanyPublicId.js";
import { useAuth } from "../../Providers/Auth/AuthProvider.jsx";
import { useBaseData } from "../../Providers/Data/BaseDataProvider.jsx";
import DiscardButton from "./components/DiscardButton.jsx";
import FlexboxVertical from "../../SharedComponents/FlexboxVertical.jsx";
import FlexboxHorizontal from "../../SharedComponents/FlexboxHorizontal.jsx";
import useTitle from "../../hooks/useTitle.js";
import useFavicon from "../../hooks/useFavicon.js";
import PaymentDialog from "../../SharedComponents/PaymentDialog.jsx";
import YellowButton from "../../SharedComponents/YellowButton.jsx";
import BuyOrSubscribeButton from "./components/BuyOrSubscribeButton.jsx";
import { calculateEndDateOfAccess, checkOfferRepeat } from "../../Utils/misc.js";
import CompanyHeader from "../Subjects/SharedComponents/CompanyHeader.jsx";

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    margin: 30,

    "& .MuiBreadcrumbs-li": {
      fontSize: 16,
      fontWeight: 500,
    },
  },
  wrapper: {
    padding: "40px 0",
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0,
    },

    "& .MuiBox-root": {
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        alignItems: "center",
        rowGap: 26,
      },
    },
  },
  media: {
    position: "relative",

    "& img": {
      width: 635,
      height: 478,
      objectFit: "contain",

      [theme.breakpoints.down("sm")]: {
        width: 320,
        height: "auto",
      },

      [theme.breakpoints.down("xs")]: {
        //width: 220,
      },
    },
  },
  content: {
    padding: "0 30px",

    "& h2": {
      fontWeight: 800,
      fontSize: 32,
      marginBottom: 30,
    },

    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  description: {
    marginBottom: 46,
  },
  prices: {
    marginBottom: 46,

    "& .MuiTypography-root": {
      textAlign: "center",
      fontSize: 32,
      fontWeight: 800,
      lineHeight: 1.2,
      padding: 18,
      minWidth: 160,
    },
  },
  priceLabel: {
    display: "inline-block",
    borderRadius: 4,
    width: "fit-content",
    border: "1px solid #FAFAFA",
    boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
  },
  price: {
    fontWeight: 800,
  },
  oldPrice: {
    color: "#C1401E",
    textDecoration: "line-through",
    fontWeight: 500,
  },
  newPrice: {
    color: "#1A854B",
  },
  buttons: {
    marginLeft: -8,
  },
  cardBtn: {
    margin: 8,
  },
  messageText: {
    //color: "#FFFFFF",
  },
  firstPrice: {
    fontSize: 14,
    fontWeight: 500,
  },
  intoLearnBtn: {
    marginLeft: "auto",
  },
}));

/** */
const every = (every) => {
  switch (every) {
    case 1:
      return "onePerMonth";
    case 6:
      return "semiannually";
    case 12:
      return "onceAYear";
  }
};

/**
 * @return {null|*}
 * @constructor
 */
const Offer = () => {
  const classes = useStyles();
  const t = useTranslate();
  const history = useHistory();
  const { offerId, urn } = useParams();
  const { companyName, domain } = useCompanyPublicId();
  const { company2, user } = useAuth();
  const {
    company,
    requestOffer,
    offer,
    userInfo,
    subject,
    chooseSubject,
    preselectedOffer,
    preselectedSubject,
    setPreselectedSubject,
    setPreselectedOffer,
    getAvailableSubjects,
    getAvailableSubjectsForCompany,
  } = useBaseData();
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [isOfferRepeat, setIsOfferRepeat] = useState(false);
  const [ifLevelExist, setIfLevelExist] = useState(false);
  const [userOffer, setUserOffer] = useState(null);
  const [userSubscribe, setUserSubscribe] = useState(/** @type UserSubscribe */ null);

  const company_ = company || company2;

  useTitle("title.offer", { school: (company_ && company_.name) || companyName || domain });

  useFavicon();

  useEffect(() => {
    if (user) {
      getAvailableSubjects().then();
    } else {
      getAvailableSubjectsForCompany(domain || companyName).then();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    requestOffer(urn, offerId).then();
  }, [urn, offerId]);

  useEffect(() => {
    if (preselectedOffer) {
      handleOpenDialog();
    }
  }, [preselectedOffer]);

  //checks the possibility of buying an offer again
  useEffect(() => {
    checkOfferRepeat(offer, setIsOfferRepeat);
  }, [offer]);

  //calculate the end date of access
  useEffect(() => {
    calculateEndDateOfAccess(userInfo, setEndDate);
  }, [userInfo]);

  useEffect(() => {
    // find purchased offers
    const offerOfUser = offer && userInfo?.offers?.find((uOffer) => uOffer.offerId === offer.id);
    setUserOffer(offerOfUser);

    const subscribeOfUser = offer && userInfo?.subscribes.find((uSubscribe) => uSubscribe.offerId === offer.id);
    setUserSubscribe(subscribeOfUser);

    // check if the level exists
    if (!userInfo) {
      setIfLevelExist(false);
    } else {
      userInfo.levelPacks === undefined
        ? setIfLevelExist(false)
        : setIfLevelExist(offer?.levelPacks.filter((level) => userInfo.levelPacks.includes(level)).length > 0);
    }
  }, [userInfo, offer]);

  /** */
  const handleCloseDialog = () => {
    setPreselectedOffer(null);
    setIsDialogOpened(false);
    if (preselectedSubject) chooseSubject(preselectedSubject).then();
  };

  /** */
  const handleOpenDialog = () => {
    setIsDialogOpened(true);
  };

  /** */
  const offerButtonHandler = (offer) => {
    setPreselectedOffer(offer.id);
    setPreselectedSubject(offer.subjectId);
    if (!user) {
      history.push(`${companyName ? "/" + companyName : ""}/auth`);
    } else {
      handleOpenDialog();
    }
  };
  /** */
  const intoLearnHandler = () => {
    if (subject) {
      history.replace("/");
    }
  };

  if (!offer) {
    return null;
  }

  return (
    <Container maxWidth="lg">
      <CompanyHeader />
      <FlexboxHorizontal alignItems={"center"} justifyContent={"space-between"}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
          <Link
            color="inherit"
            component="button"
            variant="body1"
            onClick={() => history.push(`${companyName ? "/" + companyName : ""}`)}
          >
            {t("allSubjects")}
          </Link>
          <Link
            color="inherit"
            component="button"
            variant="body1"
            onClick={() => history.push(`${companyName ? "/" + companyName : ""}/subjects/${offer.subjectId}`)}
          >
            {t("subject")}
          </Link>
          <Typography color="textPrimary">{offer.name}</Typography>
        </Breadcrumbs>
        <YellowButton className={classes.intoLearnBtn} disabled={!subject} onClick={intoLearnHandler}>
          <Typography>{t("enter")}</Typography>
        </YellowButton>
      </FlexboxHorizontal>

      <Box className={classes.wrapper}>
        <FlexboxHorizontal justifyContent="center">
          <div className={classes.media}>
            <img
              //className={classes.media}
              src={offer.image.url}
              alt={offer.image.name}
            />
          </div>
          <div className={classes.content}>
            <Typography gutterBottom variant="h2">
              {offer.name}
            </Typography>
            <Typography variant="body1" color="textPrimary" component="p" className={classes.description}>
              {offer.description ? offer.description : t("noDescription")}
            </Typography>
            <div className={classes.prices}>
              {offer.oldPrice && offer.oldPrice > offer.price ? (
                <FlexboxHorizontal>
                  <div className={classes.priceLabel}>
                    <Typography className={classes.newPrice} variant="subtitle1">
                      {offer.price} {offer.currency.toUpperCase()}
                    </Typography>
                  </div>
                  <Typography className={classes.oldPrice} variant="subtitle1">
                    {offer.oldPrice} {offer.currency.toUpperCase()}
                  </Typography>
                </FlexboxHorizontal>
              ) : (
                <div className={classes.priceLabel}>
                  <Typography variant="subtitle1" className={classes.price}>
                    {offer.price} {offer.currency.toUpperCase()}
                  </Typography>
                </div>
              )}
            </div>
            <FlexboxVertical>
              <FlexboxHorizontal alignItems="center" className={classes.buttons}>
                <div>
                  <BuyOrSubscribeButton
                    {...{ offer, isOfferRepeat, userOffer, offerButtonHandler, userSubscribe, user }}
                  />
                </div>
                <div>
                  <DiscardButton
                    className={classes.cardBtn}
                    offer={offer}
                    user={user}
                    offerProceed={userOffer?.status === "pending"}
                  />
                </div>
              </FlexboxHorizontal>
              {offer.isSubscribe && offer.subscribe && (
                <>
                  <Typography variant="body2" className={classes.firstPrice} gutterBottom>
                    {t("firstPayment")} {offer.subscribe.firstPrice} {offer.currency.toUpperCase()}
                  </Typography>
                  <Typography variant="body2" className={classes.messageText} component="small" gutterBottom>
                    {t(every(offer.subscribe.every))}
                  </Typography>
                  {userSubscribe?.endTime && (
                    <Typography variant="body2" className={classes.messageText} component="small" gutterBottom>
                      {t("expired")}: {userSubscribe.endTime}
                    </Typography>
                  )}
                </>
              )}
              {ifLevelExist && !userOffer && (
                <Typography variant="body2" className={classes.messageText} component="small" gutterBottom>
                  {t("levelPackExist")}
                </Typography>
              )}
              {userOffer?.status === "pending" && (
                <Typography variant="body2" className={classes.messageText} component="small" gutterBottom>
                  {t("awaitingPayment")}
                </Typography>
              )}
              {userOffer?.paid && !isOfferRepeat && (
                <Typography variant="body2" className={classes.messageText} component="small" gutterBottom>
                  {t("bought")}
                </Typography>
              )}
              {userOffer?.paid && isOfferRepeat && (
                <Typography variant="body2" className={classes.messageText} component="small" gutterBottom>
                  {t("boughtUntilDate", { endDate })}
                </Typography>
              )}
            </FlexboxVertical>
          </div>
        </FlexboxHorizontal>
        <PaymentDialog isDialogOpened={isDialogOpened} handleCloseDialog={handleCloseDialog} />
      </Box>
    </Container>
  );
};

export default Offer;
