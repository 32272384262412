import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

/**
 * @param {('xs' | 'sm' | 'md' | 'lg' | 'xl')} breakpoint
 * @returns {Boolean}
 */
export default function (breakpoint) {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up(breakpoint));
}
