import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import _ from "lodash";
import { Box, makeStyles } from "@material-ui/core";
import { useTranslate } from "react-polyglot";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import Button from "@material-ui/core/Button";
import { useLearnProcess } from "../../../../Providers/Data/LearnProcessProvider.jsx";
import AnswerUserFilesProvider from "../../../../Providers/Data/AnswerUserFilesProvider.jsx";
import GreyButton from "../../../../SharedComponents/GreyButton.jsx";
import ProgressTypography from "../../../../SharedComponents/ProgressTypography.jsx";
import PrimaryRoundedButton from "../../../../SharedComponents/PrimaryRoundedButton.jsx";
import useEffectWithPreviousValues from "../../../../hooks/useEffectWithPreviousValues.js";
import Exercise9 from "./Exercise9.jsx";
import Exercise8 from "./Exercise8.jsx";
import Exercise7 from "./Exercise7.jsx";
import Exercise3 from "./Exercise3.jsx";
import Exercise5 from "./Exercise5.jsx";
import AddPointsAnimation from "../SharedComponents/AddPointsAnimation.jsx";
import FlexboxVertical from "../../../../SharedComponents/FlexboxVertical.jsx";
import Exercise11 from "./Exercise11.jsx";
import WrongAnswerAlertComment from "./SharedComponents/WrongAnswerAlertComment.jsx";
import { useCourseProvider } from "../../../../Providers/Data/CourseProvider.jsx";
import ExamInfoBlock from "./ExamInfoBlock.jsx";
import Exercise6 from "./Exercise6.jsx";
import { useDialogs } from "../../../../Providers/Common/DialogsProvider.jsx";
import Exercise12 from "./Exercise12.jsx";

const useStyles = makeStyles(
  () => ({
    button: {
      minWidth: ({ minW }) => minW,
    },
    arrowButtons: {
      position: "absolute",
      width: 50,
      height: 50,
      minWidth: "unset",
    },
    nextButton: {
      right: 0,
    },
    exerciseLearnStatusIcon: {
      position: "absolute",
      bottom: 100,
      right: 10,
    },
    colorPink: {
      color: "#EB4893",
    },
  }),
  {
    name: "makeExercisesStyles",
  },
);

/**
 * @param {ExerciseResponse} exercise
 */
const DefaultInfoBlock = ({ exercise }) => {
  const t = useTranslate();
  const progressText = `${exercise.deck.learnedCount} ${t("of")} ${exercise.deck.count}`;

  return (
    <FlexboxVertical alignItems="center">
      <ProgressTypography color="primary">{progressText}</ProgressTypography>
      <Typography color="primary">{t("completed")}</Typography>
    </FlexboxVertical>
  );
};

DefaultInfoBlock.propTypes = {
  exercise: PropTypes.object.isRequired,
};

/** */
const Exercises = () => {
  const t = useTranslate();
  const history = useHistory();
  const { warn, success, alert } = useDialogs();
  const { currentDeck, nextDeckId } = useCourseProvider();
  const {
    exercise,
    patchExercise,
    skipExercise,
    userIsWrong,
    requestOtherExercise,
    specifiedExerciseId,
  } = useLearnProcess();
  const classes = useStyles({ minW: _.get(exercise, "deck.exam.endDate") !== null ? 280 : 305 });
  const [isCheckButtonDisabled, setCheckButtonDisabled] = useState(true);
  const checkAnswerRef = useRef(/** @type {Function} */ null);
  const [attemptCount, setAttemptCount] = useState(1);
  const [congratButtonText, setCongratButtonText] = useState("OK");

  const exercisesAttempts = exercise ? exercise.exercise.exercisesAttempts : 0;
  const homeworkUserData = _.get(exercise, "exercise.data.homework.userData");

  //reset state
  useEffect(() => {
    setCheckButtonDisabled(true);
    setAttemptCount(1);
  }, [exercise]);

  // observe learned status
  useEffectWithPreviousValues(
    /** @param {ExerciseResponse} prevExercise */
    ([prevExercise]) => {
      if (!prevExercise || !exercise || !prevExercise.deck || !exercise.deck) {
        return;
      }
      if (
        !exercise.deck.exam &&
        prevExercise.deck.id === exercise.deck.id &&
        !prevExercise.deck.learned &&
        exercise.deck.learned
      ) {
        // Show Congratulation alert
        alert({
          onClose: congratCloseHandler,
          title: t("Exercises.congratulation"),
          message: t("Exercises.congratText"),
          buttonText: congratButtonText,
          disableEscape: true,
        });
      }
    },
    [exercise],
  );

  //set last congratButtonText
  useEffect(() => {
    if (nextDeckId) {
      setCongratButtonText(t("nextDeck"));
    } else {
      setCongratButtonText("OK");
    }
  }, [nextDeckId, t]);

  /** @param {boolean} byButton */
  const congratCloseHandler = (byButton) => {
    if (!byButton) return;

    if (nextDeckId) {
      history.push(`/learn/${nextDeckId}`);
    } else {
      history.push(`/`);
    }
  };

  /**
   * @param {React.ReactNode} component
   * @param {string} answerValue
   */
  const showRightAnswer = async (component, answerValue) => {
    const newAttemptCount = attemptCount + 1;
    const _attemptsOverflow = exercisesAttempts !== 0 && newAttemptCount > exercisesAttempts;
    if (exercise.deck.exam) {
      await userIsWrong(answerValue);
      success(t("Exercises.answerSaved"));
      setAttemptCount(newAttemptCount);
      return;
    }
    warn(t("Exercises.wrongAnswer"));
    if (_attemptsOverflow) {
      alert({
        title: t("Exercises.rightAnswerWas"),
        content: component,
        buttonText: t("Exercises.next"),
        maxWidth: "sm",
      });
      userIsWrong(answerValue).then();
    }
    setAttemptCount(newAttemptCount);
  };

  /**
   * @param {React.ReactNode} component
   * @param {[String]} comments
   * @param {string} answerValue
   */
  const showRightAnswerWithComments = async (component, comments, answerValue) => {
    const newAttemptCount = attemptCount + 1;
    const _attemptsOverflow = exercisesAttempts !== 0 && newAttemptCount > exercisesAttempts;
    if (!component) {
      if (comments.length > 0) {
        alert({
          content: <WrongAnswerAlertComment comments={comments} />,
          buttonText: t("Exercises.next"),
          maxWidth: "sm",
        });
      }
      setAttemptCount(newAttemptCount);
      return;
    }
    if (exercise.deck.exam) {
      await userIsWrong(answerValue);
      success(t("Exercises.answerSaved"));
      if (comments.length > 0) {
        alert({
          content: <WrongAnswerAlertComment comments={comments} />,
          buttonText: t("Exercises.next"),
          maxWidth: "sm",
        });
      }
      setAttemptCount(newAttemptCount);
      return;
    }
    warn(t("Exercises.wrongAnswer"));
    if (_attemptsOverflow) {
      alert({
        title: t("Exercises.rightAnswerWas"),
        content: component,
        buttonText: t("Exercises.next"),
        maxWidth: "sm",
      });
      userIsWrong(answerValue).then();
    } else {
      if (comments.length > 0) {
        alert({
          content: <WrongAnswerAlertComment comments={comments} />,
          buttonText: t("Exercises.next"),
          maxWidth: "sm",
        });
      }
    }
    setAttemptCount(newAttemptCount);
  };

  /** */
  const getExerciseContent = () => {
    const props1 = {
      checkAnswerRef,
      exercise: exercise.exercise.data,
      setCheckButtonDisabled,
      exerciseTitle: exercise.exercise.title,
    };
    const props2 = {
      ...props1,
      showRightAnswer,
    };
    const propsWithComments = {
      ...props1,
      showRightAnswer,
      showRightAnswerWithComments,
    };
    switch (exercise.exercise.type) {
      case 12:
        return <Exercise12 {...props1} />;
      case 11:
        return <Exercise11 {...propsWithComments} />;
      case 9:
        return <Exercise9 {...props1} />;
      case 8:
        return <Exercise8 {...props1} />;
      case 7:
        return (
          <AnswerUserFilesProvider>
            <Exercise7 {...props1} />
          </AnswerUserFilesProvider>
        );
      case 6:
        return <Exercise6 {...props1} />;
      case 5:
        return <Exercise5 {...props2} />;
      case 3:
        return <Exercise3 {...props2} />;
      default:
        return <Box>{t("unsupportedExercise", { type: exercise.exercise.type })}</Box>;
    }
  };

  /** */
  const checkAnswerButtonHandler = async () => {
    /** @type {?ExercisePatchData} */
    const answerResult = checkAnswerRef.current && (await checkAnswerRef.current());
    if (answerResult) {
      success(t("Exercises.answerSaved"));
      patchExercise(answerResult).then();
    }
  };

  /** @param {Number} direction */
  const arrowButtonsHandler = (direction) => () => {
    if (specifiedExerciseId) {
      history.push(`/learn/${currentDeck.id}`);
    }
    requestOtherExercise(direction).then();
  };

  if (!exercise) {
    return null;
  }

  let checkButtonText = t("Exercises.check");
  if (homeworkUserData) {
    checkButtonText = t("Exercises.type7.change");
  }
  if (exercise.exercise.type === 6) {
    checkButtonText = t("Exercises.type6.checkButtonText");
  }

  return (
    <Box
      data-component="Exercises"
      flexGrow={1}
      display="flex"
      flexDirection="column"
      paddingBottom="0"
      position="relative"
    >
      <Box data-tag="exercise selector" flexGrow={1}>
        {getExerciseContent()}
      </Box>
      {exercise.exercise.passed && exercise.exercise.learned && (
        <CheckCircleRoundedIcon color="primary" className={classes.exerciseLearnStatusIcon} />
      )}
      {exercise.exercise.passed && !exercise.exercise.learned && (
        <CancelRoundedIcon className={clsx(classes.exerciseLearnStatusIcon, classes.colorPink)} />
      )}
      <Button className={clsx(classes.arrowButtons)} color="primary" onClick={arrowButtonsHandler(-1)}>
        <ArrowBackIosRoundedIcon />
      </Button>
      <Button
        className={clsx(classes.arrowButtons, classes.nextButton)}
        color="primary"
        onClick={arrowButtonsHandler(0)}
      >
        <ArrowForwardIosRoundedIcon />
      </Button>
      <Box
        data-tag="bottom buttons"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        margin="15px 0 15px"
      >
        <GreyButton
          className={classes.button}
          onClick={skipExercise}
          disabled={exercise.deck.exam && (exercise.exercise.passed || exercise.deck.exam.endDate !== null)}
        >
          {t("Exercises.skip")}
        </GreyButton>
        {!exercise.deck.exam && <DefaultInfoBlock exercise={exercise} />}
        {exercise.deck.exam && <ExamInfoBlock exercise={exercise} />}
        <AddPointsAnimation>
          <PrimaryRoundedButton
            className={classes.button}
            disabled={
              (!exercise.deck.exam && isCheckButtonDisabled) ||
              (exercise.deck.exam &&
                (exercise.exercise.passed || exercise.deck.exam.endDate !== null || isCheckButtonDisabled))
            }
            onClick={checkAnswerButtonHandler}
          >
            {checkButtonText}
          </PrimaryRoundedButton>
        </AddPointsAnimation>
      </Box>
    </Box>
  );
};

export default Exercises;
