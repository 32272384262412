import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles, TableContainer, Table, TableBody, TableRow, TableCell } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import TablePagination from "@material-ui/core/TablePagination";
import { useTranslate } from "react-polyglot";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import { ReactComponent as Lines } from "../../../assets/ic_lines_green.svg";
import { ReactComponent as Sector } from "../../../assets/ic_sector_green.svg";
import { ReactComponent as Check } from "../../../assets/ic_check_green.svg";
import SearchField from "../../../SharedComponents/SearchField.jsx";
import { useDictionary } from "../../../Providers/Data/DictionaryProvider.jsx";
import { alphabets } from "../../../constants.js";
import { useBaseData } from "../../../Providers/Data/BaseDataProvider.jsx";
import { delay } from "../../../Utils/misc.js";
import TypographyArabic from "../../../SharedComponents/TypographyArabic.jsx";
import useCompanyTitle from "../../../hooks/useCompanyTitle.js";
import StringSelectInput from "../../../SharedComponents/StringSelectInput.jsx";
import ObjectSelectInput from "../../../SharedComponents/ObjectSelectInput.jsx";

const useStyles = makeStyles((theme) => ({
  headBox: {
    padding: "25px 0",
    [theme.breakpoints.down("md")]: {
      maxWidth: 824,
      margin: "0 auto",
      padding: "40px 20px 10px",
    },
  },
  headItem: {
    width: "49%",
    marginBottom: 15,
  },
  iconBtn: {
    border: "1px solid #ccc",
  },
  icon: {
    width: 25,
    height: 25,
    "& path": {
      fill: theme.palette.primary.main,
    },
  },
  checkIcon: {
    width: 25,
    height: 25,
    "& circle": {
      fill: theme.palette.primary.main,
    },
  },
  tableRow: {
    cursor: "pointer",
    transitionProperty: "background-color",
    transitionDuration: theme.transitions.duration.shorter,
  },
  wordsTable: {
    tableLayout: "fixed",
    "& td": {
      [theme.breakpoints.down("sm")]: {
        border: "none",
      },
    },
    [theme.breakpoints.down("sm")]: {
      backgroundColor: grey[100],
    },
  },
  statusCellContent: {
    width: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
  },
  headItemSwitch: {
    padding: 5,
    textAlign: "center",
    backgroundColor: grey[100],
  },
  letterFilterStyle: {
    width: ({ filtersBranch }) => (filtersBranch === -1 ? "50%" : "33.333%"),
    backgroundColor: ({ filtersLetter }) => (filtersLetter === "" ? undefined : theme.palette.primary.dark),
  },
  branchFilterStyle: {
    width: ({ filtersBranch }) => (filtersBranch === -1 ? "50%" : "33.333%"),
    backgroundColor: ({ filtersBranch }) => (filtersBranch === -1 ? undefined : theme.palette.primary.dark),
  },
  deckFilterStyle: {
    width: ({ filtersBranch }) => (filtersBranch === -1 ? 0 : "33.333%"),
    backgroundColor: ({ filtersDeck }) => (filtersDeck === -1 ? undefined : theme.palette.primary.dark),
  },
}));

const useDialogStyles = makeStyles(() => ({
  content: {
    backgroundColor: grey[100],
  },
  list: {
    backgroundColor: grey[100],
  },
  listItemText: {
    fontWeight: 700,
  },
}));

/**
 * @param {WordInWordList[]} words
 * @param {SimpleIdNameObject[]} decks
 * @param {Object} rest
 * @returns {React.ReactNode}
 * @constructor
 */
const WordVariantsDialog = ({ words, decks, ...rest }) => {
  const classes = useDialogStyles();
  const t = useTranslate();

  useCompanyTitle("title.dictionary");

  /**
   * @param {Number} deckId
   * @returns {Function}
   */
  const listItemClickHandler = (deckId) => () => {
    rest.onClose && rest.onClose(deckId, words[0].id);
  };

  if (!words || !decks) {
    return null;
  }

  return (
    <Dialog {...rest}>
      <DialogTitle disableTypography>
        <TypographyArabic arabicSize={30}>{words[0].source}</TypographyArabic>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography>{t("wordsAreHere")}</Typography>
      </DialogContent>
      <List className={classes.list}>
        {decks.map((deck) => (
          <ListItem key={deck.id} className={classes.listItemText} button onClick={listItemClickHandler(deck.id)}>
            {deck.name}
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

WordVariantsDialog.propTypes = {
  decks: PropTypes.array,
  words: PropTypes.array,
};

/**
 * @returns {React.ReactNode}
 * @constructor
 */
const Dictionary = () => {
  const t = useTranslate();
  const history = useHistory();
  const { userInfo } = useBaseData();
  const { allWords, filters, setFilters, branches, decks, allDecks } = useDictionary();
  const classes = useStyles({
    filtersBranch: filters.branch,
    filtersLetter: filters.letter,
    filtersDeck: filters.deck,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTextValue, setSearchText] = useState("");
  const searchTimeoutHandle = useRef(0);
  const [wordsForDialog, setWordsForDialog] = useState(/** @type {?WordInWordList[]} */ null);
  const [decksForDialog, setDecksForDialog] = useState(/** @type {?SimpleIdNameObject[]} */ null);
  const [lettersForFilter, setLettersForFilter] = useState([]);

  // reset pagination on filters change
  useEffect(() => {
    setPage(0);
  }, [filters]);

  // form letters list
  useEffect(() => {
    if (userInfo) {
      const sourceLetters = _.get(alphabets, userInfo.subject.wordsSource.language, []);
      const translationLetters = _.get(alphabets, userInfo.subject.wordsTranslation.language, []);
      if (userInfo.subject.subjectType === "general") {
        setLettersForFilter(sourceLetters);
      } else {
        setLettersForFilter([...sourceLetters, ...translationLetters]);
      }
    }
  }, [userInfo]);

  /**
   * @param {*} _
   * @param {Number} newPage
   */
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  /** @param {ReactEventTarget} target */
  const handleChangeRowsPerPage = ({ target }) => {
    setRowsPerPage(parseInt(target.value, 10));
    setPage(0);
  };

  /** @param {ReactEventTarget} target */
  const searchFieldHandler = ({ target }) => {
    const text = target.value;
    setSearchText(text);
    clearTimeout(searchTimeoutHandle.current);
    if (text !== "") {
      searchTimeoutHandle.current = setTimeout(() => {
        setFilters({ text });
      }, 600);
    } else {
      setFilters({ text });
    }
  };

  /** @param {ReactEventTarget} target */
  const letterSelectHandler = ({ target }) => {
    setFilters({ letter: target.value });
  };

  /** @param {ReactEventTarget} target */
  const branchSelectHandler = ({ target }) => {
    const value = target.value;
    setFilters({ branch: value === "" ? -1 : value, deck: -1 });
  };

  /** @param {ReactEventTarget} target */
  const deckSelectHandler = ({ target }) => {
    const value = target.value;
    setFilters({ deck: value === "" ? -1 : value });
  };

  /**
   * @param {WordInWordList[]} words
   * @returns {Function}
   */
  const handleTableRowClick = (words) => () => {
    if (words.length === 1) {
      history.push(`/learn/${words[0].deckId}/${words[0].id}`);
    }
    if (words.length > 1) {
      setDecksForDialog(words.map((word) => allDecks.find((deck) => deck.id === word.deckId)));
      setWordsForDialog(words);
    }
  };

  /**
   * @param {Number} deckId
   * @param {Number} wordId
   */
  const closeDialogHandler = async (deckId, wordId) => {
    if (typeof deckId === "number" && typeof wordId === "number") {
      await delay(300);
      setWordsForDialog(null);
      setDecksForDialog(null);
      await delay(300);
      history.push(`/learn/${deckId}/${wordId}`);
    } else {
      setWordsForDialog(null);
      setDecksForDialog(null);
    }
  };

  return (
    <>
      <Box
        className={classes.headBox}
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems="center"
      >
        <Box className={classes.headItem}>
          <SearchField value={searchTextValue} onChange={searchFieldHandler} />
        </Box>
        <Box
          className={classes.headItem}
          style={{ width: "100%" }} //style used to override "headItem" class styles
        >
          <StringSelectInput
            className={classes.letterFilterStyle}
            label={t("allWords")}
            value={filters.letter}
            onChange={letterSelectHandler}
            values={lettersForFilter}
          />
          <ObjectSelectInput
            className={classes.branchFilterStyle}
            label={t("topic")}
            value={filters.branch === -1 ? "" : filters.branch}
            onChange={branchSelectHandler}
            values={branches}
          />
          <ObjectSelectInput
            className={classes.deckFilterStyle}
            label={t("lesson")}
            value={filters.deck === -1 ? "" : filters.deck}
            onChange={deckSelectHandler}
            values={decks}
          />
        </Box>
      </Box>
      <TableContainer>
        <Table className={classes.wordsTable}>
          <TableBody>
            {Object.keys(allWords)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((objectKey) => {
                /** @type {WordInWordList[]} */
                const similarWords = allWords[objectKey];
                const learned = similarWords.filter((word) => word.currentLearned);

                return (
                  <TableRow
                    key={objectKey}
                    hover
                    className={classes.tableRow}
                    onClick={handleTableRowClick(similarWords)}
                  >
                    <TableCell>{similarWords[0].source}</TableCell>
                    <TableCell>{similarWords[0].translation}</TableCell>
                    <TableCell style={{ width: 80 }} align="right">
                      {similarWords.length > 1 && <Lines className={classes.icon} alt="lines icon" />}
                    </TableCell>
                    <TableCell style={{ width: 60 }}>
                      {learned.length > 0 && learned.length < similarWords.length && (
                        <Sector className={classes.icon} alt="sector icon" />
                      )}
                      {learned.length === similarWords.length && (
                        <Check className={classes.checkIcon} alt="check icon" />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 50, 100, 500, 1000]}
        component="div"
        count={Object.keys(allWords).length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <WordVariantsDialog
        decks={decksForDialog}
        words={wordsForDialog}
        open={wordsForDialog}
        onClose={closeDialogHandler}
      />
    </>
  );
};

export default Dictionary;
