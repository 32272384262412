import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { useTranslate } from "react-polyglot";
import { DEFAULT_LEARNED_PATCH } from "../../../../Providers/Data/LearnProcessProvider.jsx";
import QuestionExercise6 from "./SharedComponents/QuestionExercise6.jsx";

/**
 * @param {ExerciseType6} exercise
 * @param {Function} setCheckButtonDisabled
 * @param {String} exerciseTitle
 * @param {MutableRefObject<Function>} checkAnswerRef
 * @param {Object} rest
 * @returns {*}
 * @constructor
 */
const Exercise6 = ({ exercise, setCheckButtonDisabled, exerciseTitle, checkAnswerRef, ...rest }) => {
  const t = useTranslate();

  /** @type {ExerciseType6Answer} */
  const answer = exercise.answer || exercise;

  // update check button state
  useEffect(() => {
    const handle = setTimeout(() => {
      setCheckButtonDisabled(false);
    }, 100);

    return () => clearTimeout(handle);
  });

  checkAnswerRef.current = () => {
    return DEFAULT_LEARNED_PATCH;
  };

  return (
    <Box {...rest} data-component="Exercise6" display="flex" flexDirection="column" alignItems="center" height="100%">
      <QuestionExercise6
        exerciseQuestion={exercise.question}
        exerciseTitle={exerciseTitle}
        defaultTitle={t("Exercises.type6.title")}
        exerciseAnswer={answer}
        disableBottomSeparator
      />
    </Box>
  );
};

Exercise6.propTypes = {
  checkAnswerRef: PropTypes.object.isRequired,
  exercise: PropTypes.object.isRequired,
  exerciseTitle: PropTypes.string,
  setCheckButtonDisabled: PropTypes.func.isRequired,
};

export default Exercise6;
