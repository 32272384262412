import { withStyles } from "@material-ui/core";
import CustomFab from "./CustomFab.jsx";

const PrimaryFab = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}))(CustomFab);

export default PrimaryFab;
