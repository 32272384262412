import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslate } from "react-polyglot";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchToken, isNotificationsSupported, onMessageListener } from "../Utils/firebase.js";
import { useBaseData } from "../Providers/Data/BaseDataProvider.jsx";
import useDateUtils from "../hooks/useDateUtils.js";

/** */
const PushNotification = () => {
  const { setFirebaseToken } = useBaseData();
  const [fToken, setFToken] = useState("");
  const t = useTranslate();
  const { format } = useDateUtils();

  useEffect(() => {
    if (!isNotificationsSupported()) return;

    if (Notification.permission === "granted") {
      fetchToken(setFToken).catch((e) => console.error(e));
    }
  }, [fToken]);

  useEffect(() => {
    if (fToken) {
      setFirebaseToken(fToken);
      console.log("Notification permission enabled 👍");
    } else console.log("Need notification permission ❗");
  }, [fToken]);

  onMessageListener()
    .then((payload) => {
      const dataTostify = JSON.parse(payload.data.data);
      let notificationTitle = "";
      let body = "";
      const dateNow = Date.now();
      let date = new Date(dateNow);
      switch (payload.data.type) {
        case "event":
          date = new Date(dataTostify.date);
          t("Events.startTime");
          notificationTitle = `${t("notificationsTypes.event")}: ${dataTostify.name}`;
          body = `${t("Events.startTime")} ${format(date, "EEEEEE")} ${format(date, "dd")} ${format(date, "HH:mm")}`;
          break;
        case "invoice":
          date = new Date(dataTostify.statusDate);
          notificationTitle = `${t("notificationsTypes.invoice")}: ${dataTostify.name}`;
          body = `Status ${dataTostify.status} ${format(date, "EEEEEE")} ${format(date, "dd")} ${format(
            date,
            "HH:mm",
          )}`;
          break;
        case "news":
          notificationTitle = `${t("notificationsTypes.news")}: ${dataTostify.name}`;
          body = `${dataTostify.content.slice(0, 31)}...`;
          break;
        case "homework":
          notificationTitle = `${t(`notificationsTypes.type.${dataTostify.homework.type}`)}`;
          body = `${t(`status.${dataTostify.homework.status}`)}`;
          break;
        case "homework_todo":
          notificationTitle = `${t(`notificationsTypes.type.${dataTostify.homework.type}`)}`;
          body = `${t(`status.${dataTostify.homework.status}`)}`;
          break;
        default:
          notificationTitle = "test";
          body = "test";
          break;
      }
      toast(<ToastifyNotification title={notificationTitle} body={body} />);
      console.log(payload);
    })
    .catch((err) => console.log("failed: ", err));

  /**
   * @param {String} title
   * @param {String} body
   */
  const ToastifyNotification = ({ title, body }) => (
    <div className="push-notification">
      <h2 className="push-notification-title">{title}</h2>
      <p className="push-notification-text">{body}</p>
    </div>
  );

  ToastifyNotification.propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
  };

  return <ToastContainer hideProgressBar />;
};

export default PushNotification;
