import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { useTranslate } from "react-polyglot";
import FlexboxVertical from "../../SharedComponents/FlexboxVertical.jsx";
import { useBaseData } from "../../Providers/Data/BaseDataProvider.jsx";
import useTitle from "../../hooks/useTitle.js";
import useCompanyPublicId from "../../hooks/useCompanyPublicId.js";
import useFavicon from "../../hooks/useFavicon.js";

const useStyles = makeStyles(() => ({
  iframe: {
    width: "100%",
    height: "calc(100vh - 83px)",
    marginBottom: 20,
    border: "none",
  },
}));

/** */
const PayWebview = () => {
  const { companyName, domain } = useCompanyPublicId();
  const classes = useStyles();
  const history = useHistory();
  const t = useTranslate();
  const {
    payHtml,
    requestPaymentHtml,
    preselectedSubject,
    preselectedOffer,
    chooseSubject,
    company,
    subject,
  } = useBaseData();
  const [isPaymentSuccessful, setPaymentSuccessful] = useState(false);

  useTitle("title.pay", { school: (company && company.name) || companyName || domain });

  useFavicon();

  useEffect(() => {
    if (preselectedSubject) {
      requestPaymentHtml(preselectedSubject.subjectId, "subject").then();
    }

    if (preselectedOffer) {
      requestPaymentHtml(preselectedOffer, "offer").then();
    }
    /** @param {String|*} data*/
    const receiveMessage = ({ data }) => {
      if (data === "Payment successful") {
        setPaymentSuccessful(true);
      }
    };

    window.addEventListener("message", receiveMessage, false);
    return () => {
      window.removeEventListener("message", receiveMessage, false);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** */
  const nextButtonHandler = async () => {
    if (await chooseSubject(preselectedSubject.subjectId)) {
      history.replace("/");
    }
  };

  /** */
  const skipButtonHandler = async () => {
    if (!subject) {
      history.push("/subjects");
    } else {
      history.push("/profile");
    }
  };

  return (
    <FlexboxVertical data-component="PayWebview" alignItems="center">
      {payHtml && <iframe className={classes.iframe} src={payHtml} />}
      {isPaymentSuccessful && (
        <Button variant="contained" color="primary" onClick={nextButtonHandler}>
          {t("next")}
        </Button>
      )}
      {payHtml && !isPaymentSuccessful && (
        <Button variant="contained" color="primary" onClick={skipButtonHandler}>
          {!subject ? t("skip") : t("back")}
        </Button>
      )}
    </FlexboxVertical>
  );
};

export default PayWebview;
