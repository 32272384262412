import React from "react";
import { Box, makeStyles, withStyles } from "@material-ui/core";
import { useTranslate } from "react-polyglot";
import Typography from "@material-ui/core/Typography";
import grey from "@material-ui/core/colors/grey.js";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import { useHistory } from "react-router-dom";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import SkillzrunSwitch from "../../../SharedComponents/SkillzrunSwitch.jsx";
import { useLearnProcess } from "../../../Providers/Data/LearnProcessProvider.jsx";
import GreyButton from "../../../SharedComponents/GreyButton.jsx";
import PrimaryRoundedButton from "../../../SharedComponents/PrimaryRoundedButton.jsx";
import AudioPlayer from "../../../SharedComponents/AudioPlayer.jsx";
import ProgressTypography from "../../../SharedComponents/ProgressTypography.jsx";
import AddPointsAnimation from "./SharedComponents/AddPointsAnimation.jsx";
import FlexboxVertical from "../../../SharedComponents/FlexboxVertical.jsx";
import { useCourseProvider } from "../../../Providers/Data/CourseProvider.jsx";

const SourceTypography = withStyles({
  root: {
    fontWeight: 400,
    textAlign: "center",
    margin: "0 10px 0",
  },
})(Typography);

const TranslationTypography = withStyles({
  root: {
    textAlign: "center",
    margin: "0 10px 0",
  },
})(Typography);

const useStyles = makeStyles(() => ({
  switchBox: {
    margin: "20px 0 0",
  },
  button: {
    minWidth: 305,
  },
  arrowButtons: {
    position: "absolute",
    width: 50,
    height: 50,
    minWidth: "unset",
  },
  nextButton: {
    right: 0,
  },
  image: {
    maxHeight: 200,
    objectFit: "contain",
  },
  learnStatusIcon: {
    position: "absolute",
    bottom: 100,
    right: 10,
  },
  colorPink: {
    color: "#EB4893",
  },
}));

/** */
const WordsLearning = () => {
  const classes = useStyles();
  const { word, isExpertMode, setExpertMode, learnWord, specifiedWordId, requestOtherWord } = useLearnProcess();
  const { currentDeck } = useCourseProvider();
  const t = useTranslate();
  const history = useHistory();

  /** @param {Boolean} isExpert */
  const switchHandler = (isExpert) => {
    setExpertMode(isExpert);
  };

  /** @param {Number} direction */
  const arrowButtonsHandler = (direction) => () => {
    if (specifiedWordId) {
      history.push(`/learn/${currentDeck.id}`);
    }
    requestOtherWord(direction).then();
  };

  if (!word || !word.deck) {
    return null;
  }

  const progressText = `${word.deck.learnedCount} / ${word.deck.count}`;

  return (
    <Box display="flex" flexGrow={1} flexDirection="column" data-component="WordsLearning" position="relative">
      {/* Main content */}
      <Box bgcolor={grey[50]} display="flex" flexDirection="column" alignItems="center" flexGrow={1}>
        <SkillzrunSwitch
          className={classes.switchBox}
          textLeft={t("easy")}
          textRight={t("expert")}
          isRight={isExpertMode}
          onChanged={switchHandler}
        />
        {/* Word content */}
        <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="space-evenly" alignItems="center">
          <SourceTypography variant="h2" style={{ fontSize: word.word.phrase ? 30 : 60 }}>
            {word.word.source}
          </SourceTypography>
          {word.word.image && <img className={classes.image} src={word.word.image.url} alt={word.word.image.name} />}
          {word.word.transcription && !isExpertMode && (
            <TranslationTypography variant="body1">{word.word.transcription}</TranslationTypography>
          )}
          {word.word.translation && !isExpertMode && (
            <TranslationTypography variant="body1">{word.word.translation}</TranslationTypography>
          )}
          {word.word.audio && <AudioPlayer src={word.word.audio.url} />}
        </Box>
      </Box>
      {word.word.passed && word.word.learned && (
        <CheckCircleRoundedIcon color="primary" className={classes.learnStatusIcon} />
      )}
      {word.word.passed && !word.word.learned && (
        <CancelRoundedIcon className={clsx(classes.learnStatusIcon, classes.colorPink)} />
      )}
      <Button className={classes.arrowButtons} color="primary" onClick={arrowButtonsHandler(-1)}>
        <ArrowBackIosRoundedIcon />
      </Button>
      <Button
        className={clsx(classes.arrowButtons, classes.nextButton)}
        color="primary"
        onClick={arrowButtonsHandler(1)}
      >
        <ArrowForwardIosRoundedIcon />
      </Button>
      {/* Bottom buttons */}
      <Box display="flex" justifyContent="space-between" alignItems="center" margin="15px 0 0">
        <GreyButton className={classes.button} onClick={() => learnWord(false)}>
          {t("wordToLearn")}
        </GreyButton>
        <FlexboxVertical alignItems="center">
          <ProgressTypography color="primary">{progressText}</ProgressTypography>
          <Typography color="primary">{t("completed")}</Typography>
        </FlexboxVertical>
        <AddPointsAnimation>
          <PrimaryRoundedButton className={classes.button} onClick={() => learnWord(true)}>
            {t("iKnowWord")}
          </PrimaryRoundedButton>
        </AddPointsAnimation>
      </Box>
    </Box>
  );
};

export default WordsLearning;
