import React, { useEffect, useState } from "react";
import { useTranslate } from "react-polyglot";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles, withStyles } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import Typography from "@material-ui/core/Typography";
import FlexboxVertical from "../../../SharedComponents/FlexboxVertical.jsx";
import ToDoList from "./ToDoList.jsx";
import FinishedList from "./FinishedList.jsx";
import useMediaQueryUp from "../../../hooks/useMediaQueryUp.js";
import FlexboxHorizontal from "../../../SharedComponents/FlexboxHorizontal.jsx";

const StyledTabs = withStyles(() => ({
  indicator: {
    display: "none",
  },
}))(Tabs);

const StyledTab = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    color: "white",
    fontSize: "1rem",
    transitionProperty: "background",
    transitionDuration: theme.transitions.duration.standard,
  },
  selected: {
    backgroundColor: theme.palette.primary.dark,
  },
}))(Tab);

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.dark,
    height: 48,
    alignItems: "center",
  },
  headerItem: {
    minWidth: "calc(50% - 1.5px)",
    textAlign: "center",
    textTransform: "uppercase",
    color: "white",
  },
  separator: {
    backgroundColor: theme.palette.primary.light,
    height: "100%",
    width: 3,
  },
  lists: {
    position: "relative",
    justifyContent: "space-between",
    "&>*": {
      width: "calc(50% - 1.5px)",
    },
    "&>$separator": {
      position: "absolute",
      width: 3,
      top: 0,
      left: "calc(50% - 1.5px)",
    },
  },
}));

/** */
const Homeworks = () => {
  const classes = useStyles();
  const t = useTranslate();
  const [tabIndex, setTabIndex] = useState(0);
  const simpleMode = useMediaQueryUp("lg");

  // this is the bug fix in the react-swipeable-views component
  useEffect(() => {
    /** @type {HTMLElement} */
    const swipeableViewContainer = document.querySelector(".react-swipeable-view-container");
    if (swipeableViewContainer) {
      swipeableViewContainer.style.transition = "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s";
    }
  }, [simpleMode]);

  /** */
  const tabChangeHandler = (_, newVal) => {
    setTabIndex(newVal);
  };

  /** */
  const tabChangeBySwipe = (newVal) => {
    setTabIndex(newVal);
  };

  return (
    <FlexboxVertical data-component="Homeworks" marginTop="25px">
      {!simpleMode && (
        <>
          <StyledTabs value={tabIndex} onChange={tabChangeHandler} variant="fullWidth">
            <StyledTab label={t("homeworks.todo")} />
            <StyledTab label={t("homeworks.done")} />
          </StyledTabs>
          <SwipeableViews index={tabIndex} disableLazyLoading onChangeIndex={tabChangeBySwipe}>
            <ToDoList />
            <FinishedList />
          </SwipeableViews>
        </>
      )}
      {simpleMode && (
        <>
          <FlexboxHorizontal className={classes.header}>
            <Typography className={classes.headerItem}>{t("homeworks.todo")}</Typography>
            <div className={classes.separator} />
            <Typography className={classes.headerItem}>{t("homeworks.done")}</Typography>
          </FlexboxHorizontal>
          <FlexboxHorizontal className={classes.lists}>
            <ToDoList />
            <div className={classes.separator} />
            <FinishedList />
          </FlexboxHorizontal>
        </>
      )}
    </FlexboxVertical>
  );
};

export default Homeworks;
