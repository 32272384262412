import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslate } from "react-polyglot";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import lodash from "lodash";
import FlexboxVertical from "../../../../SharedComponents/FlexboxVertical.jsx";
import FlexboxHorizontal from "../../../../SharedComponents/FlexboxHorizontal.jsx";
import ChipArabic from "../../../../SharedComponents/ChipArabic.jsx";
import { DEFAULT_LEARNED_PATCH } from "../../../../Providers/Data/LearnProcessProvider.jsx";
import Question from "./SharedComponents/Question.jsx";
import Appendix from "./SharedComponents/Appendix.jsx";

const useStyles = makeStyles(() => ({
  wordsContainer: {
    flexGrow: 1,
    flexBasis: "50%",
    flexWrap: "wrap",
    alignContent: "flex-start",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: 10,
    margin: "5px 20px 5px",
    backgroundColor: "white",
    padding: 5,
    alignSelf: "stretch",
    minHeight: 50,
    "&>*": {
      margin: 5,
    },
  },
  textHint: {
    width: "100%",
    textAlign: "center",
    marginTop: 8,
  },
  rightAnswerChipsWrapper: {
    flexWrap: "wrap",
    "&>div": {
      margin: "0 10px 10px 0",
    },
  },
}));

/**
 * @param {ExerciseType5Legacy|ExerciseType5} exercise
 * @param {Function} setCheckButtonDisabled
 * @param {String} exerciseTitle
 * @param {MutableRefObject<Function>} checkAnswerRef
 * @param {FnComponentStringVoid} showRightAnswer
 * @param {Object} rest
 * @returns {*}
 * @constructor
 */
const Exercise5 = ({ exercise, setCheckButtonDisabled, exerciseTitle, checkAnswerRef, showRightAnswer, ...rest }) => {
  const classes = useStyles();
  const t = useTranslate();
  const [userSelectedWords, setUserSelectedWords] = useState([]);
  const [inputWords, setInputWords] = useState([]);

  /** @type {ExerciseType5Legacy|ExerciseType5Answer} */
  const answer = exercise.answer || exercise;

  //reset state
  useEffect(() => {
    setInputWords([...answer.inputWords]);
    setUserSelectedWords([]);
  }, [answer]);

  // manage "check button" state
  useEffect(() => {
    setCheckButtonDisabled(userSelectedWords.length === 0);
  }, [setCheckButtonDisabled, userSelectedWords]);

  checkAnswerRef.current = async () => {
    const usersAnswerValue = userSelectedWords.join(", ");
    const learned =
      userSelectedWords.length === answer.words.length &&
      lodash.difference(userSelectedWords, answer.words).length === 0;
    if (!learned) {
      showRightAnswer(
        <FlexboxHorizontal className={classes.rightAnswerChipsWrapper}>
          {answer.words.map((word, index) => (
            <ChipArabic key={word + index} label={word} />
          ))}
        </FlexboxHorizontal>,
        usersAnswerValue,
      );
      return null;
    }
    return { ...DEFAULT_LEARNED_PATCH, payload: { answerValue: usersAnswerValue } };
  };

  /**
   * @param {String} word
   * @param {number} i
   */
  const handleInputWordClick = (word, i) => () => {
    setUserSelectedWords([...userSelectedWords, word]);
    inputWords.splice(i, 1);
    setInputWords([...inputWords]);
  };

  /**
   * @param {String} word
   * @param {number} i
   */
  const handleSelectedWordClick = (word, i) => () => {
    setInputWords([...inputWords, word]);
    userSelectedWords.splice(i, 1);
    setUserSelectedWords([...userSelectedWords]);
  };

  return (
    <FlexboxVertical {...rest} data-component="Exercise5" alignItems="center" height="100%">
      <Question
        exerciseQuestion={exercise.question}
        exerciseTitle={exerciseTitle}
        defaultTitle={t("Exercises.type5.title")}
      />
      {exercise.appendix && exercise.appendix.pdf && <Appendix pdfUrl={exercise.appendix.pdf.url} />}
      <FlexboxHorizontal className={classes.wordsContainer}>
        {userSelectedWords.length === 0 && (
          <Typography className={classes.textHint} color="textSecondary">
            {t("Exercises.type5.hint")}
          </Typography>
        )}
        {userSelectedWords.map((word, i) => (
          <ChipArabic key={word + i} label={word} onClick={handleSelectedWordClick(word, i)} />
        ))}
      </FlexboxHorizontal>
      <FlexboxHorizontal className={classes.wordsContainer}>
        {inputWords.map((word, i) => (
          <ChipArabic key={word + i} label={word} onClick={handleInputWordClick(word, i)} />
        ))}
      </FlexboxHorizontal>
    </FlexboxVertical>
  );
};

Exercise5.propTypes = {
  checkAnswerRef: PropTypes.object.isRequired,
  exercise: PropTypes.object.isRequired,
  exerciseTitle: PropTypes.string,
  setCheckButtonDisabled: PropTypes.func.isRequired,
  showRightAnswer: PropTypes.func.isRequired,
};

export default Exercise5;
