import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import React from "react";
import icClock from "../assets/ic_time.svg";

const useStyles = makeStyles(
  () => ({
    root: {
      minWidth: ({ width }) => width,
      maxWidth: ({ width }) => width,
      justifyContent: "flex-start",
    },
    label: {
      fontWeight: 600,
      paddingRight: 5,
    },
  }),
  {
    name: "TimerChip",
  },
);

/**
 * @param {String} label
 * @param {Object} classes
 * @param {Object} rest
 */
const TimerChip = ({ label, classes, ...rest }) => {
  const _classes = useStyles({ width: label.length > 5 ? 100 : 80 });

  return (
    <Chip
      {...rest}
      label={label}
      classes={{ ...classes, root: _classes.root, label: _classes.label }}
      avatar={<img src={icClock} alt="" />}
      variant="outlined"
    />
  );
};

TimerChip.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string.isRequired,
};

export default TimerChip;
