import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-polyglot";
import { Button, Card, CardActions, CardContent, CardHeader, Typography } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { useAuth } from "../../../Providers/Auth/AuthProvider.jsx";
import { useBaseData } from "../../../Providers/Data/BaseDataProvider.jsx";
import FlexboxHorizontal from "../../../SharedComponents/FlexboxHorizontal.jsx";
import LockIcon from "../../../SharedComponents/LockIcon.jsx";

const useStyles = makeStyles((theme) => ({
  cardHead: {
    color: theme.palette.primary.dark,
    textAlign: "left",
    "& .MuiCardHeader-content p": {
      fontWeight: 700,
    },
  },
  details: {
    alignItems: "center",
    flexWrap: "nowrap",
    columnGap: 30,
  },
  logo: {
    width: 160,
    height: 160,
    objectFit: "contain",
  },
  description: {
    textAlign: "left",
    wordBreak: "break-word",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 4,
    "-webkit-box-orient": "vertical",
  },
  actions: {
    padding: 0,
  },
  subjectBtn: {
    marginTop: 20,
    paddingLeft: 45,
    paddingRight: 45,
  },
  disabledBtn: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.background.default,
    opacity: 1,
    "&::after": {
      content: '"$"',
      display: "block",
      height: 25,
      width: 25,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      left: 10,
      fontSize: 18,
      fontWeight: 700,
    },
    "&.MuiInput-underline:after": {
      left: 10,
      borderBottom: "none",
    },
    "&.MuiInput-underline.Mui-focused:after": {
      transform: "translateY(-50%)",
    },
  },
}));

/**
 * @param {CompanySubject} subject
 * @param {function} onClick
 * @return {*}
 * @constructor
 */
const SubjectCard = ({ subject, onClick }) => {
  const t = useTranslate();
  const classes = useStyles();
  const { company2 } = useAuth();
  const { company } = useBaseData();

  const company_ = company || company2;

  return (
    <Grid item xs={12} md={6}>
      <Card variant="outlined" className={classes.card}>
        <CardHeader
          className={classes.cardHead}
          title={<Typography>{`${t("subject")}: ${subject.name}`}</Typography>}
        />
        <CardContent>
          <FlexboxHorizontal className={classes.details}>
            <div>
              <img
                className={classes.logo}
                src={subject.illustrationImageUrl || subject.illustrationImage?.url || company_?.logo.url}
                alt="logo"
              />
            </div>
            <Typography className={classes.description} variant="body1">
              {subject.description || t("noDescription")}
            </Typography>
          </FlexboxHorizontal>
        </CardContent>
        <CardActions disableSpacing className={classes.actions}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            className={clsx(classes.subjectBtn, {
              [classes.disabledBtn]: subject.price > 0 && !subject.purchased,
            })}
            onClick={onClick}
          >
            {t("start")}
            {subject.available === false && <LockIcon />}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

SubjectCard.propTypes = {
  subject: PropTypes.object,
  onClick: PropTypes.func,
};

export default SubjectCard;
