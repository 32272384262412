import { useEffect } from "react";
import { useTranslate } from "react-polyglot";
import { useBaseData } from "../Providers/Data/BaseDataProvider.jsx";
import useCompanyPublicId from "./useCompanyPublicId.js";

/**
 * @param {String} text
 * @param {Object} [args]
 */
const useCompanyTitle = (text, args) => {
  const t = useTranslate();
  const { company } = useBaseData();
  const { companyName, domain } = useCompanyPublicId();

  useEffect(() => {
    let schoolName = company.name;
    if (!companyName && !domain) {
      if (args && args.school) {
        args.school = `Skillzrun - ${args.school}`;
      } else {
        schoolName = `Skillzrun - ${schoolName}`;
      }
    }
    document.title = t(text, { school: schoolName, ...args });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, args]);
};

export default useCompanyTitle;
