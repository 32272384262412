import { Typography, withStyles } from "@material-ui/core";

const ExerciseTitle = withStyles(() => ({
  root: {
    fontSize: 18,
    fontWeight: 500,
    textAlign: "center",
    margin: "37px 20px 0",
  },
}))(Typography);

export default ExerciseTitle;
