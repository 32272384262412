import { useEffect } from "react";

/** @param {Function} effect
 * @param {Array} deps
 */
function useEffectAsync(effect, deps) {
  useEffect(() => {
    effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export default useEffectAsync;
