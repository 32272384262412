import React, { Children, cloneElement, isValidElement } from "react";
import * as PropTypes from "prop-types";
import { Form } from "react-final-form";

/**
 * @param {Function} onSubmit
 * @param {Function} validate
 * @param {String} formClassName
 * @param {React.ReactNode|HTMLElement} children
 * @param {Object} rest
 * @returns {*}
 * @constructor
 */
const ReactFinalForm = ({ onSubmit, validate, formClassName, children, ...rest }) => {
  return (
    <Form
      {...rest}
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className={formClassName}>
          {Children.map(children, (child) => (isValidElement(child) ? cloneElement(child) : null))}
        </form>
      )}
    />
  );
};

ReactFinalForm.propTypes = {
  onSubmit: PropTypes.func,
  validate: PropTypes.func,
  formClassName: PropTypes.string,
};

export default ReactFinalForm;
