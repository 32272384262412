import { Base64 } from "js-base64";

/**
 * @returns {Boolean}
 */
String.prototype.isArabic = function () {
  return !!this.match(/[\u0621-\u064A]+/);
};

/**
 * @param {String} substr
 * @returns {Boolean}
 */
String.prototype.contains = function (substr) {
  return this.indexOf(substr) !== -1;
};

/**
 * @param {Number} maxSize
 * @returns {String}
 */
String.prototype.clampString = function (maxSize) {
  return this.length > maxSize ? this.substring(0, maxSize - 3) + "..." : this;
};

/**
 * @returns {string}
 */
String.prototype.base64Src = function () {
  return `data:text/html;charset=utf-8;base64,${Base64.encode(this)}`;
};

/**
 * @param {string} other
 * @returns {boolean}
 */
String.prototype.equalsNoApostropheSensitive = function (other) {
  // eslint-disable-next-line no-misleading-character-class
  const allApostrophes = /[`´ʹʻʼʽˈˊˋˑ˙̀́ʹ΄҅҆ՙ՚՛՝᾽᾿`´῾‘’′ꜞꞋꞌ]/g;
  return (
    this.replace(allApostrophes, "'").toUpperCase().trim().replace(/\s+/g, " ") ===
    other.replace(allApostrophes, "'").toUpperCase().trim().replace(/\s+/g, " ")
  );
};
