import React from "react";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import { Box, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CardActionArea from "@material-ui/core/CardActionArea";
import LockIcon from "../../../SharedComponents/LockIcon.jsx";
import { useDeckData } from "../../../Providers/Data/DeckDataProvider.jsx";
import { useBaseData } from "../../../Providers/Data/BaseDataProvider.jsx";
import LinearProgressBar from "../../../SharedComponents/LinearProgressBar.jsx";
import CircularProgressWithLabel from "../../../SharedComponents/CircularProgressWithLabel.jsx";

const useStyles = makeStyles({
  cardRoot: {
    width: 194,
    height: 194,
    margin: 11,
    borderRadius: 10,
    position: "relative",
    backgroundImage: ({ illustrationImage }) =>
      illustrationImage ? `linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.00) 55%), url(${illustrationImage})` : null,
    backgroundSize: "cover",
    backgroundPosition: "bottom, center",
  },
  cardContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  circulaProgress: {
    margin: "auto 0",
  },
  linearProgress: {
    width: "100%",
    margin: "auto 0 0",
  },
  deckName: {
    textAlign: "center",
    padding: "8px 0 0",
    color: ({ illustrationImage }) => (illustrationImage ? "white" : "black"),

    "& .MuiTypography-root": {
      height: "40px",
      overflow: "hidden",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
    },
  },
});

/** */
const DeckCard = () => {
  const { userInfo } = useBaseData();
  const { deck, branch, onCardClick, progressValue } = useDeckData();
  const illustrationImage = (deck.illustrationImageUrl || deck.illustrationImage?.url)?.replace(/\\/g, "/");
  const classes = useStyles({ illustrationImage });

  return (
    <Card className={classes.cardRoot} elevation={3}>
      <CardActionArea onClick={onCardClick}>
        <CardContent className={classes.cardContent}>
          {!illustrationImage && (
            <CircularProgressWithLabel
              className={classes.circulaProgress}
              value={deck.percent}
              textValue={progressValue}
            />
          )}
          {illustrationImage && (
            <LinearProgressBar
              variant="determinate"
              className={classes.linearProgress}
              value={deck.percent}
              radius="0px"
              height={10}
              noborder={illustrationImage}
            />
          )}
          <Box className={classes.deckName}>
            <Typography variant="caption">{deck.name}</Typography>
          </Box>
        </CardContent>
      </CardActionArea>
      {(!branch.open || !deck.open || !(userInfo.isActive || userInfo.isDemoMode)) && <LockIcon />}
    </Card>
  );
};

export default DeckCard;
