import { useEffect } from "react";
import useCompanyPublicId from "./useCompanyPublicId.js";
import { useAuth } from "../Providers/Auth/AuthProvider.jsx";
import { useBaseData } from "../Providers/Data/BaseDataProvider.jsx";
import { IS_APP_STUDYLESS } from "../constants.js";

/** */
const useFavicon = () => {
  const { companyName, domain } = useCompanyPublicId();
  const { company2 } = useAuth();
  const { company } = useBaseData();

  const company_ = company || company2;

  useEffect(() => {
    if (companyName || domain || IS_APP_STUDYLESS) {
      let linkIcon = document.querySelector("link[rel~='icon']");
      let linkApple = document.querySelector("link[rel~='apple-touch-icon']");
      if (!linkIcon) {
        linkIcon = document.createElement("link");
        linkIcon.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(linkIcon);
      }

      if (!linkApple) {
        linkApple = document.createElement("link");
        linkApple.rel = "apple-touch-icon";
        linkApple.sizes = "192x192";
        document.getElementsByTagName("head")[0].appendChild(linkIcon);
      }

      if (companyName || domain) {
        linkIcon.href = company_ ? company_.logo.url : "/favicon.ico";
        linkApple.href = company_ ? company_.logo.url : "/logo192.png";
        // eslint-disable-next-line prettier/prettier
      } else {
        // IS_APP_STUDYLESS
        linkIcon.href = "/study-less-192x192.png";
        linkApple.href = "/study-less-icon.png";
      }
    }
  }, [company_, companyName, domain]);
};

export default useFavicon;
