import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.getContrastText(theme.palette.error.main),
    "&.error:hover": {
      backgroundColor: theme.palette.error.main,
    },
  },
}));

const StyledButton = withStyles((theme) => ({
  root: {
    textTransform: "none",
  },
  outlinedSizeSmall: {
    borderRadius: 15,
  },
  outlined: {
    borderRadius: 18,
  },
  containedSizeSmall: {
    borderRadius: 15,
  },
  contained: {
    borderRadius: 18,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  containedPrimary: {
    "& .MuiButton-label": {
      fontWeight: 500,
      fontSize: "0.875rem",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))(Button);

/**
 * @param {Boolean} value
 * @param {Function} onChange
 * @param {Boolean} error
 * @param {String} color
 * @param {String} className
 * @param {Object} rest
 * @returns {*}
 * @constructor
 */
const ToggleButton = ({ value, onChange, error, color, className, ...rest }) => {
  const classes = useStyles();
  const buttonRef = useRef(/** @type {HTMLElement} */ null);
  const [minHeight, setMinHeight] = useState(36);
  const [minWidth, setMinWidth] = useState(36);

  useEffect(() => {
    const domRect = buttonRef.current.getBoundingClientRect();
    setMinHeight(domRect.height);
    setMinWidth(domRect.width);
  }, [value]);

  return (
    <StyledButton
      ref={buttonRef}
      {...rest}
      style={{ minHeight, minWidth }}
      className={clsx({ [classes.error]: error, error }, className)}
      variant={value ? "contained" : "outlined"}
      color={value ? color : "default"}
      onClick={onChange}
    />
  );
};

ToggleButton.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
};

ToggleButton.defaultProps = {
  color: "secondary",
  error: false,
};

export default ToggleButton;
