import { useTranslate } from "react-polyglot";
import React from "react";
import PropTypes from "prop-types";
import { useBaseData } from "../../../Providers/Data/BaseDataProvider.jsx";
import RedButton from "../../../SharedComponents/RedButton.jsx";

/**
 * @param {Offer} offer
 * @param {UserInfo} user
 * @param {boolean} offerProceed
 * @param {*} res
 * @return {null|*}
 * @constructor
 */
const DiscardButton = ({ offer, user, offerProceed, ...res }) => {
  const t = useTranslate();
  const { discardOffer } = useBaseData();

  if (!user) {
    return null;
  }

  if (!offerProceed) {
    return null;
  }

  return (
    <RedButton {...res} onClick={() => discardOffer(offer.id)}>
      {t("cancel")}
    </RedButton>
  );
};

DiscardButton.propTypes = {
  offer: PropTypes.object,
  offerProceed: PropTypes.bool,
  user: PropTypes.object,
};

export default DiscardButton;
