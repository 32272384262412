import React, { createContext, useContext, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import MySnackbar from "./MySnackbar.jsx";

const useAlertStyles = makeStyles(() => ({
  actionsRoot: {
    justifyContent: "center",
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  titledDialogContent: {
    marginBottom: 15,
    paddingBottom: 16,
  },
}));

/**
 * @param {Boolean} open
 * @param {Function} handleClose
 * @param {String} title
 * @param {String} message
 * @param {React.Component} content
 * @param {String} buttonText
 * @param {Boolean} disableEscape
 * @param {String} maxWidth
 * @returns {*}
 * @constructor
 */
const Alert = ({ open, handleClose, title, message, content, buttonText, disableEscape, maxWidth }) => {
  const classes = useAlertStyles();

  return (
    <Dialog
      maxWidth={maxWidth}
      disableBackdropClick={disableEscape}
      disableEscapeKeyDown={disableEscape}
      open={open}
      onClose={handleClose ? () => handleClose(false) : undefined}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent className={clsx({ [classes.titledDialogContent]: !!title }, classes.dialogContent)}>
        {message && <Typography>{message}</Typography>}
        {content}
      </DialogContent>
      <DialogActions className={classes.actionsRoot}>
        <Button
          onClick={handleClose ? () => handleClose(true) : undefined}
          color="secondary"
          fullWidth
          autoFocus
          variant="contained"
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Alert.propTypes = {
  buttonText: PropTypes.string,
  content: PropTypes.node,
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.string,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  disableEscape: PropTypes.bool,
  maxWidth: PropTypes.string,
};

Alert.defaultProps = {
  buttonText: "OK",
  disableEscape: true,
  maxWidth: "xs",
};

/**
 * @typedef {Object} SnackbarProviderContext
 * @property {FnSnackbar} error
 * @property {FnSnackbar} warn
 * @property {FnSnackbar} info
 * @property {FnSnackbar} success
 * @property {FnAlert} alert
 */

/**
 * @callback FnAlert
 * @param {AlertOptions} options
 */

/**
 * @callback FnSnackbar
 * @param {String} msg
 * @param {{vertical: String, horizontal: String}} [pos]
 * @returns {void}
 */

/**
 * @typedef {Object} AlertOptions
 * @property {Function} [onClose]
 * @property {String} [title]
 * @property {String} [message]
 * @property {React.ReactNode|HTMLElement} [content]
 * @property {String} [buttonText]
 * @property {Boolean} [disableEscape]
 * @property {string} [maxWidth]
 */

/** @type {React.Context<SnackbarProviderContext>} */
const dialogsContext = createContext({
  /** @type {FnSnackbar} */
  error: () => {},
  /** @type {FnSnackbar} */
  warn: () => {},
  /** @type {FnSnackbar} */
  info: () => {},
  /** @type {FnSnackbar} */
  success: () => {},
  /** @type {FnAlert} */
  alert: () => {},
});

/**
 * @returns {SnackbarProviderContext}
 */
export const useDialogs = () => useContext(dialogsContext);

/**
 * @param {React.ReactNode} children
 * @returns {*}
 * @constructor
 */
const DialogsProvider = ({ children }) => {
  const [isSnackbarOpened, setSnackbarOpened] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarPosition, setSnackbarPosition] = useState();
  const [isAlertOpened, setAlertOpened] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertButtonText, setAlertButtonText] = useState(/** @type {String|undefined} */ undefined);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertContent, setAlertContent] = useState(null);
  const [alertDisableEscape, setAlertDisableEscape] = useState(/** @type {Boolean|undefined} */ undefined);
  const [alertMaxWidth, setAlertMaxWidth] = useState("xs");
  const alertCloseHandlerRef = useRef(/** @type {?Function} */ null);
  const alertCloseTimeoutHandleRef = useRef(0);

  /** */
  const openSnackbar = (pos, type, msg) => {
    setSnackbarPosition(pos);
    setSnackbarSeverity(type || "info");
    setSnackbarMessage(msg);
    setSnackbarOpened(true);
  };

  /** @type {FnSnackbar} */
  const error = (msg, pos) => {
    openSnackbar(pos, "error", msg);
  };

  /** @type {FnSnackbar} */
  const warn = (msg, pos) => {
    openSnackbar(pos, "warning", msg);
  };

  /** @type {FnSnackbar} */
  const info = (msg, pos) => {
    openSnackbar(pos, "info", msg);
  };

  /** @type {FnSnackbar} */
  const success = (msg, pos) => {
    openSnackbar(pos, "success", msg);
  };

  /** */
  const closeSnackbar = () => {
    setSnackbarOpened(false);
    setTimeout(() => {
      setSnackbarSeverity("info");
      setSnackbarMessage("");
      setSnackbarPosition(undefined);
    }, 300);
  };

  /**
   * @type {FnAlert}
   */
  const alert = (options) => {
    const {
      onClose = null,
      title = "",
      message = "",
      content = null,
      buttonText = "OK",
      disableEscape = false,
      maxWidth = "xs",
    } = options;
    clearTimeout(alertCloseTimeoutHandleRef.current);
    alertCloseHandlerRef.current = onClose;
    setAlertTitle(title);
    setAlertMessage(message);
    setAlertContent(content);
    setAlertButtonText(buttonText);
    setAlertDisableEscape(disableEscape);
    setAlertMaxWidth(maxWidth);
    setAlertOpened(true);
  };

  /** @param {Boolean} byButton */
  const alertCloseHandler_ = (byButton) => {
    alertCloseTimeoutHandleRef.current = setTimeout(() => {
      alertCloseHandlerRef.current = null;
      setAlertDisableEscape(undefined);
      setAlertContent(null);
      setAlertMessage("");
      setAlertButtonText(undefined);
      setAlertTitle("");
      setAlertMaxWidth("xs");
    }, 300);
    setAlertOpened(false);
    alertCloseHandlerRef.current && alertCloseHandlerRef.current(byButton);
  };

  /** @type SnackbarProviderContext */
  const value = {
    error,
    warn,
    info,
    success,
    alert,
  };

  return (
    <dialogsContext.Provider value={value}>
      {children}
      <MySnackbar
        isOpen={isSnackbarOpened}
        close={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
        position={snackbarPosition}
      />
      <Alert
        title={alertTitle}
        buttonText={alertButtonText}
        message={alertMessage}
        content={alertContent}
        disableEscape={alertDisableEscape}
        open={isAlertOpened}
        handleClose={alertCloseHandler_}
        maxWidth={alertMaxWidth}
      />
    </dialogsContext.Provider>
  );
};

export default DialogsProvider;
