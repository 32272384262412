import React from "react";
import _ from "lodash";
import { Box, makeStyles, Slider, IconButton } from "@material-ui/core";
import { useField, useFormState } from "react-final-form";
import ok_emoji from "../../assets/ok_emoji.png";
import bad_emoji from "../../assets/bad_emoji.png";
import fine_emoji from "../../assets/fine_emoji.png";

const marks = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 6,
    label: "6",
  },
  {
    value: 7,
    label: "7",
  },
  {
    value: 8,
    label: "8",
  },
  {
    value: 9,
    label: "9",
  },
  {
    value: 10,
    label: "10",
  },
];

const useStyles = makeStyles({
  smileBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap",
    marginTop: 25,
  },
  emojiImg: {
    display: "block",
    width: 40,
    height: 40,
  },
  sliderBox: {
    position: "relative",
  },
});

const useDialogStyles = makeStyles({
  root: {
    width: "90%",
    marginLeft: "4%",
    marginTop: 50,
    "& .MuiSlider-mark": {
      width: 12,
      height: 12,
      backgroundColor: "#DBE4EF",
      borderRadius: "50%",
    },
    "& .MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: ({ valueRating }) => (valueRating !== 0 ? "currentcolor" : "#DBE4EF"),
    },
    "& .MuiSlider-markLabel": {
      top: -20,
      transform: "none",
    },
    "& .MuiSlider-track": {
      width: "100%",
      height: 5,
      marginTop: 4,
    },
    "& .MuiSlider-rail": {
      width: "100%",
      height: 5,
      marginTop: 4,
      backgroundColor: "#DBE4EF",
    },
    "& .MuiSlider-markLabelActive": {
      backgroundColor: "transparent",
    },
    "& .MuiSlider-thumb": {
      width: 20,
      height: 20,
      marginTop: "-5px",
      backgroundColor: ({ valueRating }) => (valueRating !== 0 ? "currentcolor" : "transparent"),
    },
    "& .MuiSlider-thumb.MuiSlider-thumbColorPrimary:hover": {
      boxShadow: ({ valueRating }) => (valueRating !== 0 ? "inherit" : "none"),
    },
  },
});

/** */
const FeedbackDialogSlider = () => {
  const classes = useStyles();
  const { input } = useField("rating");
  const formState = useFormState();
  const valueRating = _.get(formState.values, "rating");
  const dialogClasses = useDialogStyles({ valueRating });

  /**
   * @param {Object} event
   * @param {Number} value
   */
  const onChange = (event, value) => {
    input.onChange(value);
  };
  return (
    <Box className={classes.sliderBox}>
      <Box className={classes.smileBox}>
        <IconButton
          onClick={() => {
            input.onChange(1);
          }}
        >
          <img className={classes.emojiImg} src={bad_emoji} alt="bad_emoji" />
        </IconButton>
        <IconButton
          onClick={() => {
            input.onChange(5);
          }}
        >
          <img className={classes.emojiImg} src={ok_emoji} alt="ok_emoji" />
        </IconButton>
        <IconButton
          onClick={() => {
            input.onChange(10);
          }}
        >
          <img className={classes.emojiImg} src={fine_emoji} alt="fine_emoji" />
        </IconButton>
      </Box>
      <Slider
        classes={dialogClasses}
        name="rating"
        value={valueRating}
        defaultValue={0}
        step={1}
        marks={marks}
        min={1}
        max={10}
        onChange={onChange}
      />
    </Box>
  );
};

export default FeedbackDialogSlider;
