import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import Vimeo from "@u-wave/react-vimeo";
import { makeStyles } from "@material-ui/core";
import { extractVideoId, VideoType } from "../Utils/misc.js";
import YtPlayer from "./YtPlayer.jsx";

const useStyles = makeStyles(() => ({
  youtubeFrame: {
    width: "100%",
    height: ({ height }) => height || 390,
    border: "none",
    marginTop: 4,
  },
  vimeoFrame: {
    width: "100%",
    height: ({ height }) => (height && height + 4) || 394,
    "&>iframe": {
      width: "100%",
      height: ({ height }) => height || 390,
      border: "none",
      marginTop: 4,
    },
  },
  customPlayer: {
    width: "100%",
    maxHeight: ({ height }) => height || 390,
  },
}));

/**
 * @param {String} url
 * @param {FnNumberVoid} onPlayback
 * @param {String|Number} height
 * @returns {*}
 * @constructor
 */
const UniversalVideoPlayer = ({ url, onPlayback, height }) => {
  const classes = useStyles({ height });
  const { id, videoType, startTime } = extractVideoId(url);
  const simpleVideoPlayerRef = useRef(/** @type {HTMLVideoElement} */ null);

  // disable context menu on video element
  useEffect(() => {
    if (simpleVideoPlayerRef.current) {
      simpleVideoPlayerRef.current.addEventListener("contextmenu", function (e) {
        e.preventDefault();
      });
    }
  }, [videoType]);

  useEffect(() => {
    const player = simpleVideoPlayerRef.current;
    if (!player) return;

    /** */
    const eventListener = () => {
      const percent = player.currentTime / player.duration;
      onPlayback && onPlayback(percent);
    };
    player.addEventListener("timeupdate", eventListener);

    return () => {
      player.removeEventListener("timeupdate", eventListener);
    };
  }, [onPlayback]);

  /** @type {FnYtCallback} */
  const youtubePlaybackHandler = (currentTime, percent) => {
    onPlayback && onPlayback(percent);
  };

  /** @param {*} vimeo */
  const vimeoStateHandler = (vimeo) => {
    onPlayback && onPlayback(vimeo.percent);
  };

  return (
    <>
      {videoType === VideoType.YOUTUBE && (
        <YtPlayer
          className={classes.youtubeFrame}
          videoId={id}
          onPlayback={youtubePlaybackHandler}
          startTime={startTime}
        />
      )}
      {videoType === VideoType.VIMEO && (
        <Vimeo className={classes.vimeoFrame} video={url} onTimeUpdate={vimeoStateHandler} />
      )}
      {videoType === VideoType.SKILLZRUN && (
        <video
          ref={simpleVideoPlayerRef}
          className={classes.customPlayer}
          controls
          src={url}
          controlsList="nodownload"
        />
      )}
    </>
  );
};

UniversalVideoPlayer.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onPlayback: PropTypes.func,
  url: PropTypes.string.isRequired,
};

export default UniversalVideoPlayer;
