import PropTypes from "prop-types";
import React from "react";
import { Box, makeStyles, withStyles } from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslate } from "react-polyglot";
import Button from "@material-ui/core/Button";
import Flippy, { BackSide, FrontSide } from "react-flippy";
import grey from "@material-ui/core/colors/grey.js";
import { useLearnProcess } from "../../../Providers/Data/LearnProcessProvider.jsx";
import icCheckGreen from "../../../assets/ic_check_green.svg";
import icPlusPink from "../../../assets/ic_plus_pink.svg";
import useVideoThumbController from "../../../hooks/useVideoThumbController.js";
import MyScrollbars from "../../../SharedComponents/MyScrollbars.jsx";
import UniversalVideoPlayer from "../../../SharedComponents/UniversalVideoPlayer.jsx";
import SimpleCircularProgressWithLabel from "../../../SharedComponents/SimpleCircularProgressWithLabel.jsx";

const Accordion = withStyles({
  root: {
    backgroundColor: "unset",
    border: "none",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    border: "none",
    marginBottom: -1,
    minHeight: 40,
    "&$expanded": {
      minHeight: 40,
    },
  },
  content: {
    flexGrow: "unset",
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expandIcon: {
    padding: 0,
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    flexWrap: "wrap",
    alignContent: "flex-start",
    minHeight: 213,
    "&>*:nth-child(odd)": {
      flex: "0 0 55%",
    },
    "&>*:nth-child(even)": {
      flex: "0 0 45%",
    },
    "&>*:nth-child(odd)>div": {
      marginRight: 84,
    },
    "&>*:nth-child(even)>div": {
      marginRight: 36,
    },
  },
}))(MuiAccordionDetails);

const useVideoPreviewStyles = makeStyles((theme) => ({
  root: {
    "&>*": {
      margin: "0 60px 0 0",
    },
  },
  previewImage: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: ({ selected }) => (selected ? theme.palette.primary.dark : "rgba(0,0,0,0)"),
    borderRadius: 10,
    minWidth: 210,
    maxWidth: 210,
    height: 130,
    padding: 10,
    "& img": {
      width: "100%",
      height: "100%",
    },
    overflow: "hidden",
  },
  videoTitle: {
    fontSize: 16,
    textAlign: "center",
    maxHeight: "3em",
    overflow: "hidden",
    color: "#00213A",
    marginBottom: 3,
  },
  passed: {
    color: theme.palette.primary.dark,
    fontWeight: 700,
    fontSize: 16,
    textTransform: "uppercase",
    textAlign: "center",
  },
  circularProgress: {
    color: "white",
    filter: "drop-shadow(0px 0px 1px black)",
  },
  circularProgressLabel: {
    color: "white",
    textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
    fontSize: 10,
  },
}));

/**
 * @param {LearnVideo} video
 * @param {Boolean} selected
 * @param {Function} onClick
 * @returns {*}
 * @constructor
 */
const SmallVideoPreview = ({ video, selected, onClick }) => {
  const t = useTranslate();
  const classes = useVideoPreviewStyles({ selected });
  const { src } = useVideoThumbController(video);

  return (
    <Box className={classes.root} position="relative">
      <Button className={classes.previewImage} onClick={onClick} disabled={!video.readyToWatch}>
        <img src={src} alt={video.name} />
        {!video.readyToWatch && (
          <SimpleCircularProgressWithLabel
            progressProps={{ value: video.file.conversionProgress, className: classes.circularProgress }}
            rootProps={{ position: "absolute" }}
            labelProps={{ className: classes.circularProgressLabel }}
          />
        )}
      </Button>
      <Typography variant="body1" className={classes.videoTitle}>
        {video.name}
      </Typography>
      {video.passed && (
        <Typography variant="body1" className={classes.passed}>
          {t("passed")}
        </Typography>
      )}
    </Box>
  );
};

SmallVideoPreview.propTypes = {
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  video: PropTypes.object,
};

const useFlippyCardStyles = makeStyles(
  () => ({
    root: {
      marginLeft: 10,
      cursor: ({ learned }) => (learned ? "default" : "pointer"),
      transitionProperty: "opacity",
      transitionDuration: 300,
    },
    flippyCard: {
      padding: 0,
      boxShadow: "none",
      display: "flex",
    },
  }),
  {
    name: "makeFlippyCardStyles",
  },
);

/**
 * @param {WordInWordResponse} word
 * @returns {*}
 * @constructor
 */
const VideoWordState = ({ word }) => {
  const classes = useFlippyCardStyles({ learned: word.learned });
  const { learnVideoWord, videoWordPatching } = useLearnProcess();

  /** */
  const clickHandler = async () => {
    if (!word.learned && videoWordPatching !== word.id) {
      await learnVideoWord(word.id, true);
    }
  };

  return (
    <Flippy
      style={{ opacity: videoWordPatching === word.id ? 0.3 : 1 }}
      className={classes.root}
      isFlipped={word.learned}
      flipOnClick={false}
      onClick={clickHandler}
    >
      <FrontSide className={classes.flippyCard}>
        <img src={icPlusPink} alt="plus" />
      </FrontSide>
      <BackSide className={classes.flippyCard}>
        <img src={icCheckGreen} alt="check" />
      </BackSide>
    </Flippy>
  );
};

VideoWordState.propTypes = {
  word: PropTypes.object.isRequired,
};

const useStyles = makeStyles((theme) => ({
  videosRoot: {
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    '&>div[class=""]': {
      maxHeight: 394,
    },
  },
  bottomBlock: {
    backgroundColor: grey[50],
  },
  youtubeFrame: {
    width: "100%",
    height: 390,
    border: "none",
    marginTop: 4,
  },
  vimeoFrame: {
    width: "100%",
    height: 394,
    "&>iframe": {
      width: "100%",
      height: 390,
      border: "none",
      marginTop: 4,
    },
  },
  wordsTitle: {
    fontSize: 15,
    color: theme.palette.primary.dark,
  },
  expandMoreIcon: {
    color: theme.palette.primary.dark,
  },
  wordItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 16,
    "&>img": {
      cursor: "pointer",
    },
  },
  wordFont: {
    fontSize: 16,
  },
  videoThumbs: {
    minHeight: 211,
    "&>*:first-child": {
      marginLeft: 25,
    },
  },
  osWords: {
    maxHeight: 213,
  },
}));

/** */
const Videos = () => {
  const {
    videos,
    setWordsExpanded,
    areWordsExpanded,
    selectedVideoIndex,
    setSelectedVideoIndex,
    learnVideo,
    learnedVideos,
  } = useLearnProcess();
  const classes = useStyles();
  const t = useTranslate();

  /**
   * @param {*} _
   * @param {*} newExpanded
   */
  const handleChange = (_, newExpanded) => {
    setWordsExpanded(newExpanded);
  };

  /** */
  const sendVideoLearned = () => {
    const videoId = videos.videos[selectedVideoIndex].id;
    if (learnedVideos.findIndex((id) => id === videoId) < 0) {
      learnVideo(videos.videos[selectedVideoIndex].id).then();
    }
  };

  /** @param {Number} percent */
  const onPlayback = (percent) => {
    if (percent > 0.95) {
      sendVideoLearned();
    }
  };

  if (!videos || !videos.videos || videos.videos.length === 0) {
    return null;
  }

  const hasVideoWords = videos.videos[selectedVideoIndex].words && videos.videos[selectedVideoIndex].words.length > 0;

  return (
    <Box className={classes.videosRoot}>
      <UniversalVideoPlayer url={videos.videos[selectedVideoIndex].url} onPlayback={onPlayback} />
      <Box className={classes.bottomBlock} flexGrow={1}>
        <Accordion square expanded={areWordsExpanded} onChange={handleChange} disabled={!hasVideoWords}>
          <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.expandMoreIcon} />}>
            <Typography className={classes.wordsTitle} variant="subtitle2">
              {t("videoVocabulary")}
            </Typography>
          </AccordionSummary>
          <MyScrollbars className={classes.osWords}>
            <AccordionDetails>
              {videos.videos[selectedVideoIndex].words.map((word) => (
                <Box key={word.id} className={classes.wordItem}>
                  <Typography className={classes.wordFont} variant="body2" component="span">
                    {word.source}
                  </Typography>
                  <VideoWordState word={word} />
                </Box>
              ))}
            </AccordionDetails>
          </MyScrollbars>
        </Accordion>
        <MyScrollbars>
          <Box display="flex" className={classes.videoThumbs}>
            {videos.videos.map((video, i) => (
              <SmallVideoPreview
                key={video.id}
                video={video}
                onClick={() => setSelectedVideoIndex(i)}
                selected={i === selectedVideoIndex}
              />
            ))}
          </Box>
        </MyScrollbars>
      </Box>
    </Box>
  );
};

export default Videos;
