import React from "react";
import { Box } from "@material-ui/core";

/**
 * @param {Object} props
 * @returns {*}
 * @constructor
 */
const FlexboxVertical = (props) => <Box {...props} display="flex" flexDirection="column" />;

export default FlexboxVertical;
