import PropTypes from "prop-types";
import React from "react";
import Button from "@material-ui/core/Button";
import { useTranslate } from "react-polyglot";
import { TextField } from "mui-rff";
import { Typography } from "@material-ui/core";
import { useAuth } from "../../../Providers/Auth/AuthProvider.jsx";
import useTitle from "../../../hooks/useTitle.js";
import FlexboxVertical from "../../../SharedComponents/FlexboxVertical.jsx";
import ReactFinalForm from "../../../SharedComponents/ReactFinalForm.jsx";
import useCompanyPublicId from "../../../hooks/useCompanyPublicId.js";
import useFavicon from "../../../hooks/useFavicon.js";

/** @param {String} className */
const CheckEmail = ({ className }) => {
  const { companyName, domain } = useCompanyPublicId();
  const t = useTranslate();
  const { checkEmail, company2 } = useAuth();

  useTitle("title.login", { school: (company2 && company2.name) || companyName || domain });

  useFavicon();

  /** @param {String} email */
  const onSubmit = ({ email }) => {
    checkEmail(email);
  };

  return (
    <>
      <Typography variant="subtitle1" align="center" color="primary">
        {t("title.checkInSystem")}
      </Typography>
      <ReactFinalForm onSubmit={onSubmit} formClassName={className}>
        <FlexboxVertical>
          <TextField name="email" label="Email" type="email" variant="outlined" fullWidth={false} required />
          <Button size="large" type="submit" variant="contained" color="primary">
            {t("check")}
          </Button>
        </FlexboxVertical>
      </ReactFinalForm>
    </>
  );
};

CheckEmail.propTypes = {
  className: PropTypes.string,
};

export default CheckEmail;
