import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import AuthPage from "./Pages/Auth/AuthPage.jsx";
import Subjects from "./Pages/Subjects/Subjects.jsx";
import DialogsProvider from "./Providers/Common/DialogsProvider.jsx";
import AuthProvider from "./Providers/Auth/AuthProvider.jsx";
import I18nProvider from "./Providers/i18n/I18nProvider.jsx";
import Home from "./Pages/Home/Home.jsx";
import AuthProtectedRoute from "./Providers/Auth/AuthProtectedRoute.jsx";
import BaseDataProvider from "./Providers/Data/BaseDataProvider.jsx";
import UserDataProtectedRoute from "./Providers/Data/UserDataProtectedRoute.jsx";
import UserPreferencesProvider from "./Providers/Data/UserPreferencesProvider.jsx";
import HttpProvider from "./Providers/Common/HttpProvider.jsx";
import CompanyProtectedRoute from "./Providers/Data/CompanyProtectedRoute.jsx";
import PayWebview from "./Pages/Payments/PayWebview.jsx";
import MyScrollbars from "./SharedComponents/MyScrollbars.jsx";
import { IS_COMMON_APP } from "./constants.js";
import AuthRequestDataRoute from "./Providers/Auth/AuthRequestDataRoute.jsx";
import Offer from "./Pages/Offer/Offer.jsx";
import SubjectDetails from "./Pages/Subjects/SubjectDetails.jsx";

/** */
function App() {
  return (
    <UserPreferencesProvider>
      <I18nProvider>
        <DialogsProvider>
          <HttpProvider>
            <AuthProvider>
              <BaseDataProvider>
                <MyScrollbars style={{ height: "100vh" }}>
                  <Router>
                    <Switch>
                      <UserDataProtectedRoute exact path="/">
                        <Home />
                      </UserDataProtectedRoute>
                      <UserDataProtectedRoute
                        path={["/dictionary", "/homeworks", "/events", "/news", "/learn", "/missions", "/leaderboard"]}
                      >
                        <Home />
                      </UserDataProtectedRoute>
                      <AuthRequestDataRoute exact path={["/auth", "/password", "/registration"]}>
                        <AuthPage />
                      </AuthRequestDataRoute>
                      <AuthProtectedRoute exact path="/subjects">
                        <Subjects />
                      </AuthProtectedRoute>
                      <AuthProtectedRoute exact path="/subjects/:subjectId">
                        <SubjectDetails />
                      </AuthProtectedRoute>
                      <Route exact path={["/offer/:urn/:offerId", "/:companyName/offer/:urn/:offerId"]}>
                        <Offer />
                      </Route>
                      <AuthProtectedRoute exact path="/profile">
                        <Home />
                      </AuthProtectedRoute>
                      <AuthProtectedRoute exact path="/orders">
                        <Home />
                      </AuthProtectedRoute>
                      <AuthProtectedRoute exact path="/pay">
                        <PayWebview />
                      </AuthProtectedRoute>
                      {IS_COMMON_APP && (
                        <>
                          <CompanyProtectedRoute exact path="/:companyName">
                            <Subjects />
                          </CompanyProtectedRoute>
                          <CompanyProtectedRoute exact path="/:companyName/subjects/:subjectId">
                            <SubjectDetails />
                          </CompanyProtectedRoute>
                          <Route
                            exact
                            path={["/:companyName/auth", "/:companyName/password", "/:companyName/registration"]}
                          >
                            <AuthPage />
                          </Route>
                          <AuthProtectedRoute exact path="/:companyName/pay">
                            <PayWebview />
                          </AuthProtectedRoute>
                        </>
                      )}
                      <Redirect to="/" />
                    </Switch>
                  </Router>
                </MyScrollbars>
              </BaseDataProvider>
            </AuthProvider>
          </HttpProvider>
        </DialogsProvider>
      </I18nProvider>
    </UserPreferencesProvider>
  );
}

export default App;
