import React, { forwardRef } from "react";
import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const CustomButton = forwardRef((props, ref) => <Button ref={ref} {...props} variant="contained" />);

CustomButton.displayName = "GreyButton";

export default withStyles({
  root: {
    color: "#000000",
    backgroundColor: "#D6E3F7",
    borderRadius: 50,
    "&:hover": {
      backgroundColor: "#D6E3F7",
      opacity: "0.6",
    },
  },
  contained: {
    "& .MuiButton-label": {
      fontWeight: 300,
      fontSize: 24,
    },
  },
})(CustomButton);
