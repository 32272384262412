import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslate } from "react-polyglot";
import { GridContextProvider, GridDropZone, GridItem, move, swap } from "react-grid-dnd";
import { makeStyles, useTheme } from "@material-ui/core";
import lodash from "lodash";
import Typography from "@material-ui/core/Typography";
import FlexboxVertical from "../../../../SharedComponents/FlexboxVertical.jsx";
import { calcTextWidth } from "../../../../Utils/misc.js";
import ChipArabic from "../../../../SharedComponents/ChipArabic.jsx";
import "../../../../Utils/extensions.js";
import { DEFAULT_LEARNED_PATCH } from "../../../../Providers/Data/LearnProcessProvider.jsx";
import Question from "./SharedComponents/Question.jsx";
import Appendix from "./SharedComponents/Appendix.jsx";

const DROP_ZONE_TOP_BOTTOM_PADDINGS = 20;
const DROP_ITEM_HEIGHT = 40;

const useStyles = makeStyles(() => ({
  dragnDropContainer: {
    touchAction: "none",
    userSelect: "none",
    padding: "15px 0 10px",
    flexGrow: 1,
    alignSelf: "stretch",
    position: "relative",
  },
  dropzone: {
    minHeight: 200,
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: 10,
    margin: "5px 20px 5px",
    backgroundColor: "white",
    padding: "10px 0 0",
    transitionProperty: "height",
    transitionDuration: "300ms",
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: "1 !important",
  },
  chip: {
    width: "90%",
    cursor: "grab",
    maxWidth: 400,
    "&:active": {
      boxShadow: "4px 4px 3px -1px rgba(0, 0, 0, 0.3)",
      cursor: "grabbing",
    },
  },
  textHint: {
    position: "absolute",
    left: 40,
    right: 40,
    top: 86,
    textAlign: "center",
  },
}));

/**
 * @param {ExerciseType3Legacy|ExerciseType3} exercise
 * @param {Function} setCheckButtonDisabled
 * @param {String} exerciseTitle
 * @param {MutableRefObject<Function>} checkAnswerRef
 * @param {FnComponentStringVoid} showRightAnswer
 * @param {Object} rest
 * @returns {*}
 * @constructor
 */
const Exercise3 = ({ exercise, setCheckButtonDisabled, exerciseTitle, checkAnswerRef, showRightAnswer, ...rest }) => {
  const classes = useStyles();
  const t = useTranslate();
  const theme = useTheme();
  const [items, setItems] = useState({ left: [], right: [] });
  const [itemsPerRow, setItemsPerRow] = useState(2);
  const [inputWordsHeight, setInputWordsHeight] = useState(0);
  const [selectedWordsHeight, setSelectedWordsHeight] = useState(0);

  /** @type {ExerciseType3Answer|ExerciseType3Legacy} */
  const answer = exercise.answer || exercise;

  //reset state
  useEffect(() => {
    // calc grid items count per row
    const dropzoneElement = document.querySelector(`.${classes.dropzone}`);
    const typography = theme.typography;
    let maxWidth = 0;
    answer.inputWords.forEach((word) => {
      const fontSize = word.isArabic() ? "26px" : typography.pxToRem(13);
      const width = calcTextWidth(word, `${fontSize} ${typography.fontFamily}`);
      if (width > maxWidth) {
        maxWidth = width;
      }
    });
    if (maxWidth > 373) {
      maxWidth = 373;
    }
    maxWidth += 24 + 10;
    const dropzoneWidth = dropzoneElement.getBoundingClientRect().width;
    const itemsPerRow = Math.floor(dropzoneWidth / maxWidth);
    setItemsPerRow(itemsPerRow);
    // end of calc

    const freshItems = {
      left: [],
      right: null,
    };
    const allWords = lodash.shuffle(answer.inputWords);
    freshItems.right = allWords.map((name, id) => ({ id, name }));
    const inputWordsContainerHeight =
      Math.ceil(answer.inputWords.length / itemsPerRow) * DROP_ITEM_HEIGHT + DROP_ZONE_TOP_BOTTOM_PADDINGS;
    setInputWordsHeight(inputWordsContainerHeight);
    setItems(freshItems);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer]);

  // manage "check button" state
  useEffect(() => {
    setCheckButtonDisabled(items.left.length === 0);
  }, [setCheckButtonDisabled, items]);

  checkAnswerRef.current = async () => {
    const userAnswer = items.left.map((item) => item.name);
    const learned = lodash.isEqual(userAnswer, answer.sentence);
    if (!learned) {
      showRightAnswer(<Typography variant="h4">{answer.sentence.join(" ")}</Typography>, userAnswer.join(" "));
      return null;
    }
    return { ...DEFAULT_LEARNED_PATCH, payload: { answerValue: answer.sentence.join(" ") } };
  };

  // change drop zones heights after moving item
  useEffect(() => {
    if (items.right.length > 0) {
      const inputWordsContainerHeight =
        Math.ceil(items.right.length / itemsPerRow) * DROP_ITEM_HEIGHT + DROP_ZONE_TOP_BOTTOM_PADDINGS;
      setInputWordsHeight(inputWordsContainerHeight);
    } else {
      setInputWordsHeight(0);
    }

    if (items.left.length > 0) {
      const selectedWordsContainerHeight =
        Math.ceil(items.left.length / itemsPerRow) * DROP_ITEM_HEIGHT +
        DROP_ZONE_TOP_BOTTOM_PADDINGS +
        DROP_ITEM_HEIGHT / 2;
      setSelectedWordsHeight(selectedWordsContainerHeight);
    } else {
      setSelectedWordsHeight(0);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  /**
   * @param {number} sourceId
   * @param {number} sourceIndex
   * @param {number} targetIndex
   * @param {number} targetId
   */
  const onChange = (sourceId, sourceIndex, targetIndex, targetId) => {
    if (targetId) {
      const result = move(items[sourceId], items[targetId], sourceIndex, targetIndex);
      return setItems({
        ...items,
        [sourceId]: result[0],
        [targetId]: result[1],
      });
    }

    const result = swap(items[sourceId], sourceIndex, targetIndex);
    return setItems({
      ...items,
      [sourceId]: result,
    });
  };

  return (
    <FlexboxVertical {...rest} data-component="Exercise3" alignItems="center" height="100%">
      <Question
        exerciseQuestion={exercise.question}
        exerciseTitle={exerciseTitle}
        defaultTitle={t("Exercises.type3.title")}
      />
      {exercise.appendix && exercise.appendix.pdf && <Appendix pdfUrl={exercise.appendix.pdf.url} />}
      <GridContextProvider onChange={onChange}>
        <FlexboxVertical className={classes.dragnDropContainer}>
          <GridDropZone
            style={{ height: selectedWordsHeight > 0 ? selectedWordsHeight : undefined }}
            className={classes.dropzone}
            id="left"
            boxesPerRow={itemsPerRow}
            rowHeight={40}
          >
            {items.left.map((item) => (
              <GridItem key={item.name + item.id} className={classes.gridItem}>
                <ChipArabic className={classes.chip} label={item.name} />
              </GridItem>
            ))}
          </GridDropZone>
          <GridDropZone
            style={{ height: inputWordsHeight > 0 ? inputWordsHeight : undefined }}
            className={classes.dropzone}
            id="right"
            boxesPerRow={itemsPerRow}
            rowHeight={40}
          >
            {items.right.map((item) => (
              <GridItem key={item.name + item.id} className={classes.gridItem}>
                <ChipArabic className={classes.chip} label={item.name} />
              </GridItem>
            ))}
          </GridDropZone>
          {items.left.length === 0 && (
            <Typography className={classes.textHint} color="textSecondary">
              {t("Exercises.type3.hint")}
            </Typography>
          )}
        </FlexboxVertical>
      </GridContextProvider>
    </FlexboxVertical>
  );
};

Exercise3.propTypes = {
  checkAnswerRef: PropTypes.object.isRequired,
  exercise: PropTypes.object.isRequired,
  exerciseTitle: PropTypes.string,
  setCheckButtonDisabled: PropTypes.func.isRequired,
  showRightAnswer: PropTypes.func.isRequired,
};

export default Exercise3;
