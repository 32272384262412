import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useTranslate } from "react-polyglot";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core";
import LevelsCard from "../LeftSideComponents/LevelsCard.jsx";
import BranchList from "./BranchList.jsx";
import DeckList from "./DeckList.jsx";
import useDesktopWidthMatch from "../../../hooks/useDesktopWidthMatch.js";
import useCompanyTitle from "../../../hooks/useCompanyTitle.js";
import { useCourseProvider } from "../../../Providers/Data/CourseProvider.jsx";
import StudentProgressStatistic from "../../../SharedComponents/StudentProgressStatistic.jsx";
import FeedbackDialog from "../../../SharedComponents/FeedbackDialog/FeedbackDialog.jsx";
import ContactUs from "../../../SharedComponents/ContactUs.jsx";

const useStyles = makeStyles((theme) => ({
  courseStatPageRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
  },
  branchesDecks: {
    margin: 0,
    width: "100%",
    maxWidth: "unset",
    [theme.breakpoints.up("lg")]: {
      margin: "24px 0 0 20px",
      maxWidth: "865px",
    },
  },
  leftSideElements: {
    display: "flex",
    width: "100%",
    padding: "34px 0",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "column",
      width: "415px",
    },
  },
  box1: {
    flexGrow: 2,
    margin: "0 0 0 16px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("lg")]: {
      margin: "24px 0 0",
      maxHeight: 220,
    },
  },
  trainingButton: {
    margin: "8px 0 0",
    [theme.breakpoints.up("lg")]: {
      margin: "24px 0 0",
    },
  },
}));

/** */
const CourseStartPage = () => {
  const classes = useStyles();
  const isDesktop = useDesktopWidthMatch();
  const t = useTranslate();
  const { eventsToFeedback, setEventsToFeedback } = useCourseProvider();
  const [isFeedbackOpen, setFeedbackOpen] = useState(false);

  useCompanyTitle("title.home");

  useEffect(() => {
    if (eventsToFeedback.length > 0) {
      setFeedbackOpen(true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsToFeedback]);

  /** */
  const closeFeedbackDialog = () => {
    const cloneEvent = _.cloneDeep(eventsToFeedback);
    cloneEvent.shift();
    setEventsToFeedback(cloneEvent);
    setFeedbackOpen(false);
  };

  return (
    <Box className={classes.courseStatPageRoot}>
      <Box className={classes.leftSideElements}>
        <LevelsCard tinyMode={!isDesktop} />
        <Box className={classes.box1}>
          <StudentProgressStatistic />
          {/*<StartTrainingButton className={classes.trainingButton} />*/}
        </Box>
        <ContactUs />
      </Box>
      <Box className={classes.branchesDecks}>
        <BranchList />
        <DeckList />
      </Box>
      {eventsToFeedback?.length > 0 && (
        <FeedbackDialog
          data={eventsToFeedback[0]}
          instructionText={t("feedbackDialog.eventInstruction")}
          resource="event"
          onClose={closeFeedbackDialog}
          open={isFeedbackOpen}
        />
      )}
    </Box>
  );
};

export default CourseStartPage;
