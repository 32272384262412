import React from "react";
import Typography from "@material-ui/core/Typography";
import { Box, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import ExerciseTitle from "./ExerciseTitle.jsx";
import LearnAudioPlayer from "./LearnAudioPlayer.jsx";
import UniversalVideoPlayer from "../../../../../SharedComponents/UniversalVideoPlayer.jsx";
import LetterImageQuestion from "./LetterImageQuestion.jsx";
import { useLearnProcess } from "../../../../../Providers/Data/LearnProcessProvider.jsx";

const useStyles = makeStyles((theme) => ({
  textQuestion: {
    fontSize: 19,
    fontWeight: 500,
    margin: "20px 30px 20px",
  },
  videoPlayerWrapper: {
    width: 400,
    marginTop: 10,
    "&>div": {
      width: "100%",
    },
  },
  hr: {
    width: "100%",
    height: 2,
    minHeight: 2,
    marginTop: 40,
    backgroundColor: theme.palette.primary.main,
    border: "none",
  },
  audioWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "sticky",
    top: 48,
    width: "100%",
    padding: "20px 0",
  },
}));

/**
 * @param {Object} classes
 * @param {MediaQuestion} exerciseQuestion
 * @param {ExerciseType6Answer} exerciseAnswer
 * @param {String} exerciseTitle
 * @param {String} defaultTitle
 * @param {Boolean} disableBottomSeparator
 * @returns {*}
 * @constructor
 */
const QuestionExercise6 = ({
  exerciseQuestion,
  exerciseAnswer,
  exerciseTitle,
  defaultTitle,
  disableBottomSeparator,
}) => {
  const classes = useStyles();
  const { exercise } = useLearnProcess();
  const question = exerciseQuestion;

  return (
    <>
      {!exerciseTitle && <ExerciseTitle>{defaultTitle}</ExerciseTitle>}
      {exerciseTitle && <ExerciseTitle>{exerciseTitle}</ExerciseTitle>}
      {question.text && <Typography className={classes.textQuestion}>{question.text}</Typography>}
      {question.audio && (
        <Box data-tag="audio question" className={classes.audioWrapper}>
          <LearnAudioPlayer url={question.audio.url} />
        </Box>
      )}
      {question.image &&
        [""].map(() => <LetterImageQuestion key={exercise.exercise.id} image={question.image} data={exerciseAnswer} />)}
      {question.video && (
        <div className={classes.videoPlayerWrapper}>
          <UniversalVideoPlayer url={question.video} height={225} />
        </div>
      )}
      {!disableBottomSeparator && <hr className={classes.hr} />}
    </>
  );
};

QuestionExercise6.propTypes = {
  defaultTitle: PropTypes.string.isRequired,
  disableBottomSeparator: PropTypes.bool,
  exerciseAnswer: PropTypes.object.isRequired,
  exerciseQuestion: PropTypes.object.isRequired,
  exerciseTitle: PropTypes.string.isRequired,
};

export default QuestionExercise6;
