import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

/***
 * @param {string[]} tabRoutes
 * @returns {{onTabChanged: Function, tabIndex: number}}
 */
const useTabsRouter = (tabRoutes) => {
  const [tabIndex, setTabIndex] = useState(0);
  const history = useHistory();
  /** @type {String} */
  const location = history.location.pathname;

  /**
   * @param {*} _
   * @param {Number} newValue
   */
  const onTabChanged = (_, newValue) => {
    history.push(tabRoutes[newValue]);
  };

  useEffect(() => {
    let tabIndex_ = -1;
    tabRoutes.forEach((tabRoute, i) => {
      if (location.indexOf(tabRoute) === 0) {
        tabIndex_ = i;
      }
    });
    if (tabIndex_ > -1) {
      setTabIndex(tabIndex_);
    }
  }, [location, tabRoutes]);

  return {
    onTabChanged,
    tabIndex,
  };
};

export default useTabsRouter;
