import React, { useState } from "react";
import { TextField } from "mui-rff";
import { useTranslate } from "react-polyglot";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import PropTypes from "prop-types";

/** */
const PasswordButton = ({ onClick, visible }) => {
  return (
    <InputAdornment position="end">
      <IconButton onClick={onClick}>{visible ? <Visibility /> : <VisibilityOff />}</IconButton>
    </InputAdornment>
  );
};

PasswordButton.propTypes = {
  fieldName: PropTypes.string,
  onClick: PropTypes.func,
  visible: PropTypes.bool,
};

/**
 * @param {String} labelTranslationKey
 * @param {Boolean} enableAutoFill
 * @param {Object} rest
 */
const PasswordFormField = ({ labelTranslationKey, enableAutoFill, ...rest }) => {
  const t = useTranslate();
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  return (
    <TextField
      autoComplete={!enableAutoFill ? "new-password" : undefined}
      label={t(labelTranslationKey)}
      type={passwordVisibility ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <PasswordButton onClick={() => setPasswordVisibility(!passwordVisibility)} visible={passwordVisibility} />
        ),
      }}
      {...rest}
    />
  );
};

PasswordFormField.propTypes = {
  enableAutoFill: PropTypes.bool,
  labelTranslationKey: PropTypes.string,
};

export default PasswordFormField;
