import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useBaseData } from "./BaseDataProvider.jsx";
import useCompanyPublicId from "../../hooks/useCompanyPublicId.js";

/**
 * @param {(React.ReactNode|HTMLElement)} children
 * @param {Object} rest
 * @returns {React.ReactNode}
 * @constructor
 */
const UserDataProtectedRoute = ({ children, ...rest }) => {
  const { subject, userInfo, company } = useBaseData();
  const { domain } = useCompanyPublicId();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (subject && subject.token && userInfo && userInfo.subject.available !== false && company) {
          return React.cloneElement(children);
        } else if (company || domain) {
          return (
            <Redirect
              to={{
                pathname: "/subjects",
                state: { from: location },
              }}
            />
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: "/auth",
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  );
};

export default UserDataProtectedRoute;
