import PropTypes from "prop-types";
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { useTranslate } from "react-polyglot";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FlatGreyButton from "../../../../../SharedComponents/FlatGreyButton.jsx";
import FlatPrimaryButton from "../../../../../SharedComponents/FlatPrimaryButton.jsx";
import FlexboxVertical from "../../../../../SharedComponents/FlexboxVertical.jsx";
import { Actions, Content, Title } from "../../SharedComponents/ExamPopup.jsx";
import TimerChip from "../../../../../SharedComponents/TimerChip.jsx";
import { formatTime } from "../../../../../Utils/misc.js";
import CircularProgressWithLabel from "../../../../../SharedComponents/CircularProgressWithLabel.jsx";
import ExamStatusText from "../../../../../SharedComponents/ExamStatusText.jsx";

const useStyles = makeStyles(() => ({
  examInfo: {
    alignItems: "center",
  },
  timerChip: {
    position: "absolute",
    right: 20,
  },
  progress: {
    margin: "20px 0",
  },
}));

/**
 * @param {Boolean} open
 * @param {ExamPopup.OnClose} onClose
 * @param {Exam} exam
 */
const ExamResultPopup = ({ open, onClose, exam }) => {
  const classes = useStyles();
  const t = useTranslate();

  /** */
  const cancel = () => {
    onClose(false);
  };

  return (
    <Dialog open={open} onClose={cancel} fullWidth maxWidth="sm">
      <Title disableTypography>
        <Typography variant="h6">{t("exam.finishedPopupTitle")}</Typography>
        <TimerChip className={classes.timerChip} label={formatTime((exam.endDate - exam.startDate) / 1000)} />
      </Title>
      <Content>
        <FlexboxVertical className={classes.examInfo}>
          <CircularProgressWithLabel
            className={classes.progress}
            value={(exam.points / exam.totalPoints) * 100}
            componentSize={120}
            textValue={`${exam.points}/${exam.totalPoints}`}
          />
          <ExamStatusText exam={exam} />
        </FlexboxVertical>
      </Content>
      <Actions>
        <FlatGreyButton onClick={cancel}>{t("exam.closeButton")}</FlatGreyButton>
        <FlatPrimaryButton onClick={() => onClose(true)}>{t("exam.nextButton")}</FlatPrimaryButton>
      </Actions>
    </Dialog>
  );
};

ExamResultPopup.propTypes = {
  exam: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ExamResultPopup;
