import PropTypes from "prop-types";
import React from "react";
import clsx from "clsx";
import { Button, makeStyles } from "@material-ui/core";
import LockIcon from "../../../SharedComponents/LockIcon.jsx";

const useStyles = makeStyles((theme) => ({
  subjectBtn: {
    marginTop: 20,
    paddingLeft: 45,
    paddingRight: 45,
  },
  selectBtn: {
    position: "relative",
    "&::before": {
      content: '""',
      display: "block",
      height: 12,
      width: 12,
      borderRadius: "50%",
      backgroundColor: theme.palette.primary.main,
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      left: "-20px",
    },
  },
  disabledBtn: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.background.default,
    opacity: 1,
    "&::after": {
      content: '"$"',
      display: "block",
      height: 25,
      width: 25,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      left: 10,
      fontSize: 18,
      fontWeight: 700,
    },
    "&.MuiInput-underline:after": {
      left: 10,
      borderBottom: "none",
    },
    "&.MuiInput-underline.Mui-focused:after": {
      transform: "translateY(-50%)",
    },
  },
}));

/**
 * @param {CompanySubject} subject
 * @param {Number} selectedSubjectId
 * @param {Object} rest
 */
const SubjectButton = ({ subject, selectedSubjectId, ...rest }) => {
  const classes = useStyles();

  return (
    <Button
      key={subject.id}
      variant="contained"
      color="primary"
      size="large"
      fullWidth
      className={clsx(classes.subjectBtn, {
        [classes.selectBtn]: subject.id === selectedSubjectId,
        [classes.disabledBtn]: subject.price > 0 && !subject.purchased,
      })}
      {...rest}
    >
      {subject.name}
      {subject.available === false && <LockIcon />}
    </Button>
  );
};

SubjectButton.propTypes = {
  selectedSubjectId: PropTypes.number.isRequired,
  subject: PropTypes.object.isRequired,
};

export default SubjectButton;
