import { Backdrop, withStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";

const MyBackdrop = withStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
  },
}))((props) => (
  <Backdrop {...props}>
    <CircularProgress color="inherit" />
  </Backdrop>
));

export default MyBackdrop;
